import { backend } from '../../../env-variables'
import axios from 'axios'

const saveAnimationFrames = (animationFramesData, character) => {
  axios.post(backend + 'animation-data/save-frame-data', {
    animationFramesData: animationFramesData,
    character
  })
  .then((result) => {
    console.log(result)
  })
}

const saveLinkedHurtboxes = (linkedHurtboxesData, character) => {
  axios.post(backend + 'animation-data/save-linked-hurtboxes', {
    linkedHurtboxesData: linkedHurtboxesData,
    character
  })
  .then((result) => {
    console.log(result)
  })
}

export {
  saveAnimationFrames,
  saveLinkedHurtboxes
}
import { inplacePositioning } from './default-metadata'

const season2 = {
  "mouth-2-1": {...inplacePositioning},
  "mouth-2-2": {...inplacePositioning},
  "mouth-2-3": {...inplacePositioning},
  "mouth-2-4": {...inplacePositioning},
  "mouth-2-5": {...inplacePositioning},
  "mouth-2-6": {...inplacePositioning},
  "mouth-2-7": {...inplacePositioning},
  "mouth-2-8": {...inplacePositioning},
  "mouth-2-9": {...inplacePositioning},
  "mouth-2-10": {...inplacePositioning}
}

const mouthAccessoryMetadata = {
  ...season2
}

export default mouthAccessoryMetadata
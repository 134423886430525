import {
  rimLightVertexPrepend,
  rimLightVertexContent
} from '../rim-light/rim-light-vertex-shader'
import * as THREE from 'three'

const toonVertexShader = `
  uniform float edgeWidthRatio;
  uniform bool USE_RIMLIGHT;
  uniform bool edge;
  uniform vec3 lightPosition;
  uniform vec3 objectPosition;

  varying vec2 vUv;
  varying vec3 vLightDirection;

  varying vec3 N;
  varying vec3 I;

  ${rimLightVertexPrepend}
  ${THREE.ShaderChunk.skinning_pars_vertex}

  void main(){
    mat4 modelViewProjectionMatrix = projectionMatrix * modelViewMatrix;

    ${THREE.ShaderChunk.begin_vertex}
    ${THREE.ShaderChunk.beginnormal_vertex}
    ${THREE.ShaderChunk.skinbase_vertex}
    ${THREE.ShaderChunk.skinnormal_vertex}

    ${THREE.ShaderChunk.skinning_vertex}

    if (edge) {
      transformed += objectNormal * edgeWidthRatio;
    }
    else {
      vec3 shadeOffset = vec3(-0.5, -0.5, 0.);
      vec3 adjShadePosition = normalize(cameraPosition - objectPosition) - shadeOffset;
      vec3 t = normalize(cross(objectNormal, adjShadePosition));
      vec3 b = cross(objectNormal, t);
  
      vLightDirection = normalize(vec3(
        dot(t, adjShadePosition), 
        dot(b, adjShadePosition), 
        dot(objectNormal, adjShadePosition)
      ));
      vUv = uv;
    }

    //Transform vertex by modelview and projection matrices
    gl_Position = modelViewProjectionMatrix * vec4( transformed, 1.0 );

    // Normal transform (transposed model-view inverse)
    N = normalMatrix * objectNormal;

    if (USE_RIMLIGHT) {
      ${rimLightVertexContent}
    }

    // Incident vector
    I = vec3(modelViewMatrix * vec4(transformed, 1.0));
  }
`

export {
  toonVertexShader
}
import React, { useState, useEffect } from 'react'
import LoadingScreen from '../../generic-components/loading-screen/LoadingScreen'
import FramesMenu from '../frames-menu/FramesMenu'
import ShapesMenu from '../shapes-menu/ShapesMenu'
import ManipulationMenu from '../manipulation-menu/ManipulationMenu'
import AnimationMenu from '../animation-menu/AnimationMenu'
import BoneMenu from '../bone-menu/BoneMenu'
import LinkBoneCheckbox from '../link-bone-checkbox/LinkBoneCheckbox'
import LiveCollidersCheckbox from '../live-colliders-checkbox/LiveCollidersCheckbox'
import UseMLCheckbox from '../use-ml-checkbox/UseMLCheckbox'
import CollisionData from '../collision-data/CollisionData'
import ColliderNameInput from '../collider-name-input/ColliderNameInput'
import ColliderTypeMenu from '../collider-type-menu/ColliderTypeMenu'
import UploadModel from '../upload-model/UploadModel'
import { addMeshToScene } from '../../../helpers/collision-maker/collider-operations'
import { useHurtboxSetup } from '../../../helpers/hooks/fighter-collision-maker/hurtbox-setup'
import { useHurtboxAnimation } from '../../../helpers/hooks/fighter-collision-maker/hurtbox-animation'
import { useChangingKeydown } from '../../../helpers/hooks/fighter-collision-maker/changing-keydown'
import { useCollisionMaker } from '../../../helpers/hooks/fighter-collision-maker/setup'
import { useMeshManipulation } from '../../../helpers/hooks/fighter-collision-maker/mesh-manipulation'
import { useBoneLinking } from '../../../helpers/hooks/fighter-collision-maker/bone-linking'
import { useSwapCharacters } from '../../../helpers/hooks/fighter-collision-maker/swap-characters'
import { 
  animationAtom,
  animationDataAtom,
  animationReadyAtom,
  colliderSelectedAtom,
  colliderTypeAtom,
  meshesInSceneAtom,
  manipulationAtom,
  sceneAtom,
  fighterTypeAtom
} from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './fighter-collision-maker.css'

const FighterCollisionMaker = () => {
  const animationData = useAtom(animationDataAtom)[0]
  const animationsReady = useAtom(animationReadyAtom)[0]
  const setColliderSelected = useAtom(colliderSelectedAtom)[1]
  const animation = useAtom(animationAtom)[0]
  const colliderType = useAtom(colliderTypeAtom)[0]
  const setMeshesInScene = useAtom(meshesInSceneAtom)[1]
  const setManipulationType = useAtom(manipulationAtom)[1]
  const scene = useAtom(sceneAtom)[0]
  const [fighterType, setFighterType] = useAtom(fighterTypeAtom)

  const [createRequest, setCreateRequest] = useState(undefined)
  const [modelData, setModelData] = useState({})

  useCollisionMaker()
  useSwapCharacters()
  useChangingKeydown()
  useMeshManipulation()
  useBoneLinking()
  useHurtboxSetup(modelData)
  useHurtboxAnimation(modelData)

  useEffect(() => {
    if (createRequest !== undefined && scene !== undefined) {
      addMeshToScene(createRequest, {}, setMeshesInScene, setColliderSelected, scene)
      setCreateRequest(undefined)
    }
  }, [createRequest])

  const showShapeMenu = (
    animation !== undefined &&
    (
      (animation.name === "a-pose" && colliderType.name === "hurtbox") ||
      (animation.name !== "a-pose" && colliderType.name === "hitbox")
    )
  )

  const saveImage = () => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'image.png');
    let canvas = document.getElementById('canvas');
    canvas.toBlob(function(blob) {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.click();
    });
  }

  return (
    <>
      <div id="fighter-collision-maker__container">
        {
          (!animationsReady || animationData === undefined) &&
          <LoadingScreen />
        }
        {/* <button onClick={() => saveImage()}>Save Image</button> */}
        <div id="fighter-collision-maker__menu">     
          {
            showShapeMenu && 
            <ShapesMenu setCreateRequest={setCreateRequest} />
          }
          <ManipulationMenu setManipulationType={setManipulationType} />
          <FramesMenu />
          <AnimationMenu showShapeMenu={showShapeMenu} />
          {
            animation !== undefined && animation.name !== "a-pose" &&
            <>
              <LinkBoneCheckbox />
              <LiveCollidersCheckbox />
              {
                Object.keys(modelData).length > 0 &&
                <UseMLCheckbox />
              }
            </>
          }          
          {
            animationData !== undefined &&
            <BoneMenu boneNames={animationData.boneNames} />
          }
          {
            animationsReady && animationData !== undefined &&
            <CollisionData />
          }
          <ColliderTypeMenu />
          <ColliderNameInput />
          <UploadModel modelData={modelData} setModelData={setModelData} />
        </div>
        <div id="fighter-collision-maker">
          <select 
            id="fighter-collision-maker__options"
            onChange={(e) => setFighterType(e.target.value)}
            value={fighterType} >
            <option value="fighter">Fighter</option>
            <option value="agnes-ultimate">Agnes Ultimate</option>
            <option value="attack-drone">Attack Drone</option>
            <option value="defense-drone">Defense Drone</option>
            <option value="agility-drone">Agility Drone</option>
          </select>
        </div>
      </div>
    </>
  )
}

export default FighterCollisionMaker
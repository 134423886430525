import React, { useState, useEffect } from 'react'
import { bodyPartAssets } from '../../../helpers/asset-management/animation-player/animation-player'
import { 
  removeBodyMesh, 
  getAccessoryParams 
} from '../../../helpers/asset-management/fighter-accessories/dress-fighter'
import { inplacePositioning } from '../../../helpers/asset-management/fighter-accessories/metadata/default-metadata'
import Accessory from '../../../helpers/asset-management/fighter-accessories/accessory'
import checkmark from '../../../local-assets/collision-maker/tick-mark.png'
import * as THREE from 'three'
import './attribute-uploader.css'

const keepBodyParts = ["head", "mouth", "eyes"]
const facePlateParts = ["mouth", "eyes"]

const AttributeUploader = ({ environment, bodyPart }) => {
  const [attributeFiles, setAttributeFiles] = useState({ fbx: undefined, baseColor: undefined })

  const attachAttribute = () => {
    const skinMaterial = environment.character.getObjectByName("mesh_head").material
    const limbMaterial = environment.character.getObjectByName("mesh_arms").material
    const headMesh = environment.character.children.filter((child) => child.name === "mesh_head")[0]  
    const params = getAccessoryParams(
      environment.resources.items["gradientMap"], 
      environment.resources.items["colorGamut"], 
      environment.character.scale.x, 
      environment.resources.loaders, 
      headMesh.skeleton.bones[0], 
      headMesh.skeleton,
      skinMaterial,
      limbMaterial,
      environment.resources["toonMaterials"],
      [],
      0, 
      0,
      {}       
    )
    params.fbx = attributeFiles.fbx
    params.metadata = inplacePositioning
    params.baseColor = attributeFiles.baseColor
    
    params.anchorPoint = environment.character
    params.bindBool = true
    new Accessory(params)
    if (!keepBodyParts.includes(bodyPart)) {
      removeBodyMesh(environment.character, `mesh_${bodyPart}`)
    }    
  }

  const attachFaceTexture = () => {
    var facePlateMesh
    if (bodyPart === "mouth") {
      facePlateMesh = environment.character.getObjectByName("head_mouth")
    }
    else {
      facePlateMesh = environment.character.getObjectByName("head_eyes")
    }
    attributeFiles.baseColor.minFilter = THREE.NearestFilter
    attributeFiles.baseColor.magFilter = THREE.NearestFilter
    facePlateMesh.material = new THREE.MeshBasicMaterial({ 
      map: attributeFiles.baseColor,
      transparent: true,
      depthTest: false,
      depthWrite: false,
    })
  }

  const handleFile = (e) => {
    const files = e.target.files
    if (files.length <= 2) {
      var file
      for (var i = 0; i < files.length; i++) {
        file = files[i]
        const fileReader = new FileReader()
        if (file.name.split(".")[1] === "fbx") {
          fileReader.readAsDataURL(file)
    
          fileReader.onload = function () {
            environment.resources.loaders.fbxLoader.load(fileReader.result, (fbx) => {
              setAttributeFiles((prevState) => {
                return {
                  ...prevState,
                  fbx: fbx
                }
              })
            })
          }
        }
        else if (file.type.split("/")[0] === "image") {        
          fileReader.readAsDataURL(file)
    
          fileReader.onload = function () {
            environment.resources.loaders.textureLoader.load(fileReader.result, (texture) => {
              texture.encoding = THREE.sRGBEncoding
              setAttributeFiles((prevState) => {
                return {
                  ...prevState,
                  baseColor: texture
                }
              })
            })
          }
        }
        else {
          alert("You are only allowed to upload FBX files for models, and PNG/JPG/WEBP for images")
        }
      }
    }
    else {
      alert("You are trying to upload too many files")
    }
  }

  useEffect(() => {
    if (
      attributeFiles.fbx !== undefined && 
      attributeFiles.baseColor !== undefined &&
      !facePlateParts.includes(bodyPart)
    ) {
      attachAttribute()
    }
    else if (
      attributeFiles.baseColor !== undefined &&
      facePlateParts.includes(bodyPart)
    ) {
      attachFaceTexture()
    }
  }, [attributeFiles])

  return (
    <>
      <div className="attribute-uploader__container">      
        <input 
          type="file" 
          onChange={(e) => handleFile(e)}
          onClick={(e) => e.target.value = null}
          multiple/>
        <img src={bodyPartAssets[bodyPart]} alt="" />
        <p>{bodyPart}</p>

        <div id="attribute-uploader__status-container">
          {
            !facePlateParts.includes(bodyPart) &&
            <div className="attribute-uploader__status">
              <p>Model</p>
              <img 
                src={checkmark} 
                style={{opacity: attributeFiles.fbx !== undefined ? 1 : 0}} 
                alt="" />
            </div>            
          }
          <div className="attribute-uploader__status">
            <p>Texture</p>
            <img 
              src={checkmark} 
              style={{opacity: attributeFiles.baseColor !== undefined ? 1 : 0}} 
              alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

export default AttributeUploader
const headIdxToDetails = {
  1: { name: "Tight Bun", rarity: "Bronze", probability: 0.13 },
  2: { name: "Pigtails", rarity: "Gold", probability: 0.07 },
  3: { name: "Bowl Cut", rarity: "Gold", probability: 0.07 },
  4: { name: "Bob", rarity: "Bronze", probability: 0.13 },
  5: { name: "Spiky", rarity: "Silver", probability: 0.1 },
  6: { name: "Beanie", rarity: "Bronze", probability: 0.13 },
  7: { name: "Baseball Cap", rarity: "Bronze", probability: 0.13 },
  8: { name: "Balding", rarity: "Silver", probability: 0.1 },
  9: { name: "Backwards Cap", rarity: "Silver", probability: 0.1 },
  10: { name: "Afro", rarity: "Diamond", probability: 0.04 },
  beta: { name: "Beta", rarity: "Unique", probability: 0 },
  guppy: { name: "Guppy", rarity: "Unique", probability: 0 },
  artist: { name: "Artist", rarity: "Unique", probability: 0 },
  gwei: { name: "Gwei", rarity: "Unique", probability: 0 },
}

const eyesIdxToDetails = {  
  1: { name: "Serious", rarity: "Bronze", probability: 0.15 },
  2: { name: "Shocked", rarity: "Bronze", probability: 0.15 },
  3: { name: "Angry", rarity: "Silver", probability: 0.1 },
  4: { name: "Love", rarity: "Gold", probability: 0.07 },
  5: { name: "Wired", rarity: "Gold", probability: 0.07 },
  6: { name: "Lazy", rarity: "Bronze", probability: 0.15 },
  7: { name: "Focused", rarity: "Gold", probability: 0.07 },
  8: { name: "Happy", rarity: "Silver", probability: 0.1 },
  9: { name: "Caveman", rarity: "Silver", probability: 0.1 },
  10: { name: "Crazy", rarity: "Diamond", probability: 0.04 },
  200: { name: "Season 2 Alien", rarity: "Diamond", probability: 0.04 },
  201: { name: "Season 2 Bug", rarity: "Diamond", probability: 0.04 },
  eth: { name: "ETH", rarity: "Unique", probability: 0 },
  guppy: { name: "Guppy", rarity: "Unique", probability: 0 },
  gwei: { name: "Gwei", rarity: "Unique", probability: 0 },
  bo: { name: "Bo", rarity: "Unique", probability: 0 },
}

const mouthIdxToDetails = {  
  1: { name: "Smile", rarity: "Bronze", probability: 0.15 },
  2: { name: "Frown", rarity: "Bronze", probability: 0.15 },
  3: { name: "Kissable", rarity: "Gold", probability: 0.07 },
  4: { name: "Plump Lips", rarity: "Diamond", probability: 0.04 },
  5: { name: "Teeth Grind", rarity: "Silver", probability: 0.1 },
  6: { name: "Buck Teeth", rarity: "Silver", probability: 0.1 },
  7: { name: "Tongue Out", rarity: "Bronze", probability: 0.15 },
  8: { name: "Tooth Missing", rarity: "Silver", probability: 0.1 },
  9: { name: "Caveman", rarity: "Gold", probability: 0.07 },
  10: { name: "Spikey Teeth", rarity: "Gold", probability: 0.07 },  
  guppy: { name: "Guppy", rarity: "Unique", probability: 0 },
  artist: { name: "Artist", rarity: "Unique", probability: 0 },
  gwei: { name: "Gwei", rarity: "Unique", probability: 0 },
  bo: { name: "Bo", rarity: "Unique", probability: 0 },
}

const bodyIdxToDetails = {
  1: { name: "Karate Gi", rarity: "Bronze", probability: 0.15 },  
  2: { name: "Sailor Scout", rarity: "Gold", probability: 0.07 },
  3: { name: "Puffer Jacket", rarity: "Gold", probability: 0.07 },
  4: { name: "Office", rarity: "Silver", probability: 0.1 },
  5: { name: "Workout", rarity: "Bronze", probability: 0.15 },
  6: { name: "Tuxedo", rarity: "Diamond", probability: 0.04 },
  7: { name: "Cheerleader", rarity: "Silver", probability: 0.1 },
  8: { name: "Overalls", rarity: "Silver", probability: 0.1 },
  9: { name: "Punk", rarity: "Gold", probability: 0.07 },
  10: { name: "Star Dress", rarity: "Bronze", probability: 0.15 },
  guppy: { name: "Guppy", rarity: "Unique", probability: 0 },
  artist: { name: "Artist", rarity: "Unique", probability: 0 },
  gwei: { name: "Gwei", rarity: "Unique", probability: 0 },
  boxing: { name: "Boxing", rarity: "Unique", probability: 0 },
}

const handsIdxToDetails = {
  1: { name: "Taped Hands", rarity: "Bronze", probability: 0.15 },
  2: { name: "Boxing Gloves", rarity: "Silver", probability: 0.1 },
  3: { name: "Mouse Hands", rarity: "Silver", probability: 0.1 },
  4: { name: "Star Hands", rarity: "Gold", probability: 0.07 },
  5: { name: "Half Gloves", rarity: "Bronze", probability: 0.15 },
  6: { name: "Spike Knuckles", rarity: "Silver", probability: 0.1 },
  7: { name: "Skull Gloves", rarity: "Gold", probability: 0.07 },
  8: { name: "Happy Hands", rarity: "Bronze", probability: 0.15 },
  9: { name: "Ghost Slashers", rarity: "Gold", probability: 0.07 },
  10: { name: "Shredder", rarity: "Diamond", probability: 0.04 },
  bowling: { name: "Bowling", rarity: "Unique", probability: 0 },
  guppy: { name: "Guppy", rarity: "Unique", probability: 0 },
  artist: { name: "Bare Hands", rarity: "Unique", probability: 0 },
  gwei: { name: "Gwei", rarity: "Unique", probability: 0 },
}

const feetIdxToDetails = {
  1: { name: "Athletic Sneakers", rarity: "Bronze", probability: 0.15 },
  2: { name: "Purple Shoes", rarity: "Bronze", probability: 0.15 },
  3: { name: "Cloud Boost", rarity: "Diamond", probability: 0.04 },
  4: { name: "Spiked Cleats", rarity: "Silver", probability: 0.1 },
  5: { name: "Space Boots", rarity: "Gold", probability: 0.07 },
  6: { name: "Waves", rarity: "Gold", probability: 0.07 },
  7: { name: "Bare Feet", rarity: "Bronze", probability: 0.15 },
  8: { name: "Gum Shoes", rarity: "Silver", probability: 0.1 },
  9: { name: "X-Trainer", rarity: "Gold", probability: 0.07 },
  10: { name: "Designer Slides", rarity: "Silver", probability: 0.1 },
  guppy: { name: "Guppy", rarity: "Unique", probability: 0 },
  artist: { name: "Artist", rarity: "Unique", probability: 0 },
  gwei: { name: "Gwei", rarity: "Unique", probability: 0 },
}

// Need to revisit
const limbsIdxToDetails = {  
  1: { name: "Red", rarity: "Bronze", probability: 0.25},
  2: { name: "Orange", rarity: "Bronze", probability: 0.25},
  3: { name: "Yellow", rarity: "Silver", probability: 0.13},
  4: { name: "Green", rarity: "Silver", probability: 0.13},
  5: { name: "Blue", rarity: "Gold", probability: 0.09},
  6: { name: "Navy", rarity: "Gold", probability: 0.09},
  7: { name: "Purple", rarity: "Diamond", probability: 0.06},
}

// Need to revisit
const skinIdxToDetails = {
  1: { name: "Human Tone 1", rarity: "Bronze", probability: 0.12 },
  2: { name: "Human Tone 2", rarity: "Bronze", probability: 0.12 },
  3: { name: "Human Tone 3", rarity: "Bronze", probability: 0.12 },
  4: { name: "Human Tone 4", rarity: "Bronze", probability: 0.12 },
  5: { name: "Human Tone 5", rarity: "Bronze", probability: 0.12 },
  6: { name: "Alien Tone 6", rarity: "Silver", probability: 0.08 },
  7: { name: "Alien Tone 7", rarity: "Silver", probability: 0.08 },
  8: { name: "Alien Tone 8", rarity: "Silver", probability: 0.08 },
  9: { name: "Alien Tone 9", rarity: "Silver", probability: 0.08 },
  10: { name: "Alien Tone 10", rarity: "Silver", probability: 0.08 },
}

const attributeMapping = {
  Head: headIdxToDetails,
  Eyes: eyesIdxToDetails,
  Mouth: mouthIdxToDetails,
  Body: bodyIdxToDetails,
  Hands: handsIdxToDetails,
  Feet: feetIdxToDetails,
  Limbs: limbsIdxToDetails,
  Skin: skinIdxToDetails
}

export default attributeMapping
import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import additionalAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/additional'
import { 
  getAccessoryParams,
  meshesToRemove,
  removeBodyMesh
} from '../../../helpers/asset-management/fighter-accessories/dress-fighter'
import Accessory from '../../../helpers/asset-management/fighter-accessories/accessory'
import { accessoryDirectory } from '../../../helpers/dressing-room/dressing-room-scene'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import * as THREE from 'three'
import './texture-picker.css'

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const presetMappings = {
  marshall : {
    eyes: 1,
    mouth: 1,
  },
  bo : {
    mouth: 2,
  }
}

// Dylan's mouth gets cut off when we remove the eyes for some reason... Look into this

const TexturePicker = ({ attributeType, preset, environment, attributeMapping }) => {
  const [currentAttribute, setCurrentAttribute] = useState(undefined)
  const [loaders, setLoaders] = useState(undefined)

  const series = 1

  const currentMapping = attributeMapping[series][capitalize(attributeType)]

  const options = [
    ...Object.keys(currentMapping).map((id) => {
      return { 
        name: currentMapping[id].name.split(" ").join("-"), 
        label: currentMapping[id].name, 
        id: id 
      }
    })
  ]

  const attachAdditionalAttribute = (attributeModel, key, type, metadata) => {
    const skinMaterial = environment.character.getObjectByName("mesh_head").material
    const limbMaterial = environment.character.getObjectByName("mesh_arms").material
    const headMesh = environment.character.children.filter((child) => child.name === "mesh_head")[0]  
    const params = getAccessoryParams(
      environment.resources.items["gradientMap"], 
      environment.resources.items["colorGamut"], 
      environment.character.scale.x, 
      environment.resources.loaders, 
      headMesh.skeleton.bones[0], 
      headMesh.skeleton,
      skinMaterial,
      limbMaterial,
      environment.resources["toonMaterials"],
      [],
      key, 
      type,
      {}       
    )
    params.fbx = attributeModel
    params.metadata = metadata
    
    params.anchorPoint = environment.character
    params.bindBool = true
    new Accessory(params)
  }

  const attachFaceTexture = (texture) => {
    meshesToRemove[attributeType].forEach((meshName) => {
      removeBodyMesh(environment.character, meshName)
    })

    var facePlateMesh
    if (attributeType === "mouth") {
      facePlateMesh = environment.character.getObjectByName("head_mouth")
    }
    else {
      facePlateMesh = environment.character.getObjectByName("head_eyes")
    }
    texture.encoding = THREE.sRGBEncoding
    texture.minFilter = THREE.NearestFilter
    texture.magFilter = THREE.NearestFilter
    facePlateMesh.material = new THREE.MeshBasicMaterial({ 
      map: texture,
      transparent: true,
      depthWrite: false,
      depthTest: true,
    })
  }

  const changeTexture = (newAttribute) => {
    setCurrentAttribute(newAttribute)
  }

  useEffect(() => {
    setCurrentAttribute(options[0])
    setLoaders({ textureLoader: new THREE.TextureLoader(), fbxLoader: new FBXLoader() })
  }, [])

  useEffect(() => {
    if (preset.name !== "none") {
      const eyesMesh = environment.character.getObjectByName("head_eyes")
      if (attributeType === "eyes" && preset.name === "artist") {        
        eyesMesh.visible = false
        // const headMaterial = environment.character.getObjectByName("mesh_head").material
        // eyesMesh.material = headMaterial
        const modelFile = `${accessoryDirectory}additional/${preset.name}/additional-${preset.name}.fbx`
        loaders.fbxLoader.load(modelFile, (fbx) => {
          attachAdditionalAttribute(
            fbx, 
            preset.name, 
            "additional", 
            additionalAccessoryMetadata["additional-artist"]
          )
        })
      }
      else {
        if (attributeType === "eyes") {
          eyesMesh.visible = true
        }
        removeBodyMesh(environment.character, "additional_accessory")
        var presetAttribute = options.filter((option) => option.id === preset.name)[0]
        if (presetAttribute !== undefined) {
          setCurrentAttribute(presetAttribute)
        }
        else {
          if (presetMappings[preset.name] !== undefined) {
            presetAttribute = options.filter((option) => {
              return option.id === presetMappings[preset.name][attributeType].toString()
            })[0]
            setCurrentAttribute(presetAttribute)
          }
        }
      }
    }
    else {
      if (environment?.character !== undefined) {
        removeBodyMesh(environment.character, "additional_accessory") 
      }
    }
  }, [preset])

  useEffect(() => {
    if (
      environment?.character !== undefined && 
      currentAttribute !== undefined && 
      loaders !== undefined
    ) {
      const specialAttribute = isNaN(+currentAttribute.id)
      const key = specialAttribute ? currentAttribute.id : `${series}-${currentAttribute.id}`
      const modelFile = `${accessoryDirectory}${attributeType}/${key}/${attributeType}-${key}.webp`
      loaders.textureLoader.load(modelFile, (texture) => {
        attachFaceTexture(texture)
      })
    }
  }, [environment, currentAttribute, loaders])

  return (
    <>
      {
        currentAttribute !== undefined &&
        <Dropdown
          containerId={`texture-picker__container--${attributeType}`}
          dropdownId={`texture-picker-dropdown--${attributeType}`}
          selection={currentAttribute}
          clickFunction={changeTexture}
          options={options}
          positionStyling={{}}>
        </Dropdown>
      }
    </>
  )
}

export default TexturePicker
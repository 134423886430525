import Accessory from './accessory'
import headAccessoryMetadata from './metadata/head'
import feetAccessoryMetadata from './metadata/feet'
import handsAccessoryMetadata from './metadata/hands'
import bodyAccessoryMetadata from './metadata/body'
import armsAccessoryMetadata from './metadata/arms'
import legsAccessoryMetadata from './metadata/legs'
import eyesAccessoryMetadata from './metadata/eyes'
import mouthAccessoryMetadata from './metadata/mouth'
import additionalAccessoryMetadata from './metadata/additional'
import { basicFragmentShader } from '../../../shaders/basic/basic-fragment-shader'
import { basicVertexShader } from '../../../shaders/basic/basic-vertex-shader'
import * as THREE from 'three'

const assetsStorageUrl = "https://ai-arena.b-cdn.net/"

const bodyPartsToHide = ["body", "arms", "hands", "legs", "feet"]

const faceAccessoriesWithMesh = {
  eyes: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8"],
  mouth: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8", "2-9", "2-10"]
}

const presetCustomTextures = {
  eyesId: {
    50: "eth"
  },
  headId: {
    50: "beta",
    "marshall": "1-5"
  },
  bodyId: {
    "marshall": "1-1"
  },
  handsId: {
    50: "bowling",
    "bo": "1-2"
  },
}

const getCustomTexture = (data, key) => {
  if (presetCustomTextures[key] !== undefined) {
    const customTexture = presetCustomTextures[key][data[key]]
    if (customTexture !== undefined) {
      return customTexture
    }
  }
}

const getAccessoryId = (data, key) => {
  const customTexture = getCustomTexture(data, key)
  if (customTexture !== undefined) {
    return [customTexture, data[key]]
  }
  return [`${Number.isInteger(data[key]) ? data.series + "-" : ""}${data[key]}`]
}

const removeEyes = (character) => {
  const eyesMesh = character.getObjectByName("head_eyes")
  const headMaterial = character.getObjectByName("mesh_head").material
  if (eyesMesh !== undefined) {
    eyesMesh.material = headMaterial
  }
}

const removeMouth = (character) => {
  const mouthMesh = character.getObjectByName("head_mouth")
  const headMaterial = character.getObjectByName("mesh_head").material
  if (mouthMesh !== undefined) {
    mouthMesh.material = headMaterial
  }
}

const removeBodyMesh = (body, bodyPartName) => {
  const bodyParts = body.children.filter((child) => child.name === bodyPartName)
  if (bodyParts.length > 0) {
    for (var i = 0; i < bodyParts.length; i++) {
      bodyParts[i].material.dispose()
      bodyParts[i].geometry.dispose()
      body.remove(bodyParts[i])
    }
  }
}

const removeCrown = (character) => {
  const headBone = character.getObjectByName("Head_M")
  const crown = character.getObjectByName("crown")
  if (headBone !== undefined && crown !== undefined) {
    headBone.remove(crown)
  }
}

const toggleBodyMeshVisibility = (body, bodyPartName, visibility) => {
  const bodyPart = body.children.filter((child) => child.name === bodyPartName)[0]
  if (bodyPart !== undefined) {
    bodyPart.visible = visibility
  }
}

const meshesToRemove = {
  "eyes": ["mesh_eyes", "eyes_glass"],
  "mouth": ["mesh_mouth"],
  "head": ["head_accessory", "hair", "head_glass"],
  "feet": ["feet_accessory", "feet_skin"],
  "hands": ["hands_accessory", "hands_skin"],
  "body": ["body_accessory", "body_skin"],
  "arms": ["arms_accessory", "arms_skin"],
  "legs": ["legs_accessory", "legs_accessory_extra", "legs_skin"]
}

const removeFace = (character) => {
  const eyesMesh = character.getObjectByName("head_eyes")
  const mouthMesh = character.getObjectByName("head_mouth")
  const headMaterial = character.getObjectByName("mesh_head").material
  if (eyesMesh !== undefined && mouthMesh !== undefined) {
    eyesMesh.material = headMaterial
    mouthMesh.material = headMaterial
  }
}

const stripDownFighter = (character) => {
  removeFace(character)
  removeCrown(character)
  Object.keys(meshesToRemove).forEach((type) => {
    meshesToRemove[type].forEach((meshName) => {
      removeBodyMesh(character, meshName)
      toggleBodyMeshVisibility(character, "mesh_" + type, true)
    })
  })
}

const getAccessoryParams = (
  gradientMap, 
  colorGamut,
  scale, 
  loaders, 
  rootBone, 
  skeleton, 
  skinMaterial, 
  limbMaterial,
  toonMaterials,
  allMeshes,
  accessoryId, 
  accessoryGroup, 
  metadata
) => {
  const fileName = `${accessoryGroup}-${accessoryId}.fbx`
  const name = fileName.split(".")[0]
  return {
    path: `${assetsStorageUrl}accessories/${accessoryGroup}/${accessoryId}/`,
    name: name,
    metadata: metadata[name],
    scale: scale,
    gradientMap: gradientMap,
    colorGamut: colorGamut,
    loaders: loaders,
    rootBone: rootBone, 
    skeleton: skeleton,
    limbMaterial: limbMaterial,
    skinMaterial: skinMaterial,
    toonMaterials: toonMaterials,
    allMeshes: allMeshes,
    shadowBool: false
  }
}

const addFacePlate = (accessoryInputs, facePlate) => {
  const fighterModel = accessoryInputs[0]
  const params = getAccessoryParams(
    ...accessoryInputs.slice(1, accessoryInputs.length), 
    0, 
    "face", 
    {}
  )
  params.metadata = {
    rotation: { x: 0, y: 0, z: 0 },
    position: { x: 0, y: 0, z: 0 },
    scaleMultiple: 1,
    useTexture: true
  }
  params.fbx = facePlate
  params.anchorPoint = fighterModel
  params.bindBool = true
  new Accessory(params)
}

const addFaceTexture = (facePlateMesh, filePath, fighterModel, resources, cachedAssets) => {
  const textureNameArray = filePath.split("/")
  const textureName = textureNameArray[textureNameArray.length - 1].replace(".webp", "")
  var baseColor
  if (cachedAssets !== undefined && cachedAssets[textureName] !== undefined) {
    baseColor = cachedAssets[textureName]
  }
  else {
    baseColor = resources.loaders.textureLoader.load(filePath)
    if (cachedAssets !== undefined) {
      cachedAssets[textureName] = baseColor
    }
  }
  baseColor.encoding = THREE.sRGBEncoding
  baseColor.minFilter = THREE.NearestFilter
  baseColor.magFilter = THREE.NearestFilter
  facePlateMesh.material = new THREE.ShaderMaterial({
    uniforms: {
      uTexture: { type: "t", value: baseColor },
      USE_TEXTURE: { type: "i", value: true }
    },
    vertexShader: basicVertexShader,
    fragmentShader: basicFragmentShader,
    transparent: true,
    depthWrite: false,
    depthTest: true
  })
  const playerIdx = fighterModel.name.split("fighter-")[1]
  if (resources[`toonMaterials-${playerIdx}`] !== undefined) {
    resources[`toonMaterials-${playerIdx}`].push(facePlateMesh.material)
  }
}

const prepareParams = (params, fighterModel, customId, cachedAssets) => {
  params.customTexture = customId
  params.anchorPoint = fighterModel
  params.bindBool = true
  params.cachedAssets = cachedAssets
  params.edgeData = {
    width: params.skinMaterial.uniforms.edgeWidthRatio.value,
    color: params.skinMaterial.uniforms.edgeColor.value,
  }
}

const addAccessory = (name, accessoryInputs, accessoryIdData, accessoryMetadata, cachedAssets) => {
  const [accessoryId, customId] = accessoryIdData
  const fighterModel = accessoryInputs[0]
  const params = getAccessoryParams(
    ...accessoryInputs.slice(1, accessoryInputs.length), 
    accessoryId, 
    name, 
    accessoryMetadata
  )

  const meshExists = accessoryMetadata[name + "-" + accessoryId] !== undefined
  if (meshExists) {
    prepareParams(params, fighterModel, customId, cachedAssets)
    new Accessory(params)
    if (bodyPartsToHide.includes(name)) {
      // removeBodyMesh(fighterModel, "mesh_" + name)
      toggleBodyMeshVisibility(fighterModel, "mesh_" + name, false)
    }
  }  
}

const addEyesAccessory = (accessoryInputs, resources, accessoryIdData, cachedAssets) => {
  let accessoryId = accessoryIdData[0]
  if (faceAccessoriesWithMesh.eyes.includes(accessoryId)) {
    addAccessory("eyes", accessoryInputs, accessoryIdData, eyesAccessoryMetadata, cachedAssets)
  }
  else {
    const fighterModel = accessoryInputs[0]
    const facePlateMesh = fighterModel.getObjectByName("head_eyes")
    if (accessoryId === "bare") {
      facePlateMesh.visible = false
      return
    }
    const filename = `eyes-${accessoryId}${accessoryId === "eth" ? "--new" : ""}`
    const path = `${assetsStorageUrl}accessories/eyes/${accessoryId}/${filename}.webp`
    addFaceTexture(facePlateMesh, path, fighterModel, resources, cachedAssets)
  }
}

const addMouthAccessory = (accessoryInputs, resources, accessoryIdData, darkerMouth, cachedAssets) => {
  const accessoryId = accessoryIdData[0]
  if (faceAccessoriesWithMesh.mouth.includes(accessoryId)) {
    addAccessory("mouth", accessoryInputs, accessoryIdData, mouthAccessoryMetadata, cachedAssets)  
  }
  else {
    const fighterModel = accessoryInputs[0]
    const facePlateMesh = fighterModel.getObjectByName("head_mouth")
    const basePath = `${assetsStorageUrl}accessories/mouth/${accessoryId}/mouth-`
    const path = `${basePath}${accessoryId}${darkerMouth ? "--darker" : ""}.webp`
    addFaceTexture(facePlateMesh, path, fighterModel, resources, cachedAssets)
  }
}

const addHeadAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {  
  addAccessory("head", accessoryInputs, accessoryIdData, headAccessoryMetadata, cachedAssets)  
}

const addBodyAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {  
  addAccessory("body", accessoryInputs, accessoryIdData, bodyAccessoryMetadata, cachedAssets)
}

const addArmsAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {  
  addAccessory("arms", accessoryInputs, accessoryIdData, armsAccessoryMetadata, cachedAssets)
}

const addLegsAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {  
  addAccessory("legs", accessoryInputs, accessoryIdData, legsAccessoryMetadata, cachedAssets)
}

const addFeetAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {
  const bareFeetId = "1-7"
  if (accessoryIdData[0] === bareFeetId) return
  addAccessory("feet", accessoryInputs, accessoryIdData, feetAccessoryMetadata, cachedAssets)
}

const addHandsAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {  
  if (accessoryIdData[0] === "bare") return
  addAccessory("hands", accessoryInputs, accessoryIdData, handsAccessoryMetadata, cachedAssets)
}

const addAdditionalAccessory = (accessoryInputs, accessoryIdData, cachedAssets) => {  
  addAccessory("additional", accessoryInputs, accessoryIdData, additionalAccessoryMetadata, cachedAssets)
}

const dressFighter = (
  fighterModel, 
  data, 
  scale, 
  resources, 
  nakedBool, 
  allMeshes, 
  cachedAssets,
  shadowBool=false,
  darkerMouth=false
) => {
  const skinMaterial = fighterModel.getObjectByName("mesh_head").material
  const limbMaterial = fighterModel.getObjectByName("mesh_arms").material
  const feetMesh = fighterModel.children.filter((child) => child.name === "mesh_feet")[0]
  const accessoryInputs = [
    fighterModel, 
    resources.items["gradientMap"], 
    resources.items["colorGamut"],
    scale, 
    resources.loaders, 
    feetMesh.skeleton.bones[0], 
    feetMesh.skeleton,
    skinMaterial,
    limbMaterial,
    resources["toonMaterials"],
    allMeshes,
  ]

  const eyesMesh = fighterModel.getObjectByName("head_eyes")
  if (eyesMesh === undefined) {
    addFacePlate(accessoryInputs, resources.items["facePlate"])
  }
  if (!nakedBool) {
    if (!shadowBool) {
      addEyesAccessory(accessoryInputs, resources, getAccessoryId(data, "eyesId"), cachedAssets)
      addMouthAccessory(accessoryInputs, resources, getAccessoryId(data, "mouthId"), darkerMouth, cachedAssets)
    }
    addHeadAccessory(accessoryInputs, getAccessoryId(data, "headId"), cachedAssets)
    addBodyAccessory(accessoryInputs, getAccessoryId(data, "bodyId"), cachedAssets)
    addArmsAccessory(accessoryInputs, getAccessoryId(data, "bodyId"), cachedAssets)
    addLegsAccessory(accessoryInputs, getAccessoryId(data, "bodyId"), cachedAssets)
    addFeetAccessory(accessoryInputs, getAccessoryId(data, "feetId"), cachedAssets)
    addHandsAccessory(accessoryInputs, getAccessoryId(data, "handsId"), cachedAssets)
    if (data.additionalAccessory) {
      addAdditionalAccessory(accessoryInputs, getAccessoryId(data, "additionalId"), cachedAssets)
    }
  }
}

export {
  removeBodyMesh,
  meshesToRemove,
  stripDownFighter,
  removeFace,
  removeEyes,
  removeMouth,  
  getAccessoryParams,
  dressFighter
}
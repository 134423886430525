import ToonShader from '../../../shaders/toon/toon-shader'
import headAccessoryMetadata from './metadata/head'
import feetAccessoryMetadata from './metadata/feet'
import handsAccessoryMetadata from './metadata/hands'
import bodyAccessoryMetadata from './metadata/body'
import armsAccessoryMetadata from './metadata/arms'
import legsAccessoryMetadata from './metadata/legs'
import * as THREE from 'three'

const convertPrependForLimbs = (prepend) => {
  return prepend.replace(/arms/g, "/body").replace(/legs/g, "/body")
}

const facePlateMeshNames = ["head_mouth", "head_eyes"]

const allMetadata = {
  head: headAccessoryMetadata,
  feet: feetAccessoryMetadata,
  hands: handsAccessoryMetadata,
  body: bodyAccessoryMetadata,
  arms: armsAccessoryMetadata,
  legs: legsAccessoryMetadata,
}

const presetColor = {
  "guppy": 2,
  "artist": 2,
  "gwei": 1,
  "marshall": "marshall",
  "bo": "bo"
}

const bareAttributes = {
  head: [],
  eyes: ["artist"],
  mouth: [],
  body: [],
  hands: ["artist"],
  feet: ["marshall", "1-7"],
}

const presetNormalAttribute = {
  head: {},
  eyes: { marshall: 1 },
  mouth: { marshall: 1 },
  body: { bo: "boxing" },
  hands: { marshall: 1 },
  feet: { bo: 1 },
}

const getAttributeId = (name, attribute) => {
  if (bareAttributes[attribute].includes(name)) {
    return "bare"
  }
  else {
    const attributeId = presetNormalAttribute[attribute][name]
    if (attributeId !== undefined) {
      return attributeId
    }
    else {
      return name
    }
  }
}

const getPresetAccessoryData = (name) => {
  const accessoryData = {
    series: 1,
    eyesId: getAttributeId(name, "eyes"),
    mouthId: getAttributeId(name, "mouth"),
    headId: getAttributeId(name, "head"),
    bodyId: getAttributeId(name, "body"),
    handsId: getAttributeId(name, "hands"),
    feetId: getAttributeId(name, "feet"),
    skinId: presetColor[name],
    limbId: name,
    additionalId: name,
    additionalAccessory: name === "artist",
    composableId: 2,
    nakedFighter: false,
  }
  return accessoryData
}

const getAccessoryData = (physicalDetails) => {
  const accessoryData = {
    series: 1,
    eyesId: parseInt(physicalDetails.Eyes.id),
    mouthId: parseInt(physicalDetails.Mouth.id),
    headId: parseInt(physicalDetails.Head.id),
    bodyId: parseInt(physicalDetails.Body.id),
    handsId: parseInt(physicalDetails.Hands.id),
    feetId: parseInt(physicalDetails.Feet.id),
    skinId: parseInt(physicalDetails.Skin.id),
    limbId: parseInt(physicalDetails.Limbs.id),
    composableId: 2,
    nakedFighter: false,
  }
  return accessoryData
}

const getTexture = (
  textureLoader, 
  texturePath, 
  filePrepend, 
  gradientMap, 
  baseColor, 
  scale, 
  edgeData
) => {  
  if (baseColor === undefined) {
    const prepend = convertPrependForLimbs(texturePath + filePrepend)
    baseColor = textureLoader.load(prepend + `--base-color.webp`)
    baseColor.encoding = THREE.sRGBEncoding
  }

  const toonShader = new ToonShader({
    stepTexture: gradientMap,
    texture: baseColor,
    useTexture: true,
    modelScale: scale,
    color: new THREE.Vector4(1.0, 0.6, 0.4, 1.0),
    shadeColor: null,
    edgeWidthRatio: edgeData !== undefined ? edgeData.width : 0.4,
    edgeColor: edgeData !== undefined ? edgeData.color : new THREE.Vector4(0, 0, 0, 1),
    // useSpecular: true
  })
  const texture = new THREE.ShaderMaterial(toonShader)
  return texture
}

const getTextureColor = (metadata, gradientMap, scale, edgeData) => {
  const toonShader = new ToonShader({
    stepTexture: gradientMap,
    texture: null,
    useTexture: false,
    modelScale: scale,
    color: metadata.color,
    shadeColor: metadata.shadeColor,
    rimColor: metadata.rimColor,
    edgeWidthRatio: edgeData !== undefined ? edgeData.width : 0.4,
    edgeColor: edgeData !== undefined ? edgeData.color : new THREE.Vector4(0, 0, 0, 1),
  })
  return new THREE.ShaderMaterial(toonShader)
}

export default class Accessory {
  constructor(params) {
    this.init(params)
  }

  init(params) {
    this.params = params
    if (this.params.fbx !== undefined) {
      this.attachAccessory(this.params.fbx)
    }
    else {
      this.loadModels() 
    }
  }

  getTextureName() {
    var textureName = this.params.name
    if (this.params.customTexture !== undefined) {
      textureName = this.params.name.split("-")[0] + "-" + this.params.customTexture
    }
    return textureName
  }

  attachAccessory(fbx) {
    for (var i = 0; i < fbx.children.length; i++) {
      this.clothing = fbx.children[i].clone()
      if (this.clothing.type.includes("Mesh")) {
        if (
          !this.clothing.name.includes("skin") && 
          !this.clothing.name.includes("_glass") && 
          (
            (this.clothing.name !== "hair" && this.params.metadata.useTexture) ||
            (this.clothing.name === "hair" && !this.params.metadata.customHairColor)
          ) &&
          !facePlateMeshNames.includes(this.clothing.name)
        ) {
          const textureName = this.getTextureName()
          this.clothing.material = getTexture(
            this.params.loaders.textureLoader,
            this.params.path,
            textureName,
            this.params.gradientMap,
            this.params.baseColor,
            this.params.scale,
            this.params.edgeData
          )
          this.cacheTexture(this.clothing.material.uniforms.uTexture.value)
          this.params.toonMaterials.push(this.clothing.material)
          if (this.params.fighterToonMaterials !== undefined) {
            this.params.fighterToonMaterials.push(this.clothing.material)
          }
        }
        else {          
          if (this.clothing.name.includes("skin") || facePlateMeshNames.includes(this.clothing.name)) {
            if (this.clothing.name.includes("arms") || this.clothing.name.includes("legs")) {
              this.clothing.material = this.params.limbMaterial
            }
            else {
              this.clothing.material = this.params.skinMaterial
            }
          }
          else {
            if (this.clothing.name.includes("_glass")) {
              this.clothing.material = new THREE.MeshBasicMaterial({ color: "navy", transparent: true, opacity: 0.2 })
            }
            else {
              this.clothing.material = getTextureColor(
                this.params.metadata, 
                this.params.gradientMap,
                this.params.scale,
                this.params.edgeData
              )
            }
            this.params.toonMaterials.push(this.clothing.material)
            if (this.params.fighterToonMaterials !== undefined) {
              this.params.fighterToonMaterials.push(this.clothing.material)
            }
          }
        }
  
        if (this.params.customName !== undefined) {
          this.clothing.name = this.params.customName
        }
        
        const rotation = this.params.metadata.rotation
        const position = this.params.metadata.position
  
        this.clothing.scale.setScalar(Math.abs(this.params.metadata.scaleMultiple))
        this.clothing.rotation.set(rotation.x, rotation.y, rotation.z)
        this.clothing.position.set(position.x, position.y, position.z)

        if (this.params.bindBool) {          
          this.clothing.skeleton = this.params.skeleton
          const bindMatrix = this.params.rootBone.matrixWorld
          const quaternion = new THREE.Quaternion()
          quaternion.setFromAxisAngle( new THREE.Vector3( rotation.x, rotation.y, rotation.z ), 0 )
          bindMatrix.makeRotationFromQuaternion(quaternion)
          bindMatrix.scale(new THREE.Vector3(this.clothing.scale.x, this.clothing.scale.y, this.clothing.scale.z))
          bindMatrix.setPosition(position.x, position.y, position.z)
          this.clothing.bind( this.clothing.skeleton, bindMatrix )
        }
        this.clothing.castShadow = true
        this.params.anchorPoint.add(this.clothing)
        if (this.params.allMeshes !== undefined) {
          this.params.allMeshes.push(this.clothing)
        }
      }
    }
  }

  cacheTexture(texture) {
    if (this.params.context !== undefined) {
      this.params.context[1]((prevState) => {
        const textureName = this.getTextureName()
        prevState.fighterTextures[textureName] = texture
        return { ...prevState }
      })
    }    
  }

  cacheModel(fbx) {
    if (this.params.context !== undefined) {
      this.params.context[1]((prevState) => {
        prevState.fighterModels[this.params.name] = fbx
        return { ...prevState }
      })
    }
  }

  loadModels() {
    if (
      this.params.context !== undefined && 
      this.params.context[0].fighterModels[this.params.name] !== undefined
    ) {
      const textureName = this.getTextureName()
      if (this.params.context[0].fighterTextures[textureName] !== undefined) {
        this.params.baseColor = this.params.context[0].fighterTextures[textureName]
      }
      this.attachAccessory(this.params.context[0].fighterModels[this.params.name])
    }
    else {
      this.params.loaders.fbxLoader.load(this.params.path + this.params.name + ".fbx", (fbx) => {
        this.attachAccessory(fbx)
        this.cacheModel(fbx)
      })
    }
  }
}

export {
  getAccessoryData,
  getPresetAccessoryData,
  convertPrependForLimbs
}
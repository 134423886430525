import React from "react"
import { useGetCSRFToken } from "../../../helpers/hooks/signout/signout"
import { authServer } from "../../../env-variables"
import "./sign-out-button.css"

const SignOutButton = ({ className }) => {
  const { csrfToken } = useGetCSRFToken()

  const handleSignOutNew = async () => {
    try {
      if (csrfToken !== null) {
        const response = await fetch(`${authServer}api/auth/signout?callbackUrl=${authServer}api/auth/session`, {
          method: "POST",
          credentials: "include",
          headers: { 
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": JSON.stringify(csrfToken),
          },
          body: JSON.stringify(csrfToken),
        })
        console.log(response, "response")
        if (response.ok) window.location.href = `${authServer}?redirect=builder`
      } else {
        console.log(csrfToken, "csrfToken is null")
      }
    } catch (error) {
      console.error("Error fetching CSRF token:", error)
    }
  }

  return (
    <div id='sign-out-button' className={className} onClick={() => handleSignOutNew()}>
      Sign Out
    </div>
  )
}

export default SignOutButton

import { toonVertexShader } from './toon-vertex-shader'
import { toonFragmentShader } from './toon-fragment-shader'
import * as THREE from 'three'

export default class ToonShader {
  constructor({
    stepTexture, 
    texture, 
    color, 
    shadeColor,
    edge = false,
    edgeColor = new THREE.Vector4(0, 0, 0, 1), 
    edgeWidthRatio = 0.3, 
    useTexture = true,
    rimColor = new THREE.Color("white"),
    modelScale = 1,
    opacity = 1,
    useSpecular = false
  }){
    if (shadeColor === null) {
      const shadeMultiple = 0.7
      shadeColor = new THREE.Vector4(
        color.x * shadeMultiple, 
        color.y * shadeMultiple, 
        color.z * shadeMultiple, 
        1
      )
    }
    this.uniforms = {
      edge              : { type: 'i',    value: edge },
      useTexture        : { type: 'i',    value: useTexture },
      lightPosition     : { type: 'v3',   value: null },
      stepTexture       : { type: 't',    value: stepTexture },
      uTexture          : { type: 't',    value: texture },
      edgeColor         : { type: 'v4',   value: edgeColor },
      color             : { type: 'v4',   value: color},
      shadeColor        : { type: 'v4',   value: shadeColor},
      edgeWidthRatio    : { type: 'f',    value: edgeWidthRatio },
      modelScale        : { type: 'f',    value: modelScale },
      USE_FLASHING      : { type: 'i',    value: false },
      flashTimer        : { type: 'f',    value: 0 },
      USE_RIMLIGHT      : { type: 'i',    value: true },
      USE_SPECULAR      : { type: 'i',    value: useSpecular },
      rimStep           : { type: 'f',    value: 0.25 },
      rimOffset         : { type: 'V3',   value: new THREE.Vector3(0.15, 0.05, 0) },      
      rimColor          : { type: 'V3',   value: rimColor },
      specularStep      : { type: 'f',    value: 0.97 },
      opacity           : { type: "f",    value: opacity },
      objectPosition    : { type: 'v3',   value: new THREE.Vector3(0, 0, 0) }
    };
    this.vertexShader = toonVertexShader
    this.fragmentShader = toonFragmentShader
  }
}
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import { TextureLoader } from "three"
import EventEmitter from "../threejs/event-emitter"
import getSources from "./source-manager"

export default class Resources extends EventEmitter {
  constructor(name, additionalSources, cachedGameModels, delayLoading = false) {
    super()

    additionalSources = additionalSources !== undefined ? additionalSources : []
    this.sources = [...getSources(name), ...additionalSources]
    this.name = name
    this.cachedGameModels = cachedGameModels !== undefined ? cachedGameModels : {}

    this.items = {}
    this.toLoad = this.sources.length
    this.loaded = 0

    this.setLoaders()
    if (!delayLoading) {
      this.startLoading()
    }
  }

  setLoaders() {
    this.loaders = {}
    this.loaders.gltfLoader = new GLTFLoader()
    this.loaders.fbxLoader = new FBXLoader()
    this.loaders.textureLoader = new TextureLoader()
  }

  startLoading() {
    for (const source of this.sources) {
      if (this.cachedGameModels[source.name] !== undefined) {
        this.sourceLoaded(source, this.cachedGameModels[source.name])
      }
      else {
        if (source.type === "gltfModel") {
          this.loaders.gltfLoader.load(source.path, (file) => {
            this.sourceLoaded(source, file)
          })
        } else if (source.type === "fbxModel") {
          this.loaders.fbxLoader.load(source.path, (file) => {
            this.sourceLoaded(source, file)
          })
        } else if (source.type === "texture") {
          this.loaders.textureLoader.load(source.path, (file) => {
            this.sourceLoaded(source, file)
          })
        }
      }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file

    this.loaded++
    this.trigger("itemLoaded")

    if (this.loaded === this.toLoad) {
      this.trigger("ready")
    }
  }
}

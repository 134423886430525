import React, { useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { 
  agilityDroneAnimationsMapping,
  animationsMapping,
  attackDroneAnimationsMapping,
  auAnimationsMapping, 
  defenseDroneAnimationsMapping
} from '../../../helpers/collision-maker/animation-mapping'
import { 
  animationAtom,
  animationDataAtom,
  animationReadyAtom,
  animationFrameAtom, 
  colliderTypeAtom, 
  totalFramesAtom,
  fighterTypeAtom
} from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './animation-menu.css'

const animationFiles = {
  "fighter": animationsMapping,
  "agnes-ultimate": auAnimationsMapping,
  "attack-drone": attackDroneAnimationsMapping,
  "defense-drone": defenseDroneAnimationsMapping,
  "agility-drone": agilityDroneAnimationsMapping
}

const AnimationMenu = ({ showShapeMenu }) => {
  const animationData = useAtom(animationDataAtom)[0]
  const animationsReady = useAtom(animationReadyAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const [animation, setAnimation] = useAtom(animationAtom)
  const [animationFrame, setAnimationFrame] = useAtom(animationFrameAtom) 
  const setColliderType = useAtom(colliderTypeAtom)[1]
  const setTotalFrames = useAtom(totalFramesAtom)[1]

  const positionStyling = {
    top: showShapeMenu ? "80px" : "10px",
    left: "20px"
  }
  const animationOptions = [
    { name: "a-pose", label: "A Pose" },
    ...Object.keys(animationFiles[fighterType]).flatMap((key) => {
      return Object.values(animationFiles[fighterType][key].names).map((name) => {
        return { name: name, label: name.split("-").join(" ") }
      })
    })
  ]

  const changeAnimation = (newAnimation) => {
    if (animation.name !== "a-pose") {
      animationData.animations[animation.name].action.stop()
    }
    if (newAnimation.name === "a-pose") {
      setColliderType({ name: "hurtbox", label: "hurtbox" })
      setTotalFrames(0)
    }
    setAnimation(newAnimation)
  }

  useEffect(() => {
    if (animationsReady) {
      setAnimation(animationOptions[0])
    }
  }, [animationsReady])

  useEffect(() => {
    if (
      animationsReady && 
      animation !== undefined && 
      animationData !== undefined
    ) {
      if (animation.name === "a-pose") {
        // animationData.fighter.rotation.y = 0
        animationData.armature.rotation.y = 0
      }
      else {
        if (fighterType === "fighter") {
          animationData.armature.rotation.y = Math.PI / 2
        }
        else {
          animationData.armature.rotation.y = 0
        }
        setTotalFrames(animationData.animations[animation.name].frameCount)
        animationData.animations[animation.name].action.play()
        animationData.animations[animation.name].action.time = 0
        setAnimationFrame(0)
        animationData.mixer.update(0)
      }
    }
  }, [animation, animationData, animationsReady])

  useEffect(() => {
    if (animationData !== undefined) {
      const roundingError = 1 / 600
      const { duration } = animationData.animations[animation.name].clip
      animationData.animations[animation.name].action.play()
      animationData.animations[animation.name].action.time = (
        Math.min(Math.max(animationFrame / 60 + roundingError, 0), duration)
      )
      animationData.mixer.update(0)
    }
  }, [animationFrame])

  return (
    <>
      {
        animation !== undefined &&
        <Dropdown
          containerId="animation-menu__container"
          dropdownId="animation-menu-dropdown"
          selection={animation}
          clickFunction={changeAnimation}
          options={animationOptions}
          positionStyling={positionStyling}>
        </Dropdown>
      }
    </>
  )
}

export default AnimationMenu
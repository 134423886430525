import * as THREE from 'three'

export default class Camera {
    constructor(sizes, scene, params) {
        this.sizes = sizes
        this.scene = scene
        this.params = params
        this.setInstance()
    }

    setInstance() {
        const fov = (this.params !== undefined && this.params.fov !== undefined) ? this.params.fov : 30
        const near = (this.params !== undefined && this.params.near !== undefined) ? this.params.near : 1
        const far = (this.params !== undefined && this.params.far !== undefined) ? this.params.far : 1000

        this.instance = new THREE.PerspectiveCamera(fov, this.sizes.width / this.sizes.height, near, far)
        this.instance.position.set(0, 3.5, 30)
        this.instance.lookAt(new THREE.Vector3(0, 3.5, 0))

        this.initialFov = fov        
        this.initialHeight = this.sizes.height

        this.scene.add(this.instance)
    }

    resize(resizeBool) {
        this.instance.aspect = this.sizes.width / this.sizes.height
        if (!resizeBool) {
          const heightRatio = this.sizes.height / this.initialHeight
          const tanFOV = Math.tan( ( ( Math.PI / 180 ) * this.initialFov / 2 ) );
          this.instance.fov = ( 360 / Math.PI ) * Math.atan( tanFOV * heightRatio );
        }
        else {
          this.instance.fov = this.initialFov
          this.initialFov = this.instance.fov
          this.initialHeight = this.sizes.height
        }
        this.instance.updateProjectionMatrix()
    }
}
import React, { useState, useEffect } from 'react'
import DownloadButton from '../download-button/DownloadButton'
import PhotoBoothScene from '../../../helpers/photo-booth/photo-booth-scene'
import './photo-booth.css'

const PhotoBooth = () => {
  const [environment, setEnvironment] = useState(undefined)
  const [animationData, setAnimationData] = useState(undefined)
  const [animationsReady, setAnimationsReady] = useState(false)
  const [images, setImages] = useState([])

  useEffect(() => {
    const env = new PhotoBoothScene(setAnimationsReady, setAnimationData)
    setEnvironment(env)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  useEffect(() => {
    if (
      animationsReady &&
      animationData !== undefined
    ) {
      animationData.fighter.getObjectByName("Armature").rotation.y = Math.PI / 3.5
      animationData.animations["idle"].action.play()
      animationData.animations["idle"].action.time = 0
      animationData.mixer.update(0)
    }
  }, [animationData, animationsReady])

  return (
    <>
      <div id="photo-booth__container">
        {
          animationsReady &&
          <DownloadButton 
            animationData={animationData}
            images={images}
            setImages={setImages}
            canvasId="canvas">
          </DownloadButton>
        }        
      </div>
    </>
  )
}

export default PhotoBooth
import React, { useState, useEffect } from 'react'
import SaveImagesScene from '../../../helpers/save-images/save-images-scene'
import attributeMapping from '../../../helpers/asset-management/fighter-accessories/generations/generation-1'
import ColorPicker from '../color-picker/ColorPicker'
import AttributePicker from '../attribute-picker/AttributePicker'
import TexturePicker from '../texture-picker/TexturePicker'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import * as d3 from 'd3'
import './save-images.css'

const presetOptions = [
  { name: "none", label: "None" },
  { name: "guppy", label: "Guppy" },
  { name: "artist", label: "Artist" },
  { name: "gwei", label: "Gwei" },
  { name: "marshall", label: "Marshall" },
  { name: "bo", label: "Bo" }
]

const SaveImages = () => {
  const [animationData, setAnimationData] = useState(undefined)
  const [animationsReady, setAnimationsReady] = useState(false)
  const [environment, setEnvironment] = useState(undefined)
  const [preset, setPreset] = useState(presetOptions[0])

  const changePreset = (newPreset) => {
    setPreset(newPreset)
  }

  useEffect(() => {
    const env = new SaveImagesScene(setAnimationsReady, setAnimationData)

    setEnvironment(env)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  const poseFighter = () => {
    const flag = d3.select("#save-images--pose-flag")
    if (flag._groups[0][0] !== undefined && flag._groups[0][0] !== null) {
      flag.style("display", "block")
    }    
  }

  const saveAllImages = () => {
    const flag = d3.select("#save-images--flag")
    if (flag._groups[0][0] !== undefined && flag._groups[0][0] !== null) {
      flag.style("display", "block")
    }
  }

  return (
    <>
      <div id="save-images__container">        
        <div id="save-images--flag" style={{ display: "none" }}></div>
        <div id="save-images--pose-flag" style={{ display: "none" }}></div>
        <div id="save-images">
          <button onClick={() => saveAllImages()}>SAVE</button>
          <button onClick={() => poseFighter()}>POSE</button>
        </div>
        {/* <Dropdown
          containerId={"preset-character__container"}
          dropdownId={"preset-character-dropdown"}
          selection={preset}
          clickFunction={changePreset}
          options={presetOptions}
          positionStyling={{}}>
        </Dropdown> */}

        {/* <ColorPicker 
          attributeType="skin"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </ColorPicker>
        <ColorPicker 
          attributeType="limbs"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </ColorPicker> */}
        
        {/* <AttributePicker
          attributeType="head"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </AttributePicker>
        <AttributePicker
          attributeType="feet"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </AttributePicker>
        <AttributePicker
          attributeType="hands"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </AttributePicker>
        <AttributePicker
          attributeType="body"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </AttributePicker> */}

        {/* <TexturePicker
          attributeType="eyes"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </TexturePicker>
        <TexturePicker
          attributeType="mouth"
          preset={preset}
          environment={environment}
          attributeMapping={attributeMapping}>
        </TexturePicker>         */}
      </div>
    </>
  )
}

export default SaveImages
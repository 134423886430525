import createScene from "../threejs/scene-setup"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
import Resources from "../resource-management/resources"
import { animationsMapping, emoteAnimations } from "../collision-maker/animation-mapping"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import * as THREE from "three"

const allAnimations = {...animationsMapping, ...emoteAnimations}

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

export default class SilhouetteMakerScene {
  constructor(setAnimationsReady, setAnimationData) {
    this.initialize(setAnimationsReady, setAnimationData)
  }

  startAnimationAtFrameZero(clip) {
    let duration = 0
    clip.tracks.forEach((track) => {
      const newTimes = new Float32Array(track.times.length)
      newTimes[0] = 0
      newTimes.set(track.times.slice(0, track.times.length - 1), 1)
      track.times = newTimes
      if (newTimes[newTimes.length - 1] > duration) {
        duration = newTimes[newTimes.length - 1]
      }
    })
    clip.duration = duration
  }

  initialize(setAnimationsReady, setAnimationData) {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "silhouette-maker",
      camera: { fov: 25, near: 0.1, far: 1000 },
      drawBool: true
    })

    this.targetFPS = 30
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 7)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    const modelName = "fighter"
    const additionalSources = [
      {
        name: modelName,
        type: "fbxModel",
        tags: ["silhouetteMaker"],
        path: fighterBodyFile,
      }
    ]

    this.animations = {}
    this.mixer = undefined
    this.resources = new Resources("silhouetteMaker", additionalSources)
    this.resources.on("ready", () => {
      const fighterScale = 0.017
      const fbx = this.resources.items[modelName]
      fbx.scale.setScalar(fighterScale)
      fbx.position.set(0, -0.6, 0)
      // fbx.rotation.y = Math.PI / 2
      // fbx.getObjectByName("Armature").rotation.y = Math.PI / 2
      fbx.name = "fighter"

      for (var m = 0; m < fbx.children.length; m++) {
        if (fbx.children[m].type.includes("Mesh")) {
          fbx.children[m].material = new THREE.MeshBasicMaterial({ color: 0x00ffff })
        }
      }

      this.scene.add(fbx)

      const helper = new THREE.SkeletonHelper(fbx)

      this.resources.loaders.fbxLoader.manager.onLoad = () => {
        this.manager = new THREE.LoadingManager()
        this.manager.onLoad = () => {
          setAnimationsReady(true)
          setAnimationData({
            mixer: this.mixer,
            animations: this.animations,
            fighter: fbx,
            armature: fbx.children.filter(
              (child) => child.name === "Armature"
            )[0],
            boneNames: [
              ...new Set(
                helper.bones.map((bone) => {
                  return bone.name
                })
              ),
            ],
          })
        }

        this.mixer = new THREE.AnimationMixer(fbx)

        const onLoad = (animNamesMapping, anim, singleBool=false) => {       
          Object.keys(animNamesMapping).forEach((animKey) => {
            const animName = animNamesMapping[animKey]
            const clip = (
              singleBool ? 
              anim.animations[0] : 
              anim.animations.filter((x) => { return x.name === animKey })[0]
            )
            if (!singleBool) {
              this.startAnimationAtFrameZero(clip)
            }
            if (animName === "double-punch") {
              console.log(clip)
            }
            for (let track in clip.tracks) {
              clip.tracks[track].setInterpolation(THREE.InterpolateDiscrete);
            }
            const action = this.mixer.clipAction(clip)
            this.animations[animName] = {
              clip: clip,
              action: action,
              frameCount: Math.round(clip.duration * 60) + 1,
              // frameCount: Math.ceil(clip.duration * TICK_RATE),
            }
          })   
        }

        const loader = new GLTFLoader(this.manager)
        Object.keys(allAnimations).forEach((animationKey) => {
          const animationData = allAnimations[animationKey]
          loader.load(animationData.path, (a) => {
            onLoad(animationData.names, a, animationData.single)
          })
        })
      }
    })

    this.previousRAF = null
    this.render()
  }

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }
      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        this.previousRAF = t - (elapsed % this.fpsInterval)
        this.threejs.instance.render(this.scene, this.camera.instance)
      }
      this.render()
    })
  }
}

import composableScreenMetadata from '../../asset-management/fighter-accessories/metadata/composable'
import ToonShader from '../../../shaders/toon/toon-shader'
import robotModifier from './robot-modifier'
import * as THREE from 'three'

// import pfpFile from '../../../local-assets/misc/Brandon-Half-Robot.png'
// import pfpFile from '../../../local-assets/textures/composable-tests/azuki-test-image.png'
// import pfpFile from '../../../local-assets/textures/composable-tests/bored-ape-test-image.png'
import pfpFile from '../../../local-assets/textures/composable-tests/illogics-test-image.png'
// import partnerLogoFile from '../../../local-assets/textures/partner-logos/paradigm-logo.png'

const composableModifier = (fbx, resources, accessoryData, allMeshes) => {
  if (resources["toonMaterials"] === undefined) {
    resources["toonMaterials"] = []
  }
  robotModifier(fbx, resources, accessoryData)

  const gradientMap = resources.items["gradientMap"]
  gradientMap.minFilter = THREE.NearestFilter
  gradientMap.magFilter = THREE.NearestFilter

  const metadataKey = `composable-screen-${accessoryData.composableId}`
  const metadata = composableScreenMetadata[metadataKey]

  const params = { scale: metadata.scaleMultiple }

  const composableScreen = resources.items["composableScreen"]
  composableScreen.position.set(
    metadata.position.x,
    metadata.position.y,
    metadata.position.z
  )
  composableScreen.rotation.set(
    metadata.rotation.x,
    metadata.rotation.y,
    metadata.rotation.z
  )
  composableScreen.scale.setScalar(params.scale)

  const headBone = fbx.getObjectByName('Head_M')
  headBone.add(composableScreen)
  resources.items["composableScreenTexture"].encoding = THREE.sRGBEncoding
  
  const whiteColor = new THREE.Vector4(1, 1, 1, 1)
  const toonShader = new ToonShader({
    stepTexture: resources.items["gradientMap"],
    texture: resources.items["composableScreenTexture"],
    useTexture: true,
    modelScale: composableScreen.scale.x,
    color: new THREE.Vector4(1.0, 0.6, 0.4, 1.0),
    shadeColor: null,
    edgeWidthRatio: accessoryData.edge !== undefined ? accessoryData.edge.width : 0.4,
    edgeColor: accessoryData.edge !== undefined ? accessoryData.edge.color : whiteColor
  })
  const screenMaterial = new THREE.ShaderMaterial(toonShader)
  resources["toonMaterials"].push(screenMaterial)  

  composableScreen.traverse((obj) => {
    if (obj.type.includes("Mesh")) {
      allMeshes.push(obj)
      obj.material = screenMaterial      
    }
  })  

  resources.loaders.textureLoader.load(pfpFile, (pfp) => {
    const screen = composableScreen.getObjectByName("mesh_screen")
    pfp.encoding = THREE.sRGBEncoding
    // screen.material = new THREE.MeshBasicMaterial({ map: pfp })
    screen.material = new THREE.MeshBasicMaterial({ color: 0x000000 })
  })
}

export default composableModifier

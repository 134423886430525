import { allReverseHitboxMappings } from "../../game-mappings/hitbox-mappings"
import { allReverseHurtboxMappings } from "../../game-mappings/hurtbox-mappings"
import { backend } from "../../../env-variables"
import axios from "axios"

async function getFrameData(character = "fighter") {
  //Get frame data from api server 
  const { data } = await axios.get(`${backend}frame-data/load?character=${character}`)
  if (data.hasOwnProperty("msg")) return data

  //Add hitbox and hurtbox mappings to frame data
  const frameData = { hitboxes: {}, hurtboxes: {} }
  for (const hitbox of data.hitboxes) {
    frameData.hitboxes[allReverseHitboxMappings[character][hitbox.name]] = hitbox
  }

  for (const hurtbox of data.hurtboxes) {
    frameData.hurtboxes[allReverseHurtboxMappings[character][hurtbox.name]] = hurtbox
  }

  return frameData
}

export {  
  getFrameData 
}
import robotModifier from './robot-modifier'
import composableModifier from './composable-modifier'
import fighterModifier from './fighter-modifier'

const characterModifierMapping = {
  "robot": robotModifier,
  "composable": composableModifier,
  "fighter": fighterModifier
}

export default characterModifierMapping
import * as THREE from 'three'

const movementMax = 0.01
const movementMultiple = 0.1
const rotationScale = 0.005

const resizeCollider = (e, colliderSelected) => {
  const mouseRelativeY = e.data.global.y - colliderSelected.position.y
  const mouseRelativeX = e.data.global.x - colliderSelected.position.x

  const scaleX = Math.sign(e.data.originalEvent.movementX) * mouseRelativeX
  const scaleY = -Math.sign(e.data.originalEvent.movementY) * mouseRelativeY

  const dominantScale = Math.abs(scaleX) > Math.abs(scaleY) ? scaleX : scaleY
  colliderSelected.scale.setScalar(
    colliderSelected.scale.x * (1 + dominantScale * 0.1)
  )
}

const moveCollider = (e, colliderSelected, camera, mouseTracker, setMouseTracker) => {  
  if (
    camera !== undefined && 
    mouseTracker.raycaster !== undefined &&
    mouseTracker.pointer !== undefined &&
    mouseTracker.background !== undefined
  ) {
    mouseTracker.pointer.set(
      ( e.data.originalEvent.clientX / window.innerWidth ) * 2 - 1,
      - ( e.data.originalEvent.clientY / window.innerHeight ) * 2 + 1,
    )
    mouseTracker.raycaster.setFromCamera( mouseTracker.pointer, camera )
    const intersects = mouseTracker.raycaster.intersectObjects([mouseTracker.background])
    
    if (intersects[0] !== undefined) {
      const differentCollider = colliderSelected.uuid !== mouseTracker.colliderUUID
      const cameraRotationChanged = (
        camera.rotation.x !== mouseTracker.cameraRotation.x ||
        camera.rotation.y !== mouseTracker.cameraRotation.y ||
        camera.rotation.z !== mouseTracker.cameraRotation.z
      )

      if (!cameraRotationChanged && !differentCollider) {
        colliderSelected.position.x += (intersects[0].point.x - mouseTracker.prevPoint.x)
        colliderSelected.position.y += (intersects[0].point.y - mouseTracker.prevPoint.y)
        colliderSelected.position.z += (intersects[0].point.z - mouseTracker.prevPoint.z)
      }
      const cameraRotation = new THREE.Vector3
      setMouseTracker((prevState) => {
        return {
          ...prevState,
          cameraRotation: cameraRotation.copy(camera.rotation),
          colliderUUID: colliderSelected.uuid,
          prevPoint: intersects[0].point
        }
      })
    }
  }
}

const rotateCollider = (e, colliderSelected) => {
  const moveX = e.data.originalEvent.movementX
  const moveY = e.data.originalEvent.movementY
  var dominantRotation    
  if (Math.abs(moveX) > Math.abs(moveY)) {
    dominantRotation = moveX * Math.sign(e.data.global.y - colliderSelected.position.y)
  }
  else {
    dominantRotation = moveY * Math.sign(e.data.global.x - colliderSelected.position.x)
  }
  colliderSelected.rotation.z -= dominantRotation * rotationScale
}

export {
  resizeCollider,
  moveCollider,
  rotateCollider
}
import ToonShader from '../../../shaders/toon/toon-shader'
import * as THREE from 'three'

const robotModifier = (fbx, resources, accessoryData={}) => {
  resources.items["robotTexture"].encoding = THREE.sRGBEncoding
  const whiteColor = new THREE.Vector4(1, 1, 1, 1)
  const toonShader = new ToonShader({
    stepTexture: resources.items["gradientMap"],
    texture: resources.items["robotTexture"],
    useTexture: true,
    modelScale: fbx.scale.x,
    color: new THREE.Vector4(1.0, 0.6, 0.4, 1.0),
    shadeColor: null,
    edgeWidthRatio: accessoryData.edge !== undefined ? accessoryData.edge.width : 0.4,
    edgeColor: accessoryData.edge !== undefined ? accessoryData.edge.color : whiteColor
  })
  const robotMaterial = new THREE.ShaderMaterial(toonShader)
  resources["toonMaterials"].push(robotMaterial)

  const glowMaterial = new THREE.MeshBasicMaterial({ color: 0xe33737 })
  fbx.children.forEach((child) => {
    if (child.type === "SkinnedMesh") {
      if (child.name === "Glow") {
        child.material = glowMaterial
      }
      else {
        child.material = robotMaterial
      }
    }
  })
}

export default robotModifier

import React from 'react'
import { colliderLinkingAtom } from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './live-colliders-checkbox.css'

const LiveCollidersCheckbox = () => {
  const [colliderLinking, setColliderLinking] = useAtom(colliderLinkingAtom)

  return (
    <label 
      className="live-colliders-checkbox__container"
      onClick={() => setColliderLinking((prevState) => { 
        return { bones: false, ml: false, live: !prevState.live } 
      })}>
      Live Colliders
      <span 
        className={`live-colliders-checkbox__checkmark${colliderLinking.live ? " checked" : ""}`}>
      </span>
    </label>
  )
}

export default LiveCollidersCheckbox
const fillFragmentPrepend = `
  uniform bool fillBool;
  uniform bool fillUp;
  uniform bool fillDown;
  uniform bool emptyDown;
  uniform float uPercent;
  uniform float uMinY;
  uniform float uMaxY;

  varying vec3 vPosition;
`

const fillFragmentContent = `
  float strength;
  if (fillUp) {
    strength = uMaxY - vPosition.y;
    strength = clamp(step((1.0 - uPercent) * uMaxY, strength), uMinY, uMaxY);
  }
  else if (fillDown) {
    strength = vPosition.y;
    strength = clamp(step((1.0 - uPercent) * uMaxY, strength), uMinY, uMaxY);
  }
  else if (emptyDown) {
    strength = uMaxY - vPosition.y;
    strength = clamp(step(uPercent * uMaxY, strength), uMinY, uMaxY);
  }

  if (strength < 0.5) discard;
`

export {
  fillFragmentPrepend,
  fillFragmentContent
}
import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import Accessory from '../../../helpers/asset-management/fighter-accessories/accessory'
import { 
  getAccessoryParams,
  removeBodyMesh,
  meshesToRemove
} from '../../../helpers/asset-management/fighter-accessories/dress-fighter'
import { convertRGB } from '../../../helpers/asset-management/fighter-accessories/metadata/default-metadata'
import headAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/head'
import feetAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/feet'
import handsAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/hands'
import bodyAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/body'
import armsAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/arms'
import legsAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/legs'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import * as THREE from 'three'
import './attribute-picker.css'

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const keepBodyParts = ["head", "mouth", "eyes"]

const presetMappings = {
  marshall : {
    head: 5,
    hands: 1,
    feet: 7,
    body: 1
  },
  bo : {
    head: 10,
    hands: 2,
    feet: 1,
    body: "boxing"
  }
}

const customTextures = {
  marshall: ["head", "body"],
  bo: ["hands"]
}

const customMetadata = {
  bo: {
    head: {
      useTexture: false,
      color: new THREE.Vector4(...convertRGB([11, 10, 34]), 1),
      shadeColor: new THREE.Vector4(0, 0, 0, 1),
      rimColor: new THREE.Vector4(...convertRGB([27, 26, 46]), 1)
    }
  }
}

const AttributePicker = ({ attributeType, preset, environment, attributeMapping }) => {
  const [currentAttribute, setCurrentAttribute] = useState(undefined)
  const [attributeMetadata, setAttributeMetadata] = useState(undefined)
  const [loaders, setLoaders] = useState(undefined)

  const series = 1

  const currentMapping = attributeMapping[capitalize(attributeType)]
  var currentMetadata
  if (attributeType === "head") {
    currentMetadata = headAccessoryMetadata
  }
  else if (attributeType === "feet") {
    currentMetadata = feetAccessoryMetadata
  }
  else if (attributeType === "hands") {
    currentMetadata = handsAccessoryMetadata
  }
  else if (attributeType === "body") {
    currentMetadata = bodyAccessoryMetadata
  }

  const options = [
    ...Object.keys(currentMapping).map((id) => {
      return { 
        name: currentMapping[id].name.split(" ").join("-"), 
        label: currentMapping[id].name, 
        id: id 
      }
    })
  ]

  const attachAttribute = (attributeModel, key, type) => {
    const skinMaterial = environment.character.getObjectByName("mesh_head").material
    const limbMaterial = environment.character.getObjectByName("mesh_arms").material
    const headMesh = environment.character.children.filter((child) => child.name === "mesh_head")[0]  
    const params = getAccessoryParams(
      environment.resources.items["gradientMap"], 
      environment.resources.items["colorGamut"], 
      environment.character.scale.x, 
      environment.resources.loaders, 
      headMesh.skeleton.bones[0], 
      headMesh.skeleton,
      skinMaterial,
      limbMaterial,
      environment.resources["toonMaterials"],
      [],
      key, 
      type,
      {}       
    )
    params.fbx = attributeModel
    params.metadata = attributeMetadata
    if (Object.keys(customTextures).includes(preset.name)) {
      if (customTextures[preset.name].includes(attributeType)) {
        if (presetMappings[preset.name][type] === +key.split("-")[1]) {
          const preloadedTexture = environment.resources.items[`${type}-${preset.name}--base-color`]
          if (preloadedTexture !== undefined) {
            params.baseColor = preloadedTexture
          }
          else {
            const textureFile = `accessories/${type}/${key}/${type}-${preset.name}--base-color.webp`
            params.baseColor = loaders.textureLoader.load(textureFile)
          }
        }
      }
      if (
        customMetadata[preset.name] !== undefined && 
        customMetadata[preset.name][type] !== undefined
      ) {
        params.metadata = { ...params.metadata, ...customMetadata[preset.name][type] }
      }
    }
    
    params.anchorPoint = environment.character
    params.bindBool = true
    meshesToRemove[type].forEach((meshName) => {
      removeBodyMesh(environment.character, meshName)
    })
    new Accessory(params)
    if (!keepBodyParts.includes(type)) {
      environment.character.getObjectByName(`mesh_${type}`).visible = false
    }
  }

  const changeAttribute = (newAttribute) => {
    setCurrentAttribute(newAttribute)
    const specialAttribute = isNaN(+newAttribute.id)
    const key = `${attributeType}${specialAttribute ? "" : "-" + series}-${newAttribute.id}`
    setAttributeMetadata(currentMetadata[key])
  }

  const attachBareBodyPart = (type) => {
    meshesToRemove[type].forEach((meshName) => {
      removeBodyMesh(environment.character, meshName)
    })
    environment.character.getObjectByName(`mesh_${type}`).visible = true
  }

  const addLimb = (type, key) => {
    const metadata = type === "arms" ? armsAccessoryMetadata : legsAccessoryMetadata
    if (metadata[`${type}-${key}`] !== undefined) {
      const preloadedModel = environment.resources.items[`${type}-${key}`]
      if (preloadedModel !== undefined) {
        attachAttribute(preloadedModel, key, type)
      }
      else {
        loaders.fbxLoader.load(`accessories/${type}/${key}/${type}-${key}.fbx`, (fbx) => {
          attachAttribute(fbx, key, type)
        })
      }
    }
    else {
      attachBareBodyPart(type)
    }
  }

  useEffect(() => {
    setCurrentAttribute(options[0])
    setLoaders({ textureLoader: new THREE.TextureLoader(), fbxLoader: new FBXLoader() })
  }, [])

  useEffect(() => {
    if (preset.name !== "none") {
      var presetAttribute = options.filter((option) => option.id === preset.name)[0]
      if (presetAttribute !== undefined) {
        setCurrentAttribute(presetAttribute)
        const key = `${attributeType}-${preset.name}`
        setAttributeMetadata({...currentMetadata[key]})
      }
      else {
        if (presetMappings[preset.name] !== undefined) {
          presetAttribute = options.filter((option) => {
            return option.id === presetMappings[preset.name][attributeType].toString()
          })[0]
          setCurrentAttribute(presetAttribute)
          const specialAttribute = isNaN(+presetAttribute.id)
          const key = `${attributeType}${specialAttribute ? "" : "-" + series}-${presetAttribute.id}`
          setAttributeMetadata(currentMetadata[key])
        }
      }
    }
  }, [preset])

  useEffect(() => {
    if (attributeMetadata !== undefined && loaders !== undefined) {
      const specialAttribute = isNaN(+currentAttribute.id)
      const key = specialAttribute ? currentAttribute.id : `${series}-${currentAttribute.id}`
      if (currentAttribute.label.includes("Bare ")) {
        attachBareBodyPart(attributeType)
      }
      else {
        const preloadedModel = environment.resources.items[`${attributeType}-${key}`]
        if (preloadedModel !== undefined) {
          attachAttribute(preloadedModel, key, attributeType)
          if (attributeType === "body") {
            addLimb("arms", key)
            addLimb("legs", key)
          }
        }
        else {
          const modelFile = `accessories/${attributeType}/${key}/${attributeType}-${key}.fbx`
          loaders.fbxLoader.load(modelFile, (fbx) => {
            attachAttribute(fbx, key, attributeType)
            if (attributeType === "body") {
              addLimb("arms", key)
              addLimb("legs", key)
            }
          })
        }
      }
    }
  }, [attributeMetadata, loaders])

  return (
    <>
      {
        currentAttribute !== undefined &&
        <Dropdown
          containerId={`attribute-picker__container--${attributeType}`}
          dropdownId={`attribute-picker-dropdown--${attributeType}`}
          selection={currentAttribute}
          clickFunction={changeAttribute}
          options={options}
          positionStyling={{}}>
        </Dropdown>
      }
    </>
  )
}

export default AttributePicker
const storageUrl = "https://ai-arena.b-cdn.net/"

const fighterAnimationsDir = storageUrl + "fighter-animations/"
const animationExtension = ".glb"

const auAnimationsDir = storageUrl + "npc-animations/agnes-ultimate/"
const auAnimationExtension = ".glb"

const droneAnimationsDir = storageUrl + "npc-animations/drones/"
const attackDroneAnimationsDir = droneAnimationsDir + "attack/"
const defenseDroneAnimationsDir = droneAnimationsDir + "defense/"
const agilityDroneAnimationsDir = droneAnimationsDir + "agility/"
const droneAnimationExtension = ".glb"

const mappingsWrapper = (mapping) => {
  const newMappings = {}
  Object.keys(mapping).forEach((key) => {
    if (typeof mapping[key] === "string") {
      newMappings[key] = { names: {[key]: key}, path: mapping[key], single: true }
    }
    else {
      if (Array.isArray(mapping[key].names)) {
        newMappings[key] = { names: {}, path: mapping[key].path }
        mapping[key].names.forEach((name) => {
          newMappings[key].names[name] = name
        })
      }
      else {
        newMappings[key] = mapping[key]
      }
    }
  })
  return newMappings
}

const sharedFighterMoveset = [
  // "back-aerial-kick",
  "back-aerial",
  "captured-start",
  "captured",
  "crouch-start",
  "crouch-hold",
  "crouch-end",
  "double-punch",
  "fall-on-back",
  "fall-on-face",
  "fall",
  "flip-kick",
  "flying-kick",
  "front-kick",
  "get-up-back",
  "get-up-front",
  "grab-attack",
  "grab-end",
  "grab-hold",
  "grab-start",
  "headbutt-charge",
  "headbutt-end",
  "headbutt-start",
  "hit-light",
  "hit-light-2",
  "hit-light-3",
  "hit-light-behind",
  "hit-light-behind-2",
  "idle",
  "jump-aerial",
  "jump-back-aerial",
  "jump-fall",
  "jump-land",
  "jump-start",
  "jump-windup",
  "kick-aerial",
  "knockback-heavy",
  "knockback-light",
  "knockback-spin",
  "ledge-catch",
  "ledge-climb",
  "ledge-hang",
  "low-sweep",
  "low-sweep-from-crouch",
  "parry-stun",
  "punch-aerial",
  "punch-down-aerial-start",
  "punch-down-aerial-hold",
  "punch-down-aerial-end",
  "punch",
  "ragdoll",
  "ragdoll-land",
  "roll",
  "run",
  "shield-break",
  "shield-hold",
  "shield-start",
  "throw-back",
  "throw-down",
  "throw-forward",
  "throw-up",
  "thrown-back",
  "thrown-down-start",
  "thrown-down-ground",
  "thrown-forward",
  "uppercut-aerial",
  "uppercut-ground",
  "wall-hang",
  "wall-jump",
]

const waterFighterMoveset = {
  "regular_ground": "water-special",
  "regular_aerial": "water-special-aerial",
  "strong": "water-special-strong",
  "throw": "throw-water",
  "thrown-water-start": "thrown-water-start",
  "thrown-water-loop": "thrown-water-loop",
  "thrown-water-end": "thrown-water-end",
}

const electricFighterMoveset = {
  "regular_ground": "electric-special",
  "regular_aerial": "electric-special-aerial",
  "strong": "electric-special-strong",
  "throw": "throw-electric",
}

const fireFighterMoveset = {
  "regular_ground": "fire-special",
  "regular_aerial": "fire-special-aerial",
  "strong": "fire-special-strong",
  "throw": "throw-fire",
}

const airThrowMoveset = {
  "behind-above-pull": "air-grab-pull-back-above",
  "behind-below-pull": "air-grab-pull-back-below",
  "behind-front-pull": "air-grab-pull-back-front",
  "end-above": "air-grab-end-above",
  "end-behind": "air-throw-behind",
  "end-below": "air-grab-end-below",
  "end-forward": "air-throw-forward",
  "end-front": "air-grab-end-front",
  "forward-above-pull": "air-grab-pull-forward-above",
  "forward-below-pull": "air-grab-pull-forward-below",
  "forward-front-pull": "air-grab-pull-forward-front",
  "hold-above": "air-grab-hold-above",
  "hold-below": "air-grab-hold-below",
  "hold-front": "air-grab-hold-front",
  "start-above": "air-grab-start-above",
  "start-below": "air-grab-start-below",
  "start-front": "air-grab-start-front",

  // New
  "end-up": "air-throw-up",
  "end-down": "air-throw-down",
  "up-above-pull": "air-grab-pull-up-above",
  "up-below-pull": "air-grab-pull-up-below",
  "up-front-pull": "air-grab-pull-up-front",  
}

const getEmoteAnimation = (type, idx) => {
  return {
    names: { "loop": `${type}-${idx}` },
    path: `${fighterAnimationsDir}${type}/${type}--${idx}.glb`
  }
}

const getAllEmoteAnimations = (type, range) => {
  const emoteAnimations = {}
  for (let i = range[0]; i < range[1] + 1; i++) {
    emoteAnimations[`${type}-${i}`] = getEmoteAnimation(type, i)
  }
  return emoteAnimations
}

const emoteAnimations = {
  ...getAllEmoteAnimations("victory", [1, 11]),
  ...getAllEmoteAnimations("defeat", [1, 11])
}

const animationsMapping = mappingsWrapper({
  "all-animations": {
    names: sharedFighterMoveset,
    path: `${fighterAnimationsDir}shared-moveset--test3.glb`,
  },
  "fire-special-animations": {
    names: fireFighterMoveset,
    path: `${fighterAnimationsDir}fire-special-animations--v3.glb`,
  },
  "electric-special-animations": {
    names: electricFighterMoveset,
    path: `${fighterAnimationsDir}electric-special-animations--v2.glb`,
  },
  "water-special-animations": {
    names: waterFighterMoveset,
    path: `${fighterAnimationsDir}water-special-animations--v3.glb`,
  },

  // Taunts
  "taunt1": fighterAnimationsDir + "taunt/taunt--1" + animationExtension,
  "taunt2": fighterAnimationsDir + "taunt/taunt--2" + animationExtension,
  "taunt3": fighterAnimationsDir + "taunt/taunt--3" + animationExtension,
  "taunt4": fighterAnimationsDir + "taunt/taunt--4" + animationExtension,
  "taunt5": fighterAnimationsDir + "taunt/taunt--5" + animationExtension,
  "taunt6": fighterAnimationsDir + "taunt/taunt--6" + animationExtension,
  "taunt7": fighterAnimationsDir + "taunt/taunt--7" + animationExtension,
  "taunt8": fighterAnimationsDir + "taunt/taunt--8" + animationExtension,
  "taunt9": fighterAnimationsDir + "taunt/taunt--9" + animationExtension,
  "taunt10": fighterAnimationsDir + "taunt/taunt--10" + animationExtension,
  "taunt11": fighterAnimationsDir + "taunt/taunt--11" + animationExtension,
  "taunt12": fighterAnimationsDir + "taunt/taunt--12" + animationExtension,
  "taunt13": fighterAnimationsDir + "taunt/taunt--13" + animationExtension,
  "taunt14": fighterAnimationsDir + "taunt/taunt--14" + animationExtension,
  "taunt15": fighterAnimationsDir + "taunt/taunt--15" + animationExtension,
  "taunt16": fighterAnimationsDir + "taunt/taunt--16" + animationExtension,
  "taunt17": fighterAnimationsDir + "taunt/taunt--17" + animationExtension,
  "taunt18": fighterAnimationsDir + "taunt/taunt--18" + animationExtension,
  "taunt19": fighterAnimationsDir + "taunt/taunt--19" + animationExtension,
  "taunt20": fighterAnimationsDir + "taunt/taunt--20" + animationExtension,  

  // Tester
  "air-throw-animations": {
    names: airThrowMoveset,
    path: fighterAnimationsDir + "air-grab--v3" + animationExtension
  },
  "air-dodge": {
    names: { 
      "start": "air-dodge-start",
      "hold": "air-dodge-hold",
      "end": "air-dodge-end"
    },
    path: fighterAnimationsDir + "air-dodge--v2" + animationExtension
  },
  "dash": fighterAnimationsDir + "dash--test2" + animationExtension,
  "stop": fighterAnimationsDir + "stop--test" + animationExtension,
})

const auAnimationsMapping = mappingsWrapper({
  "idle": auAnimationsDir + "au-idle" + auAnimationExtension,
  "block": auAnimationsDir + "au-block" + auAnimationExtension,
  "down-air-start": auAnimationsDir + "au-down-air-start" + auAnimationExtension,
  "down-air-hold": auAnimationsDir + "au-down-air-hold" + auAnimationExtension,
  "down-air-end": auAnimationsDir + "au-down-air-end" + auAnimationExtension,
  "grab-start": auAnimationsDir + "au-grab-start" + auAnimationExtension,
  "grab-hold": auAnimationsDir + "au-grab-hold" + auAnimationExtension,
  "grab-end": auAnimationsDir + "au-grab-end" + auAnimationExtension,
  "jump-start": auAnimationsDir + "au-jump-start" + auAnimationExtension,
  "jump-fall": auAnimationsDir + "au-jump-fall" + auAnimationExtension,
  "jump-land": auAnimationsDir + "au-jump-land" + auAnimationExtension,
  "left-punch": auAnimationsDir + "au-left-punch" + auAnimationExtension,
  "right-punch": auAnimationsDir + "au-right-punch" + auAnimationExtension,
  "run": auAnimationsDir + "au-run" + auAnimationExtension,
  "two-hands-slap": auAnimationsDir + "au-two-hands-slap" + auAnimationExtension,
  "electric-discharge": auAnimationsDir + "au-electric-discharge" + auAnimationExtension,
  "spin": auAnimationsDir + "au-spin" + auAnimationExtension,
  "stomp": auAnimationsDir + "au-stomp" + auAnimationExtension,
  "robots-attack": auAnimationsDir + "au-robots-attack" + auAnimationExtension,
  "water-start": auAnimationsDir + "au-water-start" + auAnimationExtension,
  "water-hold": auAnimationsDir + "au-water-hold" + auAnimationExtension,
  "water-end": auAnimationsDir + "au-water-end" + auAnimationExtension,
  "bomb": auAnimationsDir + "au-bomb" + auAnimationExtension,
  "fire-ball": auAnimationsDir + "au-fire-ball" + auAnimationExtension,

  // Getting hit
  "hit-1": auAnimationsDir + "au-hit-1" + auAnimationExtension,
  "hit-2": auAnimationsDir + "au-hit-2" + auAnimationExtension,
  "hit-behind-1": auAnimationsDir + "au-hit-behind-1" + auAnimationExtension,
  "hit-behind-2": auAnimationsDir + "au-hit-behind-2" + auAnimationExtension,
  "hit-air": auAnimationsDir + "au-hit-air" + auAnimationExtension,
  "hit-behind-air": auAnimationsDir + "au-hit-behind-air" + auAnimationExtension,
})

const attackDroneExtension = "--attack" + droneAnimationExtension
const attackDroneAnimationsMapping = mappingsWrapper({
  "combo-mania": attackDroneAnimationsDir + "combo-mania" + attackDroneExtension,
  "elemental-boost": attackDroneAnimationsDir + "elemental-boost" + attackDroneExtension,
  "floating-idle": attackDroneAnimationsDir + "floating-idle" + attackDroneExtension,
  "flying-idle": attackDroneAnimationsDir + "flying-idle" + attackDroneExtension,
  "laser": attackDroneAnimationsDir + "laser" + attackDroneExtension,
  "thousand-punches": attackDroneAnimationsDir + "thousand-punches" + attackDroneExtension,
  "tied-up": attackDroneAnimationsDir + "tied-up" + attackDroneExtension,
})

const defenseDroneExtension = "--defense" + droneAnimationExtension
const defenseDroneAnimationsMapping = mappingsWrapper({
  "backpack-deploy": defenseDroneAnimationsDir + "backpack-deploy" + defenseDroneExtension,
  "backpack-idle": defenseDroneAnimationsDir + "backpack-idle" + defenseDroneExtension,
  // "backpack-resolution": defenseDroneAnimationsDir + "backpack-resolution" + defenseDroneExtension,
  // "border-patrol": defenseDroneAnimationsDir + "border-patrol" + defenseDroneExtension,
  "backpack-resolution": defenseDroneAnimationsDir + "backpack-resolution--defense--test.glb",
  "border-patrol": defenseDroneAnimationsDir + "border-patrol--defense--test.glb",
  "defense-up": defenseDroneAnimationsDir + "defense-up" + defenseDroneExtension,
  "floating-idle": defenseDroneAnimationsDir + "floating-idle" + defenseDroneExtension,
  "flying-idle": defenseDroneAnimationsDir + "flying-idle" + defenseDroneExtension,
  "give-me-space": defenseDroneAnimationsDir + "give-me-space" + defenseDroneExtension,
  "shield-wall": defenseDroneAnimationsDir + "shield-wall" + defenseDroneExtension,
})

const agilityDroneExtension = "--agility" + droneAnimationExtension
const agilityDroneAnimationsMapping = mappingsWrapper({
  "floating-idle": agilityDroneAnimationsDir + "floating-idle" + agilityDroneExtension,
  "flying-idle": agilityDroneAnimationsDir + "flying-idle" + agilityDroneExtension,
  "retreat": agilityDroneAnimationsDir + "retreat" + agilityDroneExtension,
  "speed-boost": agilityDroneAnimationsDir + "speed-boost" + agilityDroneExtension,
  "jetpack": agilityDroneAnimationsDir + "jetpack" + agilityDroneExtension,
  "peek-a-boo": agilityDroneAnimationsDir + "peek-a-boo" + agilityDroneExtension,
  "air-slash": agilityDroneAnimationsDir + "air-slash" + agilityDroneExtension,
  "air-slash-flying": agilityDroneAnimationsDir + "air-slash-flying" + agilityDroneExtension,
})

export {
  animationsMapping,
  emoteAnimations,
  auAnimationsMapping,
  attackDroneAnimationsMapping,
  defenseDroneAnimationsMapping,
  agilityDroneAnimationsMapping,
  storageUrl
}
import React from "react"
import { HashRouter as Router } from "react-router-dom"
import createRoutes from "./access-chunks"
import {
  useCredentialsHook,
  getAccessKey,
} from "../helpers/generic/setup"
import { useSignedInSession } from "../helpers/hooks/signin/signin"

const ReactRouterSetup = () => {
  const { sessionExists, session } = useSignedInSession()
  const hasAccess = useCredentialsHook(session)
  const accessKey = getAccessKey(hasAccess)

  return (
    <Router>
      <div className="appContent">
        {createRoutes(accessKey, sessionExists)}
      </div>
    </Router>
  )
}

export default ReactRouterSetup

import * as THREE from 'three'

const createColliderMesh = (shape, data) => {
  var geometry
  var material
  const materialInputs = { transparent: true, opacity: 0.2 }
  if (shape === "Box") {
    material = new THREE.MeshBasicMaterial({ color: 0xff0000, ...materialInputs })
    geometry = new THREE.BoxGeometry(1, 1, 1)
  }
  else if (shape === "Cylinder") {
    material = new THREE.MeshBasicMaterial({ color: 0xff007f, ...materialInputs })
    geometry = new THREE.CylinderGeometry(0.6, 0.6, 1, 32)
  }
  else if (shape === "Sphere") {
    material = new THREE.MeshBasicMaterial({ color: 0x00ffff, ...materialInputs })
    geometry = new THREE.SphereGeometry(0.6, 64, 32)
  }
  const mesh = new THREE.Mesh(geometry, material)
  if (data !== undefined) {
    if (data.position !== undefined) {
      mesh.position.set(data.position.x, data.position.y, data.position.z)
    }
    if (data.rotation !== undefined) {
      mesh.rotation.set(data.rotation.x, data.rotation.y, data.rotation.z)
    }
    if (data.scale !== undefined) {
      mesh.scale.setScalar(data.scale.x)
    }
    if (data.name !== undefined) {
      mesh.name = data.name
    }
  }
  return mesh
}

export {
  createColliderMesh
}
import React, { useState, useEffect } from 'react'
import GIFEncoder from 'gif-encoder-2'
import "./download-button.css"

const DownloadButton = ({ canvasId, animationData }) => {
  const dimensions = [window.innerWidth, window.innerHeight]
  const [gif, setGif] = useState(new GIFEncoder(...dimensions))
  const [images, setImages] = useState([])

  const addImage = (canvas) => {
    if (images.length === 0) {
      gif.start()
      gif.setDelay(1000 / 60)
    }
    
    const canvas2 = document.createElement('canvas')
    canvas2.width = dimensions[0]
    canvas2.height = dimensions[1]
    const ctx = canvas2.getContext('2d')

    canvas.toBlob(function(blob) {
        let url = URL.createObjectURL(blob);
        const image = new Image()
        image.onload = () => {
            ctx.drawImage(image, 0, 0, ...dimensions)
            gif.addFrame(ctx)
        }
        image.src = url

      setImages((prevState) => {
        return [...prevState, blob]
      })
    });
  }


  const saveImage = (buffer) => {

    function toArrayBuffer() {
        const ab = new ArrayBuffer(buffer.length);
        const view = new Uint8Array(ab);
        for (let i = 0; i < buffer.length; ++i) {
            view[i] = buffer[i];
        }
        return ab;
    }

    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'test.gif');
    const newBuffer = toArrayBuffer();
    const blob = new Blob([newBuffer])
    let url = URL.createObjectURL(blob);
    downloadLink.setAttribute('href', url);
    downloadLink.click();
  }  


  useEffect(() => {
    if (images.length > 0) {
      animationData.mixer.update(1 / 60)
      if (images.length < animationData.animations["idle"].frameCount) {
        addImage(document.getElementById(canvasId))
      }
      else {
        gif.finish()
        const buffer = gif.out.getData()
        saveImage(buffer)
      }
    }
  }, [images])

  return (
    <>
      <button 
        id="photo-booth__button" 
        onClick={() => addImage(document.getElementById(canvasId))}>
        GENERATE PHOTO
      </button>
    </>
  )
}

export default DownloadButton
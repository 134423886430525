import {
  rimLightFragmentPrepend,
  rimLightFragmentContent
} from '../rim-light/rim-light-fragment-shader'
import { 
  specularLightFragmentPrepend,
  specularLightFragmentContent
} from '../specular-light/specular-light-fragment-shader'

const toonFragmentShader = `
  uniform vec3 lightDirection;
  uniform sampler2D stepTexture;
  uniform sampler2D uTexture;
  uniform bool USE_RIMLIGHT;
  uniform bool USE_SPECULAR;
  uniform bool USE_FLASHING;
  uniform bool edge;
  uniform bool useTexture;
  uniform vec4 edgeColor;  
  uniform vec4 color;
  uniform vec4 shadeColor;
  uniform float opacity;
  uniform float flashTimer;

  varying vec2 vUv;
  varying vec3 vLightDirection;
  ${rimLightFragmentPrepend}
  ${specularLightFragmentPrepend}

  void main(void){
    if (edge) {
      gl_FragColor = edgeColor;
    }
    else {
      vec3 rim;
      vec3 specular;
      vec3 specularColor;
      if (USE_RIMLIGHT) {
        ${rimLightFragmentContent}
        if (USE_SPECULAR) {
          ${specularLightFragmentContent}
        }
      }

      float step = dot(vec3(2.,2.,2.), vLightDirection) + 2.;
      step = floor(step * 2.0) / 2.0;
      step = clamp(step, 0.1, 0.9);
      if (useTexture) {
        vec4 textureColor = LinearTosRGB(texture2D(uTexture, vUv));
        gl_FragColor = textureColor;// * clamp(texture2D(stepTexture, vec2(step, 1.0)) + 0.3, 0.3, 1.0);
        if (step < 0.5) {
          gl_FragColor.rgb *= 0.7;
        }
        gl_FragColor.a = opacity;
        if (USE_RIMLIGHT) {
          gl_FragColor.rgb += vec3(0.3 * rim / rimColor) * step;
          if (USE_SPECULAR) {
            gl_FragColor.rgb += vec3(0.3 * specular / specularColor) * step;
          }
        }
      }
      else {
        if (step < 0.5) {
          gl_FragColor = vec4(shadeColor.rgb, opacity);
        }
        else {
          gl_FragColor = vec4(color.rgb, opacity);// * step;
          if (USE_RIMLIGHT) {
            gl_FragColor.rgb += rim * step;
            if (USE_SPECULAR) {
              gl_FragColor.rgb += specular * step;
            }
          }
        }
      }
      if (USE_FLASHING) {
        vec3 flashStrength = vec3(0.25);
        float flashMultiple = sin(flashTimer * 10.) + 1.;
        gl_FragColor.rgb += flashStrength * flashMultiple;
      }
    }
  }
`

export {
  toonFragmentShader
}
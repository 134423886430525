import React, { useState, useEffect } from 'react'
import MarshallBoFaceoffScene from '../../../helpers/marketing-staging/marshall-bo-faceoff-scene'
import './marketing-staging.css'

const MarketingStaging = () => {
  const [environment, setEnvironment] = useState(undefined)

  useEffect(() => {
    const env = new MarshallBoFaceoffScene()
    setEnvironment(env)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  const increasePixelRatio = () => {
    environment.threejs.instance.setPixelRatio(environment.sizes.pixelRatio * 2)
  }

  return (
    <>
      <div id="marketing-staging__container">
        <div id="marketing-staging"></div>
        {
          environment !== undefined &&
          <button onClick={() => increasePixelRatio()}>Increase Pixels</button>
        }        
      </div>
    </>
  )
}

export default MarketingStaging
import headIcon from '../../../local-assets/animation-player/head.png'
import bodyIcon from '../../../local-assets/animation-player/body.png'
import armsIcon from '../../../local-assets/animation-player/arms.png'
import legsIcon from '../../../local-assets/animation-player/legs.png'
import handsIcon from '../../../local-assets/animation-player/hands.png'
import feetIcon from '../../../local-assets/animation-player/feet.png'

const mouthIcon = undefined
const eyesIcon = undefined

const bodyPartAssets = {
  eyes: eyesIcon,
  mouth: mouthIcon,
  head: headIcon,
  body: bodyIcon,
  arms: armsIcon,
  legs: legsIcon,
  hands: handsIcon,
  feet: feetIcon,
}

export {
  bodyPartAssets
}

import { moveHurtboxWithBone } from './bone-operations'
import { 
  addMeshToScene,
  removeMeshFromScene 
} from './collider-operations'
import {
  getRigData
} from './bone-operations'
import {
  getInputs,
  getOutputs
} from './ml-inference'

const animateLinkedHurtboxes = (meshesInScene, setMeshesInScene, hurtboxLinks, scene) => {
  meshesInScene.forEach((child) => {
    const linkedBoneData = hurtboxLinks[child.name]
    if (linkedBoneData !== undefined) {
      moveHurtboxWithBone(linkedBoneData, child.name, scene)
    }
    else {
      removeMeshFromScene(scene.getObjectByName(child.name), setMeshesInScene, scene)
    }
  })
}

const animateHurtboxesWithML = (
  modelData, 
  hurtboxLinks, 
  meshesInScene, 
  setMeshesInScene,
  setColliderSelected, 
  scene
) => {
  const armature = scene.getObjectByName("Armature")
  const rigData = getRigData(armature)

  var currentName
  var currentLinkData
  var currentMesh
  var transformData

  const colliders = Object.keys(modelData)
  for (var i = 0; i < colliders.length; i++) {
    currentName = colliders[i]
    const inputs = getInputs(
      rigData, 
      modelData[currentName].featuresUsed, 
      modelData[currentName].useRotation
    )

    const outputs = getOutputs(inputs, modelData[currentName])

    currentLinkData = hurtboxLinks[currentName]
    transformData = {
      name: currentName,
      scale: currentLinkData.scale,
      rotation: { x: 0, y: 0, z: 0 },
      position: outputs[currentName]
    }
    currentMesh = meshesInScene.filter((child) => child.name === currentName)[0]
    if (currentMesh === undefined) {
      addMeshToScene(
        currentLinkData.shape, 
        transformData,
        setMeshesInScene, 
        setColliderSelected, 
        scene
      )
    }
    else {
      currentMesh.position.set(
        transformData.position.x,
        transformData.position.y,
        transformData.position.z
      )
    }
  }
}

const animateSavedHurtboxes = (
  animationName, 
  animationFrame, 
  savedFrameData, 
  meshesInScene, 
  setMeshesInScene, 
  setColliderSelected,
  colliderType, 
  hurtboxLinks,
  scene
) => {
  const currentAnimationData = savedFrameData[animationName]
  const currentColliderData = (
    colliderType.name === "hitbox" ?
    currentAnimationData.hitboxFrames : 
    currentAnimationData.hurtboxFrames
  )
  const curentFrame = animationFrame >= currentColliderData.length ? 0 : animationFrame
  const currentFrameData = currentColliderData[curentFrame]
  const frameColliderNames = currentFrameData.colliders.map((collider) => { return collider.name })

  // Selectively use links
  const linkNames = ["left foot", "right foot"]

  const meshesToRemove = []
  const meshesNotAdded = []
  meshesInScene.forEach((child) => {
    if (child.type === "Mesh" && child.name !== "wall") {
      if (frameColliderNames.includes(child.name)) {
        const colliderData = currentFrameData.colliders.filter(
          (currentFrameCollider) => currentFrameCollider.name === child.name
        )[0]
        child.position.set(
          colliderData.position.x, 
          colliderData.position.y, 
          colliderData.position.z
        )
        child.rotation.set(
          colliderData.rotation.x, 
          colliderData.rotation.y, 
          colliderData.rotation.z
        )
        child.scale.setScalar(colliderData.scale.x)
        meshesNotAdded.push(child.name)
      }
      // else if (linkNames.includes(child.name)) {
      //   const linkedBoneData = hurtboxLinks[child.name]
      //   if (linkedBoneData !== undefined) {
      //     moveHurtboxWithBone(linkedBoneData, child.name, scene)
      //   }
      // }
      else {
        removeMeshFromScene(child, setMeshesInScene, scene)
        meshesToRemove.push(child.name)
      }
    }
  })
  setMeshesInScene((prevState) => {
    return prevState.filter((childMesh) => !meshesToRemove.includes(childMesh.name))
  })
  currentFrameData.colliders.forEach((savedColliderData) => {
    if (!meshesNotAdded.includes(savedColliderData.name)) {
      addMeshToScene(
        savedColliderData.shape, 
        savedColliderData,
        setMeshesInScene, 
        setColliderSelected, 
        scene
      )
    }
  })
}

export {
  animateLinkedHurtboxes,
  animateHurtboxesWithML,
  animateSavedHurtboxes
}
import { convertHex } from './default-metadata'

const skinColorMetadata = {
  // Human
  "skin-1-1": {
    color: convertHex("#f7ddc4"),
    shadeColor: convertHex("#e1b395"),
    rimColor: convertHex("#ffecda")
  },
  "skin-1-2": {
    color: convertHex("#d09e7d"),
    shadeColor: convertHex("#b37a64"),
    rimColor: convertHex("#ffc6a1")
  },
  "skin-1-3": {
    color: convertHex("#b26949"),
    shadeColor: convertHex("#955438"),
    rimColor: convertHex("#d88868")
  },
  "skin-1-4": {
    color: convertHex("#80492a"),
    shadeColor: convertHex("#653929"),
    rimColor: convertHex("#9e6240")
  },
  "skin-1-5": {
    // color: convertHex("#44241e"),
    // shadeColor: convertHex("#2f1711"),
    color: convertHex("#5a2f24"),
    shadeColor: convertHex("#45251b"),
    rimColor: convertHex("#87533a")
  },

  // Alien
  "skin-1-6": {
    color: convertHex("#ccbcff"),
    shadeColor: convertHex("#a090ff"),
    rimColor: convertHex("#e3d9ff")
  },
  "skin-1-7": {
    color: convertHex("#e4c013"),
    shadeColor: convertHex("#e49f13"),
    rimColor: convertHex("#ffe673")
  },
  "skin-1-8": {
    color: convertHex("#51819a"),
    shadeColor: convertHex("#3f6b80"),
    rimColor: convertHex("#69a5b7")
  },
  "skin-1-9": {
    color: convertHex("#59d796"),
    shadeColor: convertHex("#4fb483"),
    rimColor: convertHex("#92ffc7")
  },
  "skin-1-10": {
    // color: convertHex("#78f6f6"),
    // shadeColor: convertHex("#66e6e6"),
    color: convertHex("#7adbf9"),
    shadeColor: convertHex("#65c3e0"),
    rimColor: convertHex("#b4edff")
  },

  // New Human Colors
  "skin-1-11": {
    color: convertHex("#e9cea9"),
    shadeColor: convertHex("#c1a186"),
    rimColor: convertHex("#f6e2c9")
  },
  "skin-1-12": {
    color: convertHex("#c7ada2"),
    shadeColor: convertHex("#9c8687"),
    rimColor: convertHex("#dfcdc4")
  },
  "skin-1-13": {
    color: convertHex("#c48850"),
    shadeColor: convertHex("#a26b40"),
    rimColor: convertHex("#dca164")
  },
  "skin-1-14": {
    color: convertHex("#b27549"),
    shadeColor: convertHex("#925a3c"),
    rimColor: convertHex("#d28f5d")
  },
  "skin-1-15": {
    color: convertHex("#493729"),
    shadeColor: convertHex("#392a27"),
    rimColor: convertHex("#705339")
  },

  // Presets:
  "skin-marshall": {
    color: convertHex("#d99c87"),
    shadeColor: convertHex("#b57865"),
    rimColor: convertHex("#ffffff")
  },
  "skin-bo": {
    color: convertHex("#602f1e"),
    shadeColor: convertHex("#402013"),
    rimColor: convertHex("#602f1e")
  }
}

export default skinColorMetadata
import { useEffect } from "react"
import { 
  resizeCollider,
  moveCollider,
  rotateCollider
} from "../../collision-maker/collider-manipulation"
import { 
  unselectAllMeshes,
  removeMeshFromScene
} from "../../collision-maker/collider-operations"
import { 
  animationAtom,
  cameraAtom, 
  colliderNameAtom, 
  colliderSelectedAtom, 
  colliderTypeAtom, 
  fighterTypeAtom, 
  hurtboxLinksAtom, 
  manipulationAtom, 
  meshesInSceneAtom, 
  mouseTrackerAtom, 
  sceneAtom 
} from "../../collision-maker/atoms"
import { useAtom } from "jotai"
import * as THREE from 'three'
import * as d3 from 'd3'

const useMeshManipulation = () => {
  const scene = useAtom(sceneAtom)[0]
  const camera = useAtom(cameraAtom)[0]
  const animation = useAtom(animationAtom)[0]
  const colliderType = useAtom(colliderTypeAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const setColliderName = useAtom(colliderNameAtom)[1]
  const setMeshesInScene = useAtom(meshesInSceneAtom)[1]
  const setHurtboxLinks = useAtom(hurtboxLinksAtom)[1]
  const [mouseTracker, setMouseTracker] = useAtom(mouseTrackerAtom)
  const [colliderSelected, setColliderSelected] = useAtom(colliderSelectedAtom)
  const [manipulationType, setManipulationType] = useAtom(manipulationAtom)

  const handleMeshSize = (e) => {
    resizeCollider(e, colliderSelected)
  }  
  const handleMeshRotate = (e) => {
    rotateCollider(e, colliderSelected)
  }
  const handleMeshGrab = (e) => {
    moveCollider(e, colliderSelected, camera, mouseTracker, setMouseTracker)
  }
  
  const handleMeshCopy = (e) => {
    const clickPosition = e.data.global
    const meshClone = colliderSelected.clone()    
    meshClone.position.x = clickPosition.x
    meshClone.position.y = clickPosition.y
    meshClone.material = new THREE.MeshBasicMaterial({ 
      color: colliderSelected.material.color,
      transparent: true,
      opacity: 0.2
    })
    scene.add(meshClone)
    meshClone.on("click", (e) => {
      unselectAllMeshes(scene)
      if (e.target.material.opacity === 0.4) {
        setColliderSelected(undefined)
      }
      else {
        e.target.material.opacity = 0.4
        setColliderSelected(e.target)
      }
    })
    setManipulationType(undefined)
  }

  useEffect(() => {
    d3.selectAll(".manipulation-menu__element").style("border-color", "transparent")
    if (manipulationType !== undefined && scene !== undefined) {
      if (manipulationType === "Delete" && colliderSelected !== undefined) {
        removeMeshFromScene(colliderSelected, setMeshesInScene, scene)
        setManipulationType(undefined)
        setColliderSelected(undefined)
        setColliderName(undefined)
        if (colliderType.name === "hurtbox" && animation.name === "a-pose") {
          setHurtboxLinks((prevState) => {
            delete prevState[fighterType][colliderSelected.name]
            return {...prevState}
          })
        }
      }
      else {
        d3.select(`#manipulation-menu--${manipulationType.toLowerCase()}`)
          .style("border-color", "#69e3f2")
      }
    }
  }, [manipulationType])
  
  useEffect(() => {
    if (colliderSelected === undefined) unselectAllMeshes(scene)
    else setColliderName(colliderSelected.name !== "" ? colliderSelected.name : undefined)

    if (colliderSelected !== undefined && manipulationType !== undefined) {
      var mousemoveFunction
      if (manipulationType === "Size") mousemoveFunction = handleMeshSize
      else if (manipulationType === "Rotate") mousemoveFunction = handleMeshRotate
      else if (manipulationType === "Grab") mousemoveFunction = handleMeshGrab

      if (manipulationType === "Copy") scene.on("click", handleMeshCopy)
      else scene.on("mousemove", mousemoveFunction)      
      
      return () => {
        if (manipulationType === "Copy") scene.off("click", handleMeshCopy)
        else scene.off("mousemove", mousemoveFunction)
      }
    }
  }, [colliderSelected, manipulationType, camera, mouseTracker])
}

export {
  useMeshManipulation
}
import { useEffect } from 'react'
import { 
  animateHurtboxesWithML,
  animateSavedHurtboxes,
  animateLinkedHurtboxes
} from '../../collision-maker/collider-animations'
import { 
  savedFrameAtom, 
  animationAtom, 
  animationFrameAtom,
  colliderSelectedAtom,
  colliderTypeAtom,
  meshesInSceneAtom,
  sceneAtom,
  hurtboxLinksAtom,
  fighterTypeAtom,
  colliderLinkingAtom,
  liveFrameAtom
} from '../../collision-maker/atoms'
import { useAtom } from 'jotai'

const useHurtboxAnimation = (modelData) => {
  const scene = useAtom(sceneAtom)[0]
  const animation = useAtom(animationAtom)[0]
  const savedFrameData = useAtom(savedFrameAtom)[0]
  const liveFrameData = useAtom(liveFrameAtom)[0]
  const animationFrame = useAtom(animationFrameAtom)[0]
  const colliderType = useAtom(colliderTypeAtom)[0]
  const colliderLinking = useAtom(colliderLinkingAtom)[0]
  const hurtboxLinks = useAtom(hurtboxLinksAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const setColliderSelected = useAtom(colliderSelectedAtom)[1]
  const [meshesInScene, setMeshesInScene] = useAtom(meshesInSceneAtom)

  useEffect(() => {
    if (      
      animationFrame !== undefined &&
      animation !== undefined &&
      (
        animation.name === "a-pose" ||
        (colliderType.name === "hurtbox" && colliderLinking.bones)||
        savedFrameData[fighterType]?.[animation.name] !== undefined
      ) &&
      colliderType !== undefined
    ) {
      if (
        animation.name === "a-pose" || 
        (colliderType.name === "hurtbox" && colliderLinking.bones)
      ) {
        if (hurtboxLinks[fighterType] !== undefined) {
          animateLinkedHurtboxes(meshesInScene, setMeshesInScene, hurtboxLinks[fighterType], scene)
        }
      }
      else if (colliderType.name === "hurtbox" && colliderLinking.ml) {
        animateHurtboxesWithML(
          modelData, 
          hurtboxLinks[fighterType], 
          meshesInScene, 
          setMeshesInScene,
          setColliderSelected, 
          scene
        )
      }
      else {
        animateSavedHurtboxes(
          animation.name, 
          animationFrame, 
          colliderLinking.live ? liveFrameData[fighterType] : savedFrameData[fighterType], 
          meshesInScene, 
          setMeshesInScene, 
          setColliderSelected,
          colliderType,
          hurtboxLinks[fighterType],
          scene
        )
      }
    }
  }, [savedFrameData[fighterType], animationFrame, animation, colliderType, colliderLinking])
}

export {
  useHurtboxAnimation
}
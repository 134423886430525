import React, { useState } from 'react';

const initialState = {
  userAddress: undefined,
  yourFighters: [],
  fighterModels: {},
  fighterTextures: {},
  gameModels: {},
  frameData: {}
}

export const Context = React.createContext();

const Store = ({ children }) => {
  const [websiteContext, setWebsiteContext] = useState(initialState)

  return (
    <Context.Provider value={[websiteContext, setWebsiteContext]}>
      {children}
    </Context.Provider>
  )
}

export default Store;

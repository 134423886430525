import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import createScene from "../../helpers/threejs/scene-setup"
import { setUpBloom, renderBloom } from "../../helpers/threejs/selective-bloom-rendering"
import { updateToonEdge, updateToonShaderLight } from '../../helpers/threejs/toon-rendering'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
import robotBodyFile from "../../local-assets/gameplay/characters/robot-body-new.fbx"
import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"
import characterModifierMapping from "../../helpers/model-modifiers/characters/character-modifier-mapping"
import Resources from "../../helpers/resource-management/resources"
import { animationsMapping } from "./animation-mapping"
import * as THREE from "three"

const baseUrl = "https://ai-arena.b-cdn.net/"

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

export default class AnimationPlayerScene {
  constructor(setAnimationsReady, setAnimationData) {
    this.initialize(setAnimationsReady, setAnimationData)
  }

  initialize(setAnimationsReady, setAnimationData) {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "animation-player",
      camera: { fov: 25, near: 0.1, far: 1000 },
    })

    this.targetFPS = 30
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 7)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0, 1, 1.66)
    // this.directionalLight.castShadow = true
    // this.directionalLight.position.set(-4, 1, 1.66)
    this.scene.add(this.directionalLight)

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
    this.scene.add(this.ambientLight)

    var robotBool = false
    const composableBool = false
    if (composableBool) {
      robotBool = true
    }
    var modelName
    if (composableBool) {
      modelName = "composable"
    } else if (robotBool) {
      modelName = "robot"
    } else {
      modelName = "fighter"
    }

    const additionalSources = [
      {
        name: modelName,
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: robotBool ? robotBodyFile : fighterBodyFile,
      },
      {
        name: "gradientMap",
        type: "texture",
        tags: ["fighterCollisionMaker"],
        path: gradientMapFile,
      },
      {
        name: "facePlate",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: 'accessories/face/face.fbx',
      },
      {
        name: "colorGamut",
        type: "texture",
        tags: ["dressingRoom"],
        path: baseUrl + "shared/color-gamut-1.webp"
      }      
    ]

    if (composableBool) {
      const folderName = "accessories/composable-screen/2/"
      additionalSources.push(
        ...[
          {
            name: "composableScreen",
            type: "fbxModel",
            tags: ["fighterCollisionMaker"],
            path: folderName + "composable-screen-2.fbx",
          },
          {
            name: "composableScreenTexture",
            type: "texture",
            tags: ["fighterCollisionMaker"],
            path: folderName + "composable-screen-2--base-color.webp",
          },
        ]
      )
    }

    this.allMeshes = []
    this.materials = {}
    this.darkMaterial = new THREE.MeshBasicMaterial({ color: "black" })
    if (robotBool) {
      const [bloomComposer, finalComposer] = setUpBloom(
        this.threejs.instance,
        this.scene,
        this.camera.instance
      )
      this.bloomComposer = bloomComposer
      this.finalComposer = finalComposer
    }

    this.animations = {}
    this.mixer = undefined
    this.resources = new Resources("fighterCollisionMaker", additionalSources)
    this.resources.on("ready", () => {
      const fighterScale = 0.017
      const fbx = this.resources.items[modelName]
      fbx.scale.setScalar(fighterScale)
      fbx.position.set(0, -0.6, 0)
      fbx.name = "fighter"

      for (var m = 0; m < fbx.children.length; m++) {
        if (fbx.children[m].type.includes("Mesh")) {
          fbx.children[m].castShadow = true
          this.allMeshes.push(fbx.children[m])          
        }
      }

      const accessoryData = {
        series: 1,
        // headId: 3,
        eyesId: "guppy",
        mouthId: "guppy",
        headId: "guppy",
        bodyId: "guppy",
        handsId: "guppy",
        feetId: 9,
        // bodyColor: {
        //   color: new THREE.Color(0x602f1e),
        //   shadeColor: new THREE.Color(0x401f13),
        //   rimColor: new THREE.Color(0x3d2821)
        // },
        // limbColor: {
        //   color: new THREE.Color("blue"),
        //   shadeColor: new THREE.Color(0x0e0e87),
        //   rimColor: new THREE.Color(0x5d5dfc)
        // },        
        bodyColor: {
          color: new THREE.Color(0xd99c86),
          shadeColor: new THREE.Color(0xb37964),
          rimColor: new THREE.Color(0xf5c4b3)
        },        
        limbColor: {
          color: new THREE.Color(0xd99c86),
          shadeColor: new THREE.Color(0xb37964),
          rimColor: new THREE.Color(0xf5c4b3)
          // color: new THREE.Color("purple"),
          // shadeColor: new THREE.Color(0x4f064f),
          // rimColor: new THREE.Color(0xf235f2)
        },
        additionalId: "artist",
        additionalAccessory: false,
        composableId: 2,
        nakedFighter: true,
      }

      characterModifierMapping[modelName](
        fbx,
        this.resources,
        accessoryData,
        this.allMeshes
      )
      this.scene.add(fbx)
      this.character = fbx

      // const planeGeo = new THREE.PlaneGeometry(10, 10)
      // const planeMat = new THREE.MeshLambertMaterial({ side: THREE.DoubleSide })
      // const plane = new THREE.Mesh(planeGeo, planeMat)
      // this.scene.add(plane)
      // plane.receiveShadow = true
      // plane.rotation.x = -Math.PI / 2
      // plane.position.y = (robotBool || composableBool) ? -0.6 : -0.64
      // this.allMeshes.push(plane)

      this.resources.loaders.fbxLoader.manager.onLoad = () => {
        this.manager = new THREE.LoadingManager()
        this.manager.onLoad = () => {
          setAnimationsReady(true)
          setAnimationData({
            mixer: this.mixer,
            animations: this.animations,
            fighter: fbx,
            armature: fbx.children.filter(
              (child) => child.name === "Armature"
            )[0],
          })
        }

        this.mixer = new THREE.AnimationMixer(fbx)

        const onLoad = (animName, anim) => {
          const clip = anim.animations[0]
          const action = this.mixer.clipAction(clip)
          this.animations[animName] = {
            clip: clip,
            action: action,
            frameCount: Math.round(clip.duration * 60) + 1,
          }
        }

        const loader = new FBXLoader(this.manager)
        Object.keys(animationsMapping).forEach((animationName) => {
          loader.load(animationsMapping[animationName], (a) => {
            onLoad(animationName, a)
          })
        })
      }
    })

    this.previousRAF = null
    this.render()
  }

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }

      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        this.previousRAF = t - (elapsed % this.fpsInterval)
        if (this.mixer !== undefined) {
          this.mixer.update(elapsed)
        }
        if (
          this.bloomComposer !== undefined &&
          this.finalComposer !== undefined
        ) {
          renderBloom(
            this.bloomComposer,
            this.finalComposer,
            this.allMeshes,
            this.materials,
            this.darkMaterial
          )
        } else {
          updateToonShaderLight(this.resources, this.directionalLight)
          // updateToonEdge(this.resources, true)
          // this.threejs.instance.render(this.scene, this.camera.instance)

          updateToonEdge(this.resources, false)
          this.threejs.instance.render(this.scene, this.camera.instance)
        }
      }
      this.render()
    })
  }
}

import React, { useState, useEffect, useContext } from 'react'
import { Context } from "../../../Store"
import { 
  getAnimationFrames,
  getLinkedHurtboxes,
  getColliderAccess,
  getLiveColliderFrames
} from '../../../helpers/backend/get-requests/animation-data'
import { 
  saveAnimationFrames,
  saveLinkedHurtboxes
} from '../../../helpers/backend/post-requests/animation-data'
import {
  downloadObjectAsJson
} from '../../../helpers/collision-maker/data-operations'
import { getRigData } from '../../../helpers/collision-maker/bone-operations'
import { getFrameData } from '../../../helpers/backend/get-requests/frame-data'
import { 
  savedFrameAtom,
  animationAtom,
  colliderTypeAtom,
  animationFrameAtom,
  animationDataAtom,
  totalFramesAtom,
  sceneAtom,
  fighterTypeAtom,
  hurtboxLinksAtom,
  liveFrameAtom
} from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './collision-data.css'

const CollisionData = () => {
  const websiteContext = useContext(Context)[0]
  const scene = useAtom(sceneAtom)[0]
  const animation = useAtom(animationAtom)[0]
  const colliderType = useAtom(colliderTypeAtom)[0]
  const animationData = useAtom(animationDataAtom)[0]
  const totalFrames = useAtom(totalFramesAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const setLiveFrameData = useAtom(liveFrameAtom)[1]
  const [hurtboxLinks, setHurtboxLinks] = useAtom(hurtboxLinksAtom)
  const [savedFrameData, setSavedFrameData] = useAtom(savedFrameAtom)
  const [animationFrame, setAnimationFrame] = useAtom(animationFrameAtom)
  const [access, setAccess] = useState({ hurtbox: false, hitbox: false })

  const createEmptyFramesArray = (frameCount) => {
    return [...Array(frameCount).keys()].map((key) => { 
        return { frame: key, colliders: [] } 
    })
  }

  const saveInputData = (armature, currentFrame, currentInputData) => {
    requestAnimationFrame(() => {
      if (currentFrame < totalFrames) {
        currentInputData.push({
          frame: currentFrame,
          data: getRigData(armature)
        })        
        setAnimationFrame(currentFrame)
        saveInputData(armature, currentFrame + 1, currentInputData)
      }
      else {
        downloadObjectAsJson(currentInputData, `${animation.name}-inputs`)
      }
    })     
  }

  async function downloadDataset() {
    const animationFrames = await getAnimationFrames(fighterType)
    const filteredFrames = animationFrames.filter((data) => {
      return (
        data.name === animation.name && 
        data.colliderType === colliderType.name
      )
    })[0]
    if (filteredFrames !== undefined) {
      const armature = scene.getObjectByName("Armature")
      // saveInputData(armature, 0, []) 
      downloadObjectAsJson(filteredFrames, `${animation.name}-${colliderType.name}-frames`)
    }
    else {
      alert("No Dataset Saved")
    }
  }

  async function loadLiveColliderFrames(loadedFrames) {
    const liveFrames = await getLiveColliderFrames(fighterType)
    const tempLiveFrameData = {}
    liveFrames.hitboxes.forEach((hitbox) => {
      tempLiveFrameData[hitbox.name] = {
        name: hitbox.name,
        hitboxFrames: hitbox.frames
      }
    })
    liveFrames.hurtboxes.forEach((hurtbox) => {
      if (tempLiveFrameData[hurtbox.name] !== undefined) {
        tempLiveFrameData[hurtbox.name].hurtboxFrames = hurtbox.frames
      }
      else {
        tempLiveFrameData[hurtbox.name] = {
          name: hurtbox.name,
          hurtboxFrames: hurtbox.frames
        }
      }
    })
    Object.keys(loadedFrames).forEach((name) => {
      const current = tempLiveFrameData[name]
      if (current !== undefined) {
        if (current?.hitboxFrames === undefined || current?.hurtboxFrames === undefined) {
          const emptyFrames = createEmptyFramesArray(loadedFrames[name].hitboxFrames.length)
          tempLiveFrameData[name] = {
            name,
            hitboxFrames: current?.hitboxFrames ?? emptyFrames,
            hurtboxFrames: current?.hurtboxFrames ?? emptyFrames
          }
        }
      }
    })
    setLiveFrameData((prevState) => { return { ...prevState, [fighterType]: tempLiveFrameData } })
  }

  async function loadAnimationFrames() {
    if (savedFrameData[fighterType] === undefined) {
      const animationFrames = await getAnimationFrames(fighterType)
      var emptyFrames
      var animData
      var currentFrames
      const tempSavedFrameData = {}
      for (var a = 0; a < animationFrames.length; a++) {
        animData = animationFrames[a]
        currentFrames = tempSavedFrameData[animData.name]
        if (currentFrames === undefined) {
          emptyFrames = createEmptyFramesArray(animData.frames.length)
          tempSavedFrameData[animData.name] = {
            name: animData.name,
            hitboxFrames: animData.colliderType === "hitbox" ? animData.frames : emptyFrames,
            hurtboxFrames: animData.colliderType === "hurtbox" ? animData.frames : emptyFrames
          }
        }
        else {
          tempSavedFrameData[animData.name] = {
            name: animData.name,
            hitboxFrames: animData.colliderType === "hitbox" ? animData.frames : currentFrames.hitboxFrames,
            hurtboxFrames: animData.colliderType === "hurtbox" ? animData.frames : currentFrames.hurtboxFrames
          }
        }
      }
      Object.keys(animationData.animations).forEach((animationName) => {
        if (tempSavedFrameData[animationName] === undefined) {
          const currentAnimation = animationData.animations[animationName]
          tempSavedFrameData[animationName] = {
            name: animationName,
            hitboxFrames: createEmptyFramesArray(currentAnimation.frameCount),
            hurtboxFrames: createEmptyFramesArray(currentAnimation.frameCount)
          }
        }
      })
      loadLiveColliderFrames(tempSavedFrameData)
      setSavedFrameData((prevState) => { return { ...prevState, [fighterType]: tempSavedFrameData } })
    }
  }

  async function loadLinkedHurtboxes() {
    if (hurtboxLinks[fighterType] === undefined) {
      const poseHurtboxData = await getLinkedHurtboxes(fighterType)
      var currentPoseCollider
      const tempHurtboxLinks = {}
      if (poseHurtboxData !== undefined) {
        for (var i = 0; i < poseHurtboxData.hurtboxes.length; i++) {
          currentPoseCollider = poseHurtboxData.hurtboxes[i]
          tempHurtboxLinks[currentPoseCollider.colliderName] = {
            name: currentPoseCollider.boneName,
            shape: currentPoseCollider.shape,
            scale: currentPoseCollider.scale,
            positionOffset: currentPoseCollider.positionOffset,
            startingRotation: currentPoseCollider.startingRotation
          }
        }
      }
      setHurtboxLinks((prevState) => { return {...prevState, [fighterType]: tempHurtboxLinks } })
    }
  }  

  const saveCollisionData = (currentFrame, saveBool) => {
    const data = {
      name: animation.name,
      colliderType: colliderType.name,
      frames: (
        colliderType.name === "hitbox" ? 
        savedFrameData[fighterType][animation.name].hitboxFrames :
        savedFrameData[fighterType][animation.name].hurtboxFrames
      )
    }
    data.frames[currentFrame].colliders = []
    scene.children.forEach((child) => {
      if (child.type === "Mesh" && child.name !== "wall") {
        data.frames[currentFrame].colliders.push({
          name: child.name,
          shape: child.geometry.type.replace("Geometry", ""),
          position: {x: child.position.x, y: child.position.y, z: child.position.z},
          rotation: {x: child.rotation._x, y: child.rotation._y, z: child.rotation._z},
          scale: {x: child.scale.x, y: child.scale.y, z: child.scale.z}
        })
      }
    })
    if (saveBool) {
      saveAnimationFrames(data, fighterType)
    }
    else {
      return data
    }
  }

  const saveEntireAnimationColliders = (currentFrame, currentAnimationData) => {
    requestAnimationFrame(() => {
      if (currentFrame < totalFrames) {
        const data = saveCollisionData(currentFrame, false)
        if (currentAnimationData === undefined) {
          currentAnimationData = data
        }
        else {
          currentAnimationData.frames[currentFrame].colliders = [...data.frames[currentFrame].colliders]
        }
        setAnimationFrame(currentFrame)
        saveEntireAnimationColliders(currentFrame + 1, currentAnimationData)
      }
      else {
        saveAnimationFrames(currentAnimationData, fighterType)
      }
    })  
  }

  const savePoseData = () => {
    const linkedHurtboxesData = Object.keys(hurtboxLinks[fighterType])
    .map((colliderName) => {
      return { 
        colliderName: colliderName,
        boneName: hurtboxLinks[fighterType][colliderName].name,
        shape: hurtboxLinks[fighterType][colliderName].shape,
        scale: hurtboxLinks[fighterType][colliderName].scale,
        positionOffset: hurtboxLinks[fighterType][colliderName].positionOffset,
        startingRotation: hurtboxLinks[fighterType][colliderName].startingRotation
      }
    })
    saveLinkedHurtboxes(linkedHurtboxesData, fighterType)
  }

  const carryForwardCollider = () => {
    setSavedFrameData((prevState) => {
      if (colliderType.name === "hitbox") {
        const previousHitbox = prevState[fighterType][animation.name].hitboxFrames[animationFrame - 1]
        prevState[fighterType][animation.name].hitboxFrames[animationFrame] = {
          frame: previousHitbox.frame + 1,
          colliders: [...previousHitbox.colliders]
        }
      }
      else if (colliderType.name === "hurtbox") {
        const previousHurtbox = prevState[fighterType][animation.name].hurtboxFrames[animationFrame - 1]
        prevState[fighterType][animation.name].hurtboxFrames[animationFrame] = {
          frame: previousHurtbox.frame + 1,
          colliders: [...previousHurtbox.colliders]
        }
      }
      return {...prevState}
    })
  }

  useEffect(async () => {
    await loadLinkedHurtboxes()
  }, [fighterType])

  useEffect(async () => {
    if (fighterType === animationData.character) {
      await loadAnimationFrames()
    }
  }, [fighterType, animationData])

  // Change this - might be able to check in the backend with the auth
  useEffect(async () => {
    const hurtboxAccess = await getColliderAccess("hurtbox")
    const hitboxAccess = await getColliderAccess("hitbox")
    setAccess({
      hurtbox: hurtboxAccess, 
      hitbox: hitboxAccess
    })
  }, [websiteContext.userAddress])

  const downloadFrameData = async () => {
    const data = await getFrameData()
    downloadObjectAsJson(data, "frame-data")
  }

  return (
    <>
      {
        (
          (access.hurtbox && colliderType.name === "hurtbox") ||
          (access.hitbox && colliderType.name === "hitbox")
        ) &&
        scene !== undefined && 
        animation !== undefined && 
        animation.name !== "a-pose" &&
        <>
          <div 
            id="collision-data__button--save-animation" 
            onClick={() => saveEntireAnimationColliders(0)}>
            <p>Save Animation</p>
          </div>
          <div 
            id="collision-data__button--save-frame" 
            onClick={() => saveCollisionData(animationFrame, true)}>
            <p>Save Frame</p>
          </div>
          {
            animationFrame > 0 && //colliderType.name === "hitbox" &&
            <div 
              id="collision-data__button--prev-frame" 
              onClick={() => carryForwardCollider()}>
              <p>Prev Frame</p>
            </div>            
          }
          <div 
            id="collision-data__button--download-dataset" 
            onClick={() => downloadDataset()}>
            <p>Download Dataset</p>
          </div>
        </>
      }
      {
        access.hurtbox &&
        scene !== undefined && 
        animation !== undefined && 
        animation.name === "a-pose" &&
        <>
          <button 
            id="collision-data__download-frame-data"
            onClick={() => downloadFrameData()}>
            DOWNLOAD FRAME DATA
          </button>
          <div 
            id="collision-data__button--save-pose" 
            onClick={() => savePoseData()}>
            <p>Save A-Pose</p>
          </div>
        </>
      }      
    </>
  )
}

export default CollisionData
import React, { useState, useEffect } from 'react'
import DressingRoomScene from '../../../helpers/dressing-room/dressing-room-scene'
import attributeMapping from '../../../helpers/asset-management/fighter-accessories/generations/all-generations'
import ColorPicker from '../color-picker/ColorPicker'
import AttributePicker from '../attribute-picker/AttributePicker'
import TexturePicker from '../texture-picker/TexturePicker'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { removeFace } from '../../../helpers/asset-management/fighter-accessories/dress-fighter'
import './dressing-room.css'

const presetOptions = [
  { name: "none", label: "None" },
  { name: "guppy", label: "Guppy" },
  { name: "artist", label: "Artist" },
  { name: "gwei", label: "Gwei" },
  { name: "marshall", label: "Marshall" },
  { name: "bo", label: "Bo" }
]

const seasonOptions = [
  { name: 1, label: "Season 1" },
  { name: 2, label: "Season 2" }
]

const DressingRoom = () => {
  const [animationData, setAnimationData] = useState(undefined)
  const [animationsReady, setAnimationsReady] = useState(false)
  const [environment, setEnvironment] = useState(undefined)
  const [preset, setPreset] = useState(presetOptions[0])
  const [season, setSeason] = useState(seasonOptions[0])

  const changePreset = (newPreset) => {
    setPreset(newPreset)
  }

  const changeSeason = (newSeason) => {
    setSeason(newSeason)
  }

  useEffect(() => {
    const env = new DressingRoomScene(setAnimationsReady, setAnimationData)

    setEnvironment(env)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  // useEffect(() => {
  //   if (environment?.character !== undefined) {
  //     removeFace(environment.character)
  //   }
  // }, [environment, season.name])

  // const saveImage = () => {
  //   let downloadLink = document.createElement('a');
  //   downloadLink.setAttribute('download', 'image.png');
  //   let canvas = document.getElementById('canvas');
  //   canvas.toBlob(function(blob) {
  //     let url = URL.createObjectURL(blob);
  //     downloadLink.setAttribute('href', url);
  //     downloadLink.click();
  //   });
  // }

  const sharedProps = { preset, environment, attributeMapping, series: season.name }

  return (
    <>
      <div id="dressing-room__container">
        {/* <button onClick={() => saveImage()}>SAVE</button> */}
        <div id="dressing-room"></div>
        <Dropdown
          containerId={"preset-character__container"}
          dropdownId={"preset-character-dropdown"}
          selection={preset}
          clickFunction={changePreset}
          options={presetOptions}
          positionStyling={{}} />
        <Dropdown
          containerId={"generation-selection__container"}
          dropdownId={"generation-selection-dropdown"}
          selection={season}
          clickFunction={changeSeason}
          options={seasonOptions}
          positionStyling={{}} />

        <ColorPicker attributeType="skin" {...sharedProps} />
        <ColorPicker attributeType="limbs" {...sharedProps} />
        
        <AttributePicker attributeType="head" {...sharedProps} />
        <AttributePicker attributeType="feet" {...sharedProps} />
        <AttributePicker attributeType="hands" {...sharedProps} />
        <AttributePicker attributeType="body" {...sharedProps} />

        {
          season.name === 1 &&
          <>
            <TexturePicker attributeType="eyes" {...sharedProps} />
            <TexturePicker attributeType="mouth" {...sharedProps} />
          </>
        }
        {
          season.name === 2 &&
          <>
            <AttributePicker attributeType="eyes" {...sharedProps} />
            <AttributePicker attributeType="mouth" {...sharedProps} />
          </>
        }
      </div>
    </>
  )
}

export default DressingRoom
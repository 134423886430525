import React from 'react'
import { colliderLinkingAtom } from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './use-ml-checkbox.css'

const UseMLCheckbox = () => {
  const [colliderLinking, setColliderLinking] = useAtom(colliderLinkingAtom)

  return (
    <label 
      className="use-ml-checkbox__container"
      onClick={() => setColliderLinking((prevState) => { 
        return { bones: false, ml: !prevState.ml, live: false } 
      })}>
      Use ML
      <span 
        className={`use-ml-checkbox__checkmark${colliderLinking.ml ? " checked" : ""}`}>
      </span>
    </label>
  )
}

export default UseMLCheckbox
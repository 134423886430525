const hurtboxMappings = {
  "captured": "captured",
  "capturedStart": "captured-start",
  "crouchStart": "crouch-start",
  "crouch": "crouch",
  "crouchHold": "crouch-hold",
  "crouchEnd": "crouch-end",
  "doublePunch": "double-punch",
  "electricSpecial": "electric-special",
  "electricSpecialAerial": "electric-special-aerial",  
  "fallDownSweep": "fall-down-sweep",
  "fall": "fall",
  "fallOnBack": "fall-on-back",
  "fallOnFace": "fall-on-face",
  "fireSpecial": "fire-special",
  "fireSpecialAerial": "fire-special-aerial",
  "flipKick": "flip-kick",
  "flyingKick": "flying-kick",
  "frontKick": "front-kick",
  "hitLight": "hit-light",
  "hitLight2": "hit-light-2",
  "hitLight3": "hit-light-3",
  "hitLightBehind": "hit-light-behind",
  "hitLightBehind2": "hit-light-behind-2",  
  "getUpBack": "get-up-back",
  "getUpFront": "get-up-front",
  "grabAttack": "grab-attack",
  "grabEnd": "grab-end",
  "grabHold": "grab-hold",
  "grabStart": "grab-start",
  "headbuttCharge": "headbutt-charge",
  "headbuttEnd": "headbutt-end",
  "headbutt": "headbutt",
  "headbuttStart": "headbutt-start",
  "idle": "idle",
  "jumpFall": "jump-fall",
  "jumpLand": "jump-land",
  "jumpStart": "jump-start",
  "jumpWindup": "jump-windup",
  "kickAerial": "kick-aerial",
  "knockbackHeavy": "knockback-heavy",
  "knockbackLight": "knockback-light",
  "knockbackSpin": "knockback-spin",
  "ledgeCatch": "ledge-catch",
  "ledgeClimb": "ledge-climb",
  "ledgeHang": "ledge-hang",
  "lowSweep": "low-sweep",
  "lowSweepFromCrouch": "low-sweep-from-crouch",
  "punchAerial": "punch-aerial",
  "punchDownAerialStart": "punch-down-aerial-start",
  "punchDownAerialHold": "punch-down-aerial-hold",
  "punchDownAerialEnd": "punch-down-aerial-end",
  "punch": "punch",
  "ragdoll": "ragdoll",
  "ragdollLand": "ragdoll-land",
  "roll": "roll",
  "run": "run",
  "jumpAerial": "jump-aerial",
  "jumpBackAerial": "jump-back-aerial",
  "shieldBreak": "shield-break",
  "shieldHold": "shield-hold",
  "shieldStart": "shield-start",
  "parryStun": "parry-stun",
  "throwBack": "throw-back",
  "throwDown": "throw-down",
  "throwForward": "throw-forward",
  "throwUp": "throw-up",
  "thrownBack": "thrown-back",
  "thrownDown": "thrown-down",
  "thrownForward": "thrown-forward",
  "turn": "turn",
  "uppercutAerial": "uppercut-aerial",
  "uppercutGround": "uppercut-ground",
  "wallHang": "wall-hang",
  "wallJump": "wall-jump",
  "waterSpecial": "water-special",
  "waterSpecialAerial": "water-special-aerial",
  "backAerialKick": "back-aerial-kick"
}

const reverseHurtboxMappings = Object.fromEntries(
  Object.entries(hurtboxMappings).map(([key, value]) => [value, key])
)

const agnesHurtboxMappings = {
  "auIdle": "idle",
  "leftPunch": "left-punch",
  "rightPunch": "right-punch",
  "twoHandsSlap": "two-hands-slap"
}

const agnesReverseHurtboxMappings = Object.fromEntries(
  Object.entries(agnesHurtboxMappings).map(([key, value]) => [value, key])
)

const allReverseHurtboxMappings = {
  "fighter": reverseHurtboxMappings,
  "agnes-ultimate": agnesReverseHurtboxMappings
}

const sharedHurtboxes = [
  ["fallOffPlatform", "jumpFall"]
]

const copyHurtboxColliders = {
  "parryStunAerial": {
    copyFrom: "jumpFall",
    numFrames: 36,
    mod: 36
  },
  "shieldHit": {
    copyFrom: "shieldHold",
    numFrames: 30,
    mod: 10
  }
}

export {
  copyHurtboxColliders,
  hurtboxMappings,
  reverseHurtboxMappings,
  agnesHurtboxMappings,
  allReverseHurtboxMappings,
  sharedHurtboxes
}
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import createScene from "../threejs/scene-setup"
import { updateToonEdge, updateToonShaderLight } from '../threejs/toon-rendering'

import eyesAccessoryMetadata from "../asset-management/fighter-accessories/metadata/eyes"
import mouthAccessoryMetadata from "../asset-management/fighter-accessories/metadata/mouth"
import headAccessoryMetadata from "../asset-management/fighter-accessories/metadata/head"
import bodyAccessoryMetadata from "../asset-management/fighter-accessories/metadata/body"
import handsAccessoryMetadata from "../asset-management/fighter-accessories/metadata/hands"
import feetAccessoryMetadata from "../asset-management/fighter-accessories/metadata/feet"
import armsAccessoryMetadata from "../asset-management/fighter-accessories/metadata/arms"
import legsAccessoryMetadata from "../asset-management/fighter-accessories/metadata/legs"
import skinColorMetadata from "../asset-management/fighter-accessories/metadata/skin"
// import limbColorMetadata from "../asset-management/fighter-accessories/metadata/limb"

import Accessory from '../asset-management/fighter-accessories/accessory'
import {  
  getAccessoryParams,
  removeBodyMesh,
  meshesToRemove
} from '../asset-management/fighter-accessories/dress-fighter'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
// import fighterBodyFile from "../../local-assets/gameplay/characters/base-body--high-poly.fbx"
// import fighterFaceFile from "../../local-assets/gameplay/characters/base-face-plate--high-poly.fbx"
import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"
import fighterModifier from "../model-modifiers/characters/fighter-modifier"
import Resources from "../resource-management/resources"
import { animationsMapping } from "./animation-mapping"
import * as THREE from "three"
import * as d3 from 'd3'

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

const baseUrl = "https://ai-arena.b-cdn.net/"

const keepBodyParts = ["head", "mouth", "eyes"]
const meshesToShow = {
  "head": ["mesh_head", "head_accessory", "hair"],
  // "feet": ["mesh_feet", "feet_accessory", "feet_skin"],
  "feet": ["feet_accessory", "feet_skin"],  
  "hands": ["hands_accessory", "hands_skin"],
  "body": ["body_accessory", "body_skin"],
  "arms": ["arms_accessory", "arms_skin"],
  "legs": ["legs_accessory", "legs_accessory_extra", "legs_skin"],
  "eyes": ["head_eyes", "mesh_eyes", "eyes_glass"],
  "mouth": ["head_mouth", "mesh_mouth"]
}

const gamutTextures = {
  eyes: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8"],
  mouth: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8", "2-9", "2-10"],
  hands: ["2-3", "2-4", "2-9"],
  feet: ["2-1", "2-7"],
  body: ["2-8"]
}

const faceMeshes = {
  eyes: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8"],
  mouth: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8", "2-9", "2-10"],
}

const attributesWithSkin = {
  head: {
    series: {
      1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },
  body: {
    series: {
      1: [1, 2, 4, 5, 7, 8, 9, 10]
    }
  },
  feet: {
    series: {
      1: [7, 10]
    }
  },
  hands: {
    series: {
      1: [1, 5, 6, 8, 9, 10]
    }
  }
}

const attributesWithLimb = {
  arms: {
    series: {
      1: [1, 4, 5, 7, 8, 9, 10]
    }
  },
  legs: {
    series: {
      1: [1, 2, 5, 6, 7, 10]
    }
  },
}


const metadataMapping = {
  head: headAccessoryMetadata,
  body: bodyAccessoryMetadata,
  hands: handsAccessoryMetadata,
  feet: feetAccessoryMetadata,
  arms: armsAccessoryMetadata,
  legs: legsAccessoryMetadata,
  eyes: eyesAccessoryMetadata,
  mouth: mouthAccessoryMetadata
}

export default class SaveImagesScene {
  constructor(setAnimationsReady, setAnimationData) {
    this.initialize(setAnimationsReady, setAnimationData)
  }

  initialize(setAnimationsReady, setAnimationData) {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "save-images",
      camera: { fov: 25, near: 0.1, far: 1000 },
    })

    this.targetFPS = 30
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 7)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0, 1, 1.66)
    this.scene.add(this.directionalLight)

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
    this.scene.add(this.ambientLight)

    const additionalSources = [
      {
        name: "fighter",
        type: "fbxModel",
        tags: ["saveImages"],
        path: fighterBodyFile,
      },
      {
        name: "gradientMap",
        type: "texture",
        tags: ["saveImages"],
        path: gradientMapFile,
      },
      {
        name: "colorGamut",
        type: "texture",
        tags: ["saveImages"],
        path: baseUrl + "shared/color-gamut-1.webp"
      },      
      {
        name: "facePlate",
        type: "fbxModel",
        tags: ["saveImages"],
        // path: fighterFaceFile,
        path: 'accessories/face/face.fbx',
      }
    ]

    this.bodyComplements = {
      series: {
        1: {
          arms: [2, 3, 4, 6, 8, 9],
          legs: [3, 4, 8, 9]
        },
        2: {
          arms: [1, 2, 3, 4, 5, 6, 7, 8, 10],
          legs: [1, 2, 3, 4, 5, 6, 7, 9, 10]
        }
      }
    }

    this.assetsToLoad = {
      // head: {
      //   series: {
      //     // 1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      //     2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      //   },
      //   // special: ["beta"]
      // },
      // body: {
      //   series: {
      //     // 1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      //     2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      //   }
      // },
      // arms: {
      //   series: {
      //     1: [2, 3, 4, 6, 8, 9]
      //   }
      // },
      // legs: {
      //   series: {
      //     1: [3, 4, 8, 9]
      //   }
      // },
      // feet: {
      //   series: {
      //     // 1: [1, 2, 3, 4, 5, 6, 8, 9, 10],
      //     2: [1, 2, 3, 4, 5, 6, 8, 9, 10]
      //   }
      // },
      // hands: {
      //   series: {
      //     // 1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      //     2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      //   }
      // },
      eyes: {
        series: {
          2: [1, 2, 3, 4, 5, 6, 8]
        }
      },
      mouth: {
        series: {
          2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
      }      
    }

    this.inPosePosition = false
    this.currentPermutation = undefined
    this.permutations = []
    this.imagesToLoad = []

    Object.keys(this.assetsToLoad).forEach((group) => {
      const groupData = this.assetsToLoad[group]
      if (groupData.series !== undefined) {
        Object.keys(groupData.series).forEach((s) => {
          groupData.series[s].forEach((idx) => {
            const rawKey = `${s}-${idx}`
            const modelKey = `${group}-${rawKey}`
            let textureKey = `${modelKey}--base-color`
            if (gamutTextures[group] && gamutTextures[group].includes(rawKey)) {
              textureKey = "colorGamut"
            }
            const baseKey = `${baseUrl}/accessories/${group}/${rawKey}/${modelKey}`
            var permutationData = { modelKey }
            additionalSources.push({
              name: modelKey,
              type: "fbxModel",
              tags: ["saveImages"],
              path: baseKey + ".fbx",
            })

            const isLimb = group === "arms" || group === "legs"
            if (!isLimb && metadataMapping[group][modelKey]?.useTexture) {
              permutationData = { ...permutationData, textureKey }
              if (textureKey !== "colorGamut") {
                additionalSources.push({
                  name: textureKey,
                  type: "texture",
                  tags: ["saveImages"],
                  path: baseKey + "--base-color.webp",
                })
              }
            }
            
            // if (isLimb && attributesWithLimb[group].series[s].includes(idx)) {
              // Object.keys(limbColorMetadata).forEach((limbKey) => {
              //   if (!isNaN(+limbKey.split("-")[1])) {
              //     permutationData.limbKey = limbKey
              //     this.addToPermutations(permutationData, group, rawKey)
              //   }
              // })
            // }
            // else if (!isLimb && attributesWithSkin[group].series[s].includes(idx)) {
              // Object.keys(skinColorMetadata).forEach((skinKey) => {
              //   if (!isNaN(+skinKey.split("-")[1])) {
              //     permutationData.skinKey = skinKey
              //     this.addToPermutations(permutationData, group, rawKey)
              //   }
              // })
            // }
            // else {
              this.addToPermutations(permutationData, group, rawKey)
              if (group === "body") {
                const complements = this.bodyComplements.series[s]
                if (complements.arms.includes(idx)) {
                  additionalSources.push(this.getLimbPermutation(modelKey, baseKey, "arms"))
                }
                if (complements.legs.includes(idx)) {
                  additionalSources.push(this.getLimbPermutation(modelKey, baseKey, "legs"))
                }
              }
            // }
          })
        })
      }
    })

    const saveBareBody = false
    if (saveBareBody) { 
      var permutationData = {}
      Object.keys(skinColorMetadata).forEach((skinKey) => {
        if (!isNaN(+skinKey.split("-")[1])) {
          permutationData = { modelKey: skinKey }
          this.addToPermutations(permutationData, "skin", undefined)
        }
      })

      // ["feet", "arms", "legs"].forEach((group) => {        
      //   const modelKey = `${group}-bare`
      //   var permutationData = { modelKey }
      //   const isLimb = group === "arms" || group === "legs"
      //   if (isLimb) {
      //     Object.keys(limbColorMetadata).forEach((limbKey) => {
      //       if (!isNaN(+limbKey.split("-")[1])) {
      //         permutationData.limbKey = limbKey
      //         this.addToPermutations(permutationData, group, undefined)
      //       }
      //     })
      //   }
      //   else {
      //     Object.keys(skinColorMetadata).forEach((skinKey) => {
      //       if (!isNaN(+skinKey.split("-")[1])) {
      //         permutationData.skinKey = skinKey
      //         this.addToPermutations(permutationData, group, undefined)
      //       }
      //     })
      //   }
      // })
    }

    const saveFaces = false
    const darkMouths = ["1-3", "1-4"]
    if (saveFaces) {
      ["eyes", "mouth"].forEach((group) => {
        const s = 1
        for (var idx = 1; idx < 11; idx++) {
          const rawKey = `${s}-${idx}`
          const modelKey = `${group}-${rawKey}`
          const textureKey = `${modelKey}`
          const baseKey = `accessories/${group}/${rawKey}/${modelKey}`
          var permutationData = { modelKey, textureKey }
          additionalSources.push({
            name: textureKey,
            type: "texture",
            tags: ["saveImages"],
            path: baseKey + ".webp",
          })
          this.addToPermutations(permutationData, group, rawKey)
          if (group === "mouth" && darkMouths.includes(rawKey)) {
            const darkKey = textureKey + "--darker"
            additionalSources.push({
              name: darkKey,
              type: "texture",
              tags: ["saveImages"],
              path: baseKey + "--darker.webp",
            })
            permutationData = { modelKey: darkKey, textureKey: darkKey }
            this.addToPermutations(permutationData, group, rawKey)
          }
        }
      })
    }

    this.allMeshes = []
    this.materials = {}

    this.animations = {}
    this.mixer = undefined
    this.resources = new Resources("saveImages", additionalSources)
    this.resources.on("ready", () => {
      const fighterScale = 0.025
      const fbx = this.resources.items["fighter"]
      // fbx.children.forEach((child) => {
      //   if (child.type.includes("Mesh")) {
      //     child.name = child.name.replace("_1", "")
      //   }
      //   else {
      //     child.name = "Armature"
      //   }
      // })
      // console.log(fbx)
      fbx.scale.setScalar(fighterScale)
      fbx.position.set(0, -1.1, 0)
      fbx.name = "fighter"

      // Object.keys(this.resources.items).forEach((item) => {
      //   if (item.includes("base-color")) {
      //     this.resources.items[item].encoding = THREE.sRGBEncoding
      //     this.threejs.instance.initTexture(this.resources.items[item])
      //   }
      // })

      for (var m = 0; m < fbx.children.length; m++) {
        if (fbx.children[m].type.includes("Mesh")) {
          fbx.children[m].castShadow = true
          this.allMeshes.push(fbx.children[m])          
        }
      }

      const accessoryData = {
        series: 1,
        skinId: 10,
        limbId: 1,
        nakedFighter: true,
      }

      fighterModifier(
        fbx,
        this.resources,
        accessoryData,
        this.allMeshes
      )
      this.scene.add(fbx)
      this.character = fbx
      this.currentAnimation = "idle"

      const transparentFaceMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 })
      fbx.getObjectByName("head_mouth").material = transparentFaceMaterial
      fbx.getObjectByName("head_eyes").material = transparentFaceMaterial

      this.resources.loaders.fbxLoader.manager.onLoad = () => {
        this.manager = new THREE.LoadingManager()
        this.manager.onLoad = () => {
          setAnimationsReady(true)
          setAnimationData({
            mixer: this.mixer,
            animations: this.animations,
            fighter: fbx,
            armature: fbx.children.filter(
              (child) => child.name === "Armature"
            )[0],
          })

          fbx.getObjectByName("Armature").rotation.y = Math.PI / 3.2//2
          this.animations[this.currentAnimation].action.play()
          this.animations[this.currentAnimation].action.time = 0
          this.mixer.update(0)
        }

        this.mixer = new THREE.AnimationMixer(fbx)

        const onLoad = (animName, anim) => {
          const clip = anim.animations[0]
          const action = this.mixer.clipAction(clip)
          this.animations[animName] = {
            clip: clip,
            action: action,
            frameCount: Math.round(clip.duration * 60) + 1,
          }
        }

        const loader = new FBXLoader(this.manager)
        Object.keys(animationsMapping).forEach((animationName) => {
          loader.load(animationsMapping[animationName], (a) => {
            onLoad(animationName, a)
          })
        })
      }
    })

    this.lastUpdate = 0
    this.previousRAF = null
    this.render()
  }

  getLimbPermutation(modelKey, baseKey, limbName) {
    return {
      name: modelKey.replace("body", limbName),
      type: "fbxModel",
      tags: ["saveImages"],
      path: baseKey.replace(/body/g, limbName) + ".fbx",
    }
  }

  poseFighter() {
    const flag = d3.select("#save-images--pose-flag")
    if (flag._groups[0][0] !== undefined && flag._groups[0][0] !== null) {
      if (flag.style("display") === "block" && !this.inPosePosition) {
        const poseAnimation = "idle"
        const poseFrame = 10
        const roundingError = 1 / 600
        this.animations[this.currentAnimation].action.stop()
        this.animations[poseAnimation].action.play()
        this.animations[poseAnimation].action.time = 0
        this.mixer.update(Math.max(poseFrame / 60 - roundingError, 0))
        // this.character.getObjectByName("Armature").rotation.y = Math.PI / 3
        this.currentAnimation = poseAnimation
        this.inPosePosition = true
      }
    }
  }

  colorToV4(c) {
    return new THREE.Vector4(c.r, c.g, c.b, 1)
  }

  changeMaterialColor(baseMaterial, colorMetadata) {
    baseMaterial.uniforms.color.value = this.colorToV4(colorMetadata.color)
    baseMaterial.uniforms.shadeColor.value = this.colorToV4(colorMetadata.shadeColor)
    baseMaterial.uniforms.rimColor.value = this.colorToV4(colorMetadata.rimColor)
  }

  attachFaceTexture() {
    var facePlateMesh
    if (this.currentPermutation.group === "mouth") {
      facePlateMesh = this.character.getObjectByName("head_mouth")
    }
    else {
      facePlateMesh = this.character.getObjectByName("head_eyes")
    }
    const texture = this.resources.items[this.currentPermutation.modelKey]
    texture.encoding = THREE.sRGBEncoding
    texture.minFilter = THREE.NearestFilter
    texture.magFilter = THREE.NearestFilter
    facePlateMesh.material = new THREE.MeshBasicMaterial({ 
      map: texture,
      transparent: true,
      depthWrite: false,
      depthTest: true,
    })
  }

  addClothingLimb(params, items, modelKey, limbName) {
    const newKey = modelKey.replace("body", limbName)
    const metadata = metadataMapping[limbName][newKey]
    const limb = this.character.children.filter((child) => child.name === `mesh_${limbName}`)[0]
    if (metadata !== undefined) {
      params.fbx = items[newKey]
      params.metadata = metadata
      limb.visible = false
      new Accessory(params)
    }
    else {
      limb.visible = true
    }
  }

  attachAttribute() {
    const skinMaterial = this.character.getObjectByName("mesh_head").material
    const limbMaterial = this.character.getObjectByName("mesh_arms").material
    if (this.currentPermutation.group === "skin") {
      this.changeMaterialColor(skinMaterial, skinColorMetadata[this.currentPermutation.modelKey])
      this.changeMaterialColor(limbMaterial, skinColorMetadata[this.currentPermutation.modelKey])
    }
    
    var type = this.currentPermutation.group
    const bareBodyBool = this.currentPermutation.modelKey.includes("-bare") || type === "skin"
    if (!bareBodyBool) {
      const headMesh = this.character.children.filter((child) => child.name === "mesh_head")[0]
      const params = getAccessoryParams(
        this.resources.items["gradientMap"],
        this.resources.items["colorGamut"], 
        this.character.scale.x, 
        this.resources.loaders, 
        headMesh.skeleton.bones[0], 
        headMesh.skeleton,
        skinMaterial,
        limbMaterial,
        this.resources["toonMaterials"],
        [],
        this.currentPermutation.rawKey, 
        this.currentPermutation.group,
        {}       
      )
      params.fbx = this.resources.items[this.currentPermutation.modelKey]
      params.baseColor = this.resources.items[this.currentPermutation.textureKey]
      if (params.baseColor !== undefined) {
        params.baseColor.encoding = THREE.sRGBEncoding
      }

      params.metadata = metadataMapping[type][this.currentPermutation.modelKey]
      params.anchorPoint = this.character
      params.bindBool = true
      meshesToRemove[type].forEach((meshName) => {
        removeBodyMesh(this.character, meshName)
      })
      new Accessory(params)
      if (type === "body") {
        this.addClothingLimb(params, this.resources.items, this.currentPermutation.modelKey, "arms")
        this.addClothingLimb(params, this.resources.items, this.currentPermutation.modelKey, "legs")
      }
      if (!keepBodyParts.includes(type)) {
        this.character.getObjectByName(`mesh_${type}`).visible = false
      }
    }
    else {
      if (type !== "skin") {
        this.character.getObjectByName(`mesh_${type}`).visible = true
      }
    }
  }

  checkIfImagesLoaded() {    
    if (this.imagesToLoad.length > 0) {
      var imagesLoaded = true
      for (var i = 0; i < this.imagesToLoad.length; i++) {
        if (this.imagesToLoad[i].image === undefined) {
          imagesLoaded = false
          break
        }
      }
      return imagesLoaded
    }
    else return true
  }

  showCorrectAttribute() {
    const keepMeshes = meshesToShow[this.currentPermutation.group]
    this.imagesToLoad = []
    this.character.traverse((child) => {
      if (child.type.includes("Mesh")) {
        const bareBodyBool = this.currentPermutation.modelKey.includes("-bare")
        child.visible = (
          (bareBodyBool && child.name === "mesh_" + this.currentPermutation.group) ||
          (!bareBodyBool && keepMeshes.includes(child.name)) || 
          (
            this.currentPermutation.group === "body" && 
            (child.name.includes("arms_accessory") || child.name.includes("legs_accessory"))
          )
        )
        if (child.visible) {
          if (child.material.uniforms !== undefined) {
            if (child.material.uniforms.useTexture.value) {
              this.imagesToLoad.push(child.material.uniforms.uTexture.value)
            }
          }
          else {
            if (child.material.map) {
              this.imagesToLoad.push(child.material.map)
            }
          }
        }
      }
    })
  }

  addToPermutations(permutationData, group, rawKey) {
    var savingKey = permutationData.modelKey
    if (permutationData.skinKey !== undefined) {
      savingKey += `--${permutationData.skinKey}`
    }
    if (permutationData.limbKey !== undefined) {
      savingKey += `--${permutationData.limbKey}`
    }
    this.permutations.push(
      {
        ...permutationData,
        group: group,
        rawKey, rawKey,
        savingKey: savingKey,
      }
    )
  }

  nextAttributePermutation() {
    this.currentPermutation = this.permutations[this.permutations.length - 1]
    if (this.currentPermutation !== undefined) {
      const isFace = ["eyes", "mouth"].includes(this.currentPermutation.group)
      if (isFace && !faceMeshes[this.currentPermutation.group].includes(this.currentPermutation.rawKey)) {
        this.attachFaceTexture()
      }
      else {
        this.attachAttribute()
      }

      if (this.currentPermutation.group !== "skin") {
        this.showCorrectAttribute()
      }
    }
  }

  saveCondition() {
    var condition = false
    const flag = d3.select("#save-images--flag")
    if (flag._groups[0][0] !== undefined && flag._groups[0][0] !== null) {
      condition = flag.style("display") === "block" && this.permutations.length > 0
      if (this.permutations.length === 0) {
        flag.style("display", "none")
      }
    }
    return condition
  }

  saveImage() {
    if (this.saveCondition() && this.checkIfImagesLoaded()) {
      if (this.currentPermutation !== undefined) {
        let downloadLink = document.createElement('a');
        downloadLink.setAttribute('download', this.currentPermutation.savingKey + '.png');
        let canvas = document.getElementById('canvas');
        canvas.toBlob(function(blob) {
          let url = URL.createObjectURL(blob);
          downloadLink.setAttribute('href', url);
          downloadLink.click();
        });
        this.permutations.pop()
      }
      this.nextAttributePermutation()
    }
  }

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }

      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        this.previousRAF = t - (elapsed % this.fpsInterval)
        if (this.mixer !== undefined && !this.inPosePosition) {
          this.mixer.update(elapsed)
        }
        updateToonShaderLight(this.resources, this.directionalLight)
        // updateToonEdge(this.resources, true)
        // this.threejs.instance.render(this.scene, this.camera.instance)        

        updateToonEdge(this.resources, false)
        this.threejs.instance.render(this.scene, this.camera.instance)
        this.poseFighter()
        if (this.inPosePosition && this.lastUpdate > 0.1) {
          this.saveImage()
          this.lastUpdate = 0
        }
        else {
          this.lastUpdate += elapsed
        }
      }
      this.render()
    })
  }
}

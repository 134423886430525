import { createColliderMesh } from './create-collider-mesh'

const doesMeshExist = (currentColliderName, meshesInScene) => {
  let exists = false
  for (var c = 0; c < meshesInScene.length; c++) {
    exists = meshesInScene[c].name === currentColliderName
    if (exists) break
  }
  return exists
}

const unselectAllMeshes = (scene) => {
  if (scene !== undefined) {
    scene.children.forEach((child) => {
      if (child.type === "Mesh" && child.name !== "wall") {
        child.material.opacity = 0.2
      }
    })
  }
}

const addMeshToScene = (
  geometryName, 
  transformData, 
  setMeshesInScene, 
  setColliderSelected, 
  scene
) => {
  const colliderMesh = createColliderMesh(geometryName, transformData)
  scene.add(colliderMesh)
  setMeshesInScene((prevState) => { return [...prevState, colliderMesh] })
  colliderMesh.on("click", (e) => {
    unselectAllMeshes(scene)
    if (e.target.material.opacity === 0.4) {
      setColliderSelected(undefined)
    }
    else {
      e.target.material.opacity = 0.4
      setColliderSelected(e.target)
    }
  })
}

const removeMeshFromScene = (mesh, setMeshesInScene, scene) => {
  setMeshesInScene((prevState) => { 
    return prevState.filter((m) => m.uuid !== mesh.uuid) 
  })
  mesh.material.dispose()
  mesh.geometry.dispose()
  scene.remove(mesh)    
}

export {
  doesMeshExist,
  unselectAllMeshes,
  addMeshToScene,
  removeMeshFromScene
}
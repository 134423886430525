const fillVertexPrepend = `
  varying vec3 vPosition;
`

const fillVertexContent = `
  vPosition = transformed;
`

export {
  fillVertexPrepend,
  fillVertexContent
}
import React, { useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { animationsMapping, emoteAnimations } from '../../../helpers/collision-maker/animation-mapping'
import './animation-menu.css'

const allAnimations = {...animationsMapping, ...emoteAnimations}

const AnimationMenu = ({ 
  animationsReady, 
  animationData, 
  animation, 
  setAnimation, 
  animationFrame, 
  setAnimationFrame, 
  setTotalFrames, 
  setColliderType,
  showShapeMenu
}) => {
  const positionStyling = {
    top: showShapeMenu ? "80px" : "10px",
    left: "20px"
  }
  const animationOptions = [
    { name: "a-pose", label: "A Pose" },
    ...Object.keys(allAnimations).flatMap((key) => {
      return Object.values(allAnimations[key].names).map((name) => {
        return { name: name, label: name.split("-").join(" ") }
      })
    })
  ]

  const changeAnimation = (newAnimation) => {
    if (animation.name !== "a-pose") {
      animationData.animations[animation.name].action.stop()
    }
    if (newAnimation.name === "a-pose") {
      setColliderType({ name: "hurtbox", label: "hurtbox" })
      setTotalFrames(0)
    }
    setAnimation(newAnimation)
  }

  useEffect(() => {
    setAnimation(animationOptions[0])
  }, [])

  useEffect(() => {
    if (
      animationsReady && 
      animation !== undefined && 
      animationData !== undefined
    ) {
      if (animation.name === "a-pose") {
        // animationData.fighter.rotation.y = 0
        animationData.fighter.getObjectByName("Armature").rotation.y = 0
      }
      else {
        // animationData.fighter.rotation.y = Math.PI / 2
        animationData.fighter.getObjectByName("Armature").rotation.y = Math.PI / 2
        setTotalFrames(animationData.animations[animation.name].frameCount)
        animationData.animations[animation.name].action.play()
        animationData.animations[animation.name].action.time = 0
        setAnimationFrame(0)
        animationData.mixer.update(0)
      }
    }
  }, [animation, animationData, animationsReady])

  useEffect(() => {
    if (animationData !== undefined) {
      const roundingError = 1 / 600
      animationData.animations[animation.name].action.play()
      animationData.animations[animation.name].action.time = 0
      animationData.mixer.update(Math.max(animationFrame / 60 - roundingError, 0))
    }
  }, [animationFrame])

  return (
    <>
      {
        animation !== undefined &&
        <Dropdown
          containerId="animation-menu__container"
          dropdownId="animation-menu-dropdown"
          selection={animation}
          clickFunction={changeAnimation}
          options={animationOptions}
          positionStyling={positionStyling}>
        </Dropdown>
      }
    </>
  )
}

export default AnimationMenu
import { inplacePositioning } from './default-metadata'

const season2 = {
  "eyes-2-1": {...inplacePositioning},
  "eyes-2-2": {...inplacePositioning},
  "eyes-2-3": {...inplacePositioning},
  "eyes-2-4": {...inplacePositioning},
  "eyes-2-5": {...inplacePositioning},
  "eyes-2-6": {...inplacePositioning},
  "eyes-2-7": {...inplacePositioning},
  "eyes-2-8": {...inplacePositioning},
}

const eyesAccessoryMetadata = {
  ...season2
}

export default eyesAccessoryMetadata
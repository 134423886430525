import React, { useState, useEffect } from 'react'
import FramesMenu from '../frames-menu/FramesMenu'
import AnimationMenu from '../animation-menu/AnimationMenu'
import SilhouetteMakerScene from '../../../helpers/silhouette-maker/silhouette-maker-scene'
import './silhouette-maker.css'

const SilhouetteMaker = () => {
  const [scene, setScene] = useState(undefined)
  const [camera, setCamera] = useState(undefined)
  const [animationData, setAnimationData] = useState(undefined)
  const [animationsReady, setAnimationsReady] = useState(false)
  const [animation, setAnimation] = useState(undefined)
  const [totalFrames, setTotalFrames] = useState(0)
  const [animationFrame, setAnimationFrame] = useState(0)

  const handleKeydown = (e) => {
    if (e.key === "ArrowRight") {
      setAnimationFrame((prevState) => {
        return Math.min(prevState + 1, totalFrames - 1)
      })
    }
    else if (e.key === "ArrowLeft") {
      setAnimationFrame((prevState) => {
        return Math.max(prevState - 1, 0)
      })
    }
  }

  useEffect(() => {
    const env = new SilhouetteMakerScene(setAnimationsReady, setAnimationData)
    setScene(env.scene)
    setCamera(env.camera.instance)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown)
    return () => {
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [totalFrames])

  const saveImage = () => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'image.png');
    let canvas = document.getElementById('canvas');
    canvas.toBlob(function(blob) {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.click();
    });
  }

  return (
    <>
      <div id="silhouette-maker__container">
        {
          animationsReady &&
          <button style={{ pointerEvents: "auto", position: "absolute"}} onClick={() => saveImage()}>Save Image</button>
        }
        <div id="silhouette-maker__menu">     
          <FramesMenu 
            animationData={animationData}
            totalFrames={totalFrames}
            animationFrame={animationFrame}
            setAnimationFrame={setAnimationFrame}>
          </FramesMenu>
          {
            animationsReady &&
            <AnimationMenu
              animationsReady={animationsReady}
              animationData={animationData}
              animation={animation}
              setAnimation={setAnimation}
              animationFrame={animationFrame}
              setAnimationFrame={setAnimationFrame}
              setTotalFrames={setTotalFrames}>
            </AnimationMenu>            
          }
        </div>
        <div id="silhouette-maker">
        </div>
      </div>
    </>
  )
}

export default SilhouetteMaker
import { useEffect } from "react"
import { useAtom, atom } from "jotai"
import { addRequestInterceptor } from "../backend/utils/interceptors"
import { backend } from "../../env-variables"
import axios from "axios"

const externalAccountProviders = ["google", "twitter", "discord"]

const accessAtom = atom({
  team: false,
  dev: false,
  checked: false,
})

const getAuthenticationCredentials = async (userObj) => {
  const { info } = userObj
  let credentials = {}
  if (externalAccountProviders.includes(info.provider)) {
    credentials.emailAddress = info.email
    credentials.username = info.username
    credentials.provider = info.provider
    credentials.providerUserId = info.providerAccountId
    credentials.identifier = info.provider === "google" ? info.email : info.providerAccountId
  }
  return credentials
}

const getAccessKey = (hasAccess) => {
  const { team, dev } = hasAccess
  if (dev) return "development"
  else if (team) return "team"
  else return "noAccess"
}

const getAccessStatus = async (setHasAccess) => {
  const siteAccessResult = await axios.get(`${backend}whitelist/builder-access`)
  setHasAccess((prevState) => {
    return { ...prevState, ...siteAccessResult.data, checked: true }
  })
}

const useCredentialsHook = (session) => {
  const [hasAccess, setHasAccess] = useAtom(accessAtom)

  useEffect(async () => {
    if (session) {
      const authCredentials = await getAuthenticationCredentials(session)
      addRequestInterceptor({
        provider: authCredentials.provider, 
        identifier: authCredentials.identifier
      })
      getAccessStatus(setHasAccess)
    }
  }, [session])

  return hasAccess
}

export {
  accessAtom,
  getAccessKey,
  useCredentialsHook,
}

import React from 'react'
import './frames-menu.css'

const FramesMenu = ({ 
  animationData,
  totalFrames, 
  animationFrame, 
  setAnimationFrame,
  animation
}) => {
  const playAnimation = (totalElapsed, previousT) => {
    if (totalElapsed === 0) {
      animationData.animations[animation.name].action.play()
      animationData.animations[animation.name].action.time = 0      
    }
    requestAnimationFrame((t) => {
      if (previousT === null) {
        previousT = t
      }
      const elapsed = (t - previousT) / 1000
      if (elapsed >= 1 / 60) {
        animationData.mixer.update(elapsed)
        previousT = t
        totalElapsed += elapsed
      }

      if (totalElapsed >= animationData.animations[animation.name].action._clip.duration) {
        setAnimationFrame(totalFrames - 1)
      }
      else {
        playAnimation(totalElapsed, previousT)
      }
    })
  }

  return (
    <>
      {
        totalFrames > 0 &&
        <>
          <div id="frames-menu__play-button" onClick={() => playAnimation(0, null)}>
            <p>Play</p>
          </div>
          <div id="frames-menu__container">
            {
              [...Array(totalFrames).keys()].map((frameIdx) => {
                return (
                  <div
                    key={`frame-key--${frameIdx}`}
                    className="frames-menu__frame-key"
                    id={`frames-menu__frame-key--${frameIdx}`}
                    style={{
                      backgroundColor: frameIdx === animationFrame ? "#ff776f" : "#69e3f2"
                    }}
                    onClick={() => {
                      setAnimationFrame(frameIdx)
                    }}>
                  </div>
                )
              })
            }
          </div>
          <h1 id="frames-menu__frame-number">Frame {animationFrame}</h1>
        </>
      }
    </>
  )
}

export default FramesMenu
import * as THREE from 'three'

const convertRGB = (rgb) => {
  return rgb.map((c) => { return c / 255 })
}

const convertHex = (hex, useVec4 = false) => {
  const color = new THREE.Color(hex)
  if (useVec4) {
    return new THREE.Vector4(color.r, color.g, color.b, 1)
  }
  else {
    return color
  }
}
const defaultColor = {
  color: new THREE.Vector4(0.9, 0.9, 0.9, 1),
  shadeColor: new THREE.Vector4(0.7, 0.7, 0.7, 1),
  rimColor: new THREE.Vector4(1, 1, 1, 1),
}

const inplacePositioning = {
  rotation: { x: 0, y: 0, z: 0 },
  position: { x: 0, y: 0, z: 0 },
  scaleMultiple: 1,
  useTexture: true,
  ...defaultColor
  // color: new THREE.Vector4(1, 1, 1, 1),
  // shadeColor: null,
  // rimColor: null
}

export {
  inplacePositioning,
  convertRGB,
  convertHex
}
// Fighter Assets
import baseBody from "../../local-assets/gameplay/characters/base-body.fbx"
import baseFacePlate from "../../local-assets/gameplay/characters/base-face-plate.fbx"

const fighterLocations = [
  "fighterCollisionMaker", 
]

const models = [
  // Fighter Assets
  {
    name: "fighter",
    type: "fbxModel",
    tags: fighterLocations,
    path: baseBody,
  },
  {
    name: "facePlate",
    type: "fbxModel",
    tags: fighterLocations,
    path: baseFacePlate,
  },
  {
    name: "crown",
    type: "fbxModel",
    tags: fighterLocations,
    path: "accessories/additional/crown/additional-crown.fbx",
  },
]

export default models

import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'
import './dropdown.css'

const Dropdown = ({ containerId, dropdownId, selection, clickFunction, options, positionStyling }) => {
  const [opened, setOpened] = useState(false)

  if (positionStyling === undefined) {
    positionStyling = {
      top: 0,
      left: 0
    }
  }

  const hideDropdownOptions = (e) => {
    const dropdownOptions = d3.select(`#${dropdownId}__container`)
    if (dropdownOptions._groups[0][0] !== null) {
      if (
        dropdownOptions.style("display") !== "none" &&
        e.target.id !== `${dropdownId}__selection` &&
        e.target.id !== `${dropdownId}__container`
      ) {
        setOpened(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("click", hideDropdownOptions)
    return () => {
      window.removeEventListener("click", hideDropdownOptions)
    }
  }, [])

  return (
    <div id={containerId} className="dropdown__container" style={positionStyling}>
      <div 
        id={`${dropdownId}__selection`}
        className="dropdown__selection" 
        onClick={() => setOpened((prevState) => { return !prevState })}>
        {
          selection.image !== undefined && 
          <img src={selection.image}/>
        }
        <p>{selection.label}</p>
      </div>
      <div 
        id={`${dropdownId}__container`}
        className="dropdown__options-container"
        style={{
          display: opened ? "flex" : "none"
        }}>
        {
          options.map((option, idx) => {
            return (
              <div 
                key={`${dropdownId}--${idx}`} 
                id={`${dropdownId}__option`}
                className="dropdown__option"
                onClick={() => clickFunction(option)}>
                {
                  option.image !== undefined && 
                  <img src={option.image}/>
                }
                <p>{option.label}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Dropdown

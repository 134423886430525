import * as THREE from 'three'

const updateToonEdge = (resources, edgeBool) => {
  if (resources["toonMaterials"] !== undefined) {
    resources["toonMaterials"].forEach((material) => {
      if (material.uniforms !== undefined) {
        material.side = edgeBool ? THREE.BackSide : THREE.DoubleSide
        material.uniforms.edge.value = edgeBool
      }
    })
  }
}

const updateToonShaderLight = (resources, light) => {
  if (resources["toonMaterials"] !== undefined) {
    resources["toonMaterials"].forEach((material) => {
      if (material.uniforms !== undefined) {
        material.uniforms.lightPosition.value = light.position
      }        
    })
  }
}

export {
  updateToonEdge,
  updateToonShaderLight
}
import React from 'react'
import './manipulation-menu.css'

const ManipulationMenu = ({ setManipulationType }) => {  
  return (
    <>
      <div id="manipulation-menu__container">
        <div
          id="manipulation-menu--grab"
          className="manipulation-menu__element"
          onClick={() => setManipulationType("Grab")}>
            G
        </div>
        <div
          id="manipulation-menu--rotate"
          className="manipulation-menu__element"
          onClick={() => setManipulationType("Rotate")}>
            R
        </div>
        <div
          id="manipulation-menu--size"
          className="manipulation-menu__element"
          onClick={() => setManipulationType("Size")}>
            S
        </div>
        <div
          id="manipulation-menu--copy"
          className="manipulation-menu__element"
          onClick={() => setManipulationType("Copy")}>
            C
        </div>        
      </div>
    </>
  )
}

export default ManipulationMenu
const specularLightFragmentPrepend = `
  uniform float specularStep;
`

const specularLightFragmentContent = `
  float specularPortion = -min(dot(vPositionW, normalize(vNormalW) ), 0.0);
  specularPortion = step(specularStep, specularPortion);
  specularColor = vec3(1., 1., 1.);
  specular = specularColor * specularPortion;
`

export {
  specularLightFragmentPrepend,
  specularLightFragmentContent
}
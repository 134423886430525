import { 
  fillFragmentPrepend,
  fillFragmentContent 
} from "../fill/fill-fragment-shader"

const basicFragmentShader = `
  uniform vec3 color;
  uniform sampler2D uTexture;
  uniform bool USE_TEXTURE;
  uniform bool USE_FLASHING;
  uniform float flashTimer;

  varying vec2 vUv;

  ${fillFragmentPrepend}

  void main(void){
    if (USE_TEXTURE) {
      gl_FragColor = LinearTosRGB(texture2D(uTexture, vUv));
    }
    else {
      gl_FragColor = vec4(color, 1.);
    }

    if (USE_FLASHING) {
      vec3 flashStrength = vec3(0.25);
      float flashMultiple = sin(flashTimer * 10.) + 1.;
      gl_FragColor.rgb += flashStrength * flashMultiple;
    }

    if (fillBool) {
      ${fillFragmentContent}
    }
  }
`

export {
  basicFragmentShader
}
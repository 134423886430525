import { useEffect } from "react"
import { 
  animationFrameAtom, 
  colliderNameAtom, 
  colliderSelectedAtom,
  manipulationAtom,
  sceneAtom,
  totalFramesAtom
} from "../../collision-maker/atoms"
import { useAtom } from "jotai"

const useChangingKeydown = () => {
  const scene = useAtom(sceneAtom)[0]
  const setAnimationFrame = useAtom(animationFrameAtom)[1]
  const setColliderName = useAtom(colliderNameAtom)[1]
  const setManipulationType = useAtom(manipulationAtom)[1]
  const totalFrames = useAtom(totalFramesAtom)[0]
  const [colliderSelected, setColliderSelected] = useAtom(colliderSelectedAtom)

  const handleChangingKeydowns = (e) => {
    if (e.key === "Enter") {
      const newNameInput = document.getElementById("collider-name-input")
      if (newNameInput !== null && newNameInput.value !== "") {
        if (colliderSelected !== undefined) {
          const colliderId = colliderSelected.uuid
          const mesh = scene.children.filter((child) => child.uuid === colliderId)[0]
          mesh.name = newNameInput.value
        }
      }
      setColliderSelected(undefined)
      setManipulationType(undefined)
      setColliderName(undefined)
    }
    else if (e.key === "ArrowRight") {
      setAnimationFrame((prevState) => {
        return Math.min(prevState + 1, totalFrames - 1)
      })
    }
    else if (e.key === "ArrowLeft") {
      setAnimationFrame((prevState) => {
        return Math.max(prevState - 1, 0)
      })
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleChangingKeydowns)
    return () => {
      window.removeEventListener("keydown", handleChangingKeydowns)
    }
  }, [colliderSelected, totalFrames])
}

export {
  useChangingKeydown
}
import { inplacePositioning } from './default-metadata'

const season1 = {
  "body-1-1": {...inplacePositioning},
  "body-1-2": {...inplacePositioning},
  "body-1-3": {...inplacePositioning},
  "body-1-4": {...inplacePositioning},
  "body-1-5": {...inplacePositioning},
  "body-1-6": {...inplacePositioning},
  "body-1-7": {...inplacePositioning},
  "body-1-8": {...inplacePositioning},
  "body-1-9": {...inplacePositioning},
  "body-1-10": {...inplacePositioning},
  "body-artist": {...inplacePositioning},
  "body-gwei": {...inplacePositioning},
  "body-guppy": {...inplacePositioning},
  "body-boxing": {...inplacePositioning},
}

const season2 = {
  "body-2-1": {...inplacePositioning},
  "body-2-2": {...inplacePositioning},
  "body-2-3": {...inplacePositioning},
  "body-2-4": {...inplacePositioning},
  "body-2-5": {...inplacePositioning},
  "body-2-6": {...inplacePositioning},
  "body-2-7": {...inplacePositioning},
  "body-2-8": {...inplacePositioning},
  "body-2-9": {...inplacePositioning},
  "body-2-10": {...inplacePositioning}
}

const bodyAccessoryMetadata = {
  ...season1,
  ...season2
}

export default bodyAccessoryMetadata
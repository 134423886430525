import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import Accessory from '../../../helpers/asset-management/fighter-accessories/accessory'
import { 
  getAccessoryParams,
  removeBodyMesh,
  meshesToRemove,
  removeMouth,
  removeEyes
} from '../../../helpers/asset-management/fighter-accessories/dress-fighter'
import { convertRGB } from '../../../helpers/asset-management/fighter-accessories/metadata/default-metadata'
import headAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/head'
import feetAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/feet'
import handsAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/hands'
import bodyAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/body'
import armsAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/arms'
import legsAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/legs'
import mouthAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/mouth'
import eyesAccessoryMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/eyes'
import { accessoryDirectory } from '../../../helpers/dressing-room/dressing-room-scene'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import * as THREE from 'three'
import './attribute-picker.css'
import { GUI } from 'dat.gui'

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const keepBodyParts = ["head", "mouth", "eyes"]

const presetMappings = {
  marshall : {
    head: 5,
    hands: 1,
    feet: 7,
    body: 1
  },
  bo : {
    head: 10,
    hands: 2,
    feet: 1,
    body: "boxing"
  }
}

const customTextures = {
  marshall: ["head", "body"],
  bo: ["hands"]
}

const gamutTextures = {
  eyes: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8"],
  mouth: ["2-1", "2-2", "2-3", "2-4", "2-5", "2-6", "2-7", "2-8", "2-9", "2-10"],
  hands: ["2-3", "2-4", "2-9"],
  feet: ["2-1", "2-7"],
  body: ["2-8"]
}

const alternateTextures = {
  head: ["1-6", "1-7", "1-9"],
  hands: ["1-3", "1-4", "1-5", "1-6", "1-7", "1-8", "1-9", "1-10"],
  feet: ["1-2", "1-3", "1-4", "1-5", "1-6", "1-8", "1-9", "1-10"],
  body: ["1-2", "1-3", "1-4", "1-5", "1-6", "1-7", "1-8", "1-9", "1-10"]
}

const customMetadata = {
  bo: {
    head: {
      useTexture: false,
      customHairColor: true,
      color: new THREE.Vector4(...convertRGB([11, 10, 34]), 1),
      shadeColor: new THREE.Vector4(0, 0, 0, 1),
      rimColor: new THREE.Vector4(...convertRGB([27, 26, 46]), 1)
    }
  }
}

const AttributePicker = ({ attributeType, preset, environment, attributeMapping, series }) => {
  const [currentAttribute, setCurrentAttribute] = useState(undefined)
  const [attributeMetadata, setAttributeMetadata] = useState(undefined)
  const [loaders, setLoaders] = useState(undefined)
  const [alternate, setAlternate] = useState(false)
  const [currentAccessory, setCurrentAccessory] = useState()

  const currentMapping = attributeMapping[series][capitalize(attributeType)]

  var currentMetadata
  if (attributeType === "head") {
    currentMetadata = headAccessoryMetadata
  }
  else if (attributeType === "feet") {
    currentMetadata = feetAccessoryMetadata
  }
  else if (attributeType === "hands") {
    currentMetadata = handsAccessoryMetadata
  }
  else if (attributeType === "body") {
    currentMetadata = bodyAccessoryMetadata
  }
  else if (attributeType === "mouth") {
    currentMetadata = mouthAccessoryMetadata
  }
  else if (attributeType === "eyes") {
    currentMetadata = eyesAccessoryMetadata
  }

  const options = [
    ...Object.keys(currentMapping).map((id) => {
      return { 
        name: currentMapping[id].name.split(" ").join("-"), 
        label: currentMapping[id].name, 
        id: id 
      }
    })
  ]

  const attachAttribute = (attributeModel, key, type) => {
    if (type === "mouth") {
      removeMouth(environment.character)
    }
    else if (type === "eyes") {
      removeEyes(environment.character)
    }

    const skinMaterial = environment.character.getObjectByName("mesh_head").material
    const limbMaterial = environment.character.getObjectByName("mesh_arms").material
    const headMesh = environment.character.children.filter((child) => child.name === "mesh_head")[0]  
    const params = getAccessoryParams(
      environment.resources.items["gradientMap"], 
      environment.resources.items["colorGamut"], 
      environment.character.scale.x, 
      environment.resources.loaders, 
      headMesh.skeleton.bones[0], 
      headMesh.skeleton,
      skinMaterial,
      limbMaterial,
      environment.resources["toonMaterials"],
      [],
      key, 
      type,
      {}       
    )
    params.fbx = attributeModel
    params.metadata = attributeMetadata
    const altTextureKey = type.replace("arms", "body").replace("legs", "body")
    if (Object.keys(customTextures).includes(preset.name)) {
      let usingCustomMetadata = false
      if (
        customMetadata[preset.name] !== undefined && 
        customMetadata[preset.name][type] !== undefined
      ) {
        usingCustomMetadata = true
        params.metadata = { ...params.metadata, ...customMetadata[preset.name][type] }
      }
      if (!usingCustomMetadata && customTextures[preset.name].includes(attributeType)) {
        if (presetMappings[preset.name][type] === +key.split("-")[1]) {
          const textureFile = `${accessoryDirectory}${type}/${key}/${type}-${preset.name}--base-color.webp`
          params.baseColor = loaders.textureLoader.load(textureFile)
          params.baseColor.encoding = THREE.sRGBEncoding
        }
      }
    }
    else if (alternate && alternateTextures[altTextureKey].includes(key)) {
      const textureBase = `${accessoryDirectory}${altTextureKey}/${key}/${altTextureKey}-${key}`
      const textureFile = `${textureBase}--base-color${alternate ? "--alt" : ""}.webp`
      params.baseColor = loaders.textureLoader.load(textureFile)
      params.baseColor.encoding = THREE.sRGBEncoding
    }
    else if (gamutTextures[attributeType] && gamutTextures[attributeType].includes(key)) {
      params.baseColor = environment.resources.items["colorGamut"]
      params.baseColor.encoding = THREE.sRGBEncoding
    }
    
    params.anchorPoint = environment.character
    params.bindBool = true
    meshesToRemove[type].forEach((meshName) => {
      removeBodyMesh(environment.character, meshName)
    })
    setCurrentAccessory(new Accessory(params))
    if (!keepBodyParts.includes(type)) {
      environment.character.getObjectByName(`mesh_${type}`).visible = false
    }
  }

  const changeAttribute = (newAttribute) => {
    setCurrentAttribute(newAttribute)
    const specialAttribute = isNaN(+newAttribute.id)
    const key = `${attributeType}${specialAttribute ? "" : "-" + series}-${newAttribute.id}`
    setAttributeMetadata({...currentMetadata[key]})
  }

  const attachBareBodyPart = (type) => {
    meshesToRemove[type].forEach((meshName) => {
      removeBodyMesh(environment.character, meshName)
    })
    environment.character.getObjectByName(`mesh_${type}`).visible = true
  }

  const addLimb = (type, key) => {
    const metadata = type === "arms" ? armsAccessoryMetadata : legsAccessoryMetadata
    if (metadata[`${type}-${key}`] !== undefined) {
      loaders.fbxLoader.load(`${accessoryDirectory}${type}/${key}/${type}-${key}.fbx`, (fbx) => {
        attachAttribute(fbx, key, type)
      })
    }
    else {
      attachBareBodyPart(type)
    }
  }

  useEffect(() => {
    setCurrentAttribute(options[0])
    setLoaders({ textureLoader: new THREE.TextureLoader(), fbxLoader: new FBXLoader() })
  }, [])

  useEffect(() => {
    if (environment?.scene !== undefined) {
      if (attributeType === "head") {
        const parameters = { 
          color: 0xd99c86, 
          shadeColor: 0xb37964,
          rimColor: 0xf5c4b3,
        }

        const changeColor = (uniformKey) => {
          const hair = environment.scene.getObjectByName("hair")
          if (hair !== undefined) {
            const newColor = new THREE.Color(parameters[uniformKey])
            hair.material.uniforms[uniformKey].value = new THREE.Vector4(
              newColor.r, newColor.g, newColor.b, 1
            )
          }
        }
      
        const gui = new GUI({ autoPlace: true })
        gui.domElement.id = 'gui'
        const hairFolder = gui.addFolder("Hair Color")
        hairFolder.addColor(parameters, 'color').onChange(() => changeColor("color"))
        hairFolder.addColor(parameters, 'shadeColor').onChange(() => changeColor("shadeColor"))
        hairFolder.addColor(parameters, 'rimColor').onChange(() => changeColor("rimColor"))
      }
    }
  }, [environment])

  useEffect(() => {
    if (preset.name !== "none") {
      var presetAttribute = options.filter((option) => option.id === preset.name)[0]
      if (presetAttribute !== undefined) {
        setCurrentAttribute(presetAttribute)
        const key = `${attributeType}-${preset.name}`
        setAttributeMetadata({...currentMetadata[key]})
      }
      else {
        if (presetMappings[preset.name] !== undefined) {
          presetAttribute = options.filter((option) => {
            return option.id === presetMappings[preset.name][attributeType].toString()
          })[0]
          setCurrentAttribute(presetAttribute)
          const specialAttribute = isNaN(+presetAttribute.id)
          const key = `${attributeType}${specialAttribute ? "" : "-" + series}-${presetAttribute.id}`
          setAttributeMetadata(currentMetadata[key])
        }
      }
    }
  }, [preset])

  useEffect(() => {
    if (attributeMetadata !== undefined && loaders !== undefined) {
      const specialAttribute = isNaN(+currentAttribute.id)
      const key = specialAttribute ? currentAttribute.id : `${series}-${currentAttribute.id}`
      if (currentAttribute.label.includes("Bare ")) {
        attachBareBodyPart(attributeType)
      }
      else {
        const modelFile = `${accessoryDirectory}${attributeType}/${key}/${attributeType}-${key}.fbx`
        loaders.fbxLoader.load(modelFile, (fbx) => {
          // if (`${attributeType}-${key}` === "head-2-4") {
          //   fbx = fbx.children[1]
          // }
          attachAttribute(fbx, key, attributeType)
          if (attributeType === "body") {
            addLimb("arms", key)
            addLimb("legs", key)
          }
        })
      }
    }
  }, [attributeMetadata, loaders])

  useEffect(() => {
    if (currentAccessory) {
      const key = `${series}-${currentAttribute.id}`
      if (alternateTextures[attributeType].includes(key)) {
        changeAttribute({...currentAttribute})
      }
    }
  }, [alternate])

  return (
    <>
      {
        currentAttribute !== undefined &&
        <>
          {
            attributeType !== "mouth" && attributeType !== "eyes" &&
            <div
              className={`attribute-picker__alternate${alternate ? " selected" : ""}`} 
              id={`attribute-picker__alternate--${attributeType}`}>
              <p>Use Alternate</p>
              <div onClick={() => setAlternate((prevState) => !prevState)}/>
            </div>
          }
          <Dropdown
            containerId={`attribute-picker__container--${attributeType}`}
            dropdownId={`attribute-picker-dropdown--${attributeType}`}
            selection={currentAttribute}
            clickFunction={changeAttribute}
            options={options}
            positionStyling={{}} />
        </>
      }
    </>
  )
}

export default AttributePicker
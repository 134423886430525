const checkMappings = (modelSpecs) => {
  const featuresUsed = modelSpecs.features_used
  const featuresMultiple = modelSpecs.rotation_included ? 9 : 3
  var correctFormat = featuresUsed !== undefined
  if (correctFormat) {
    const totalFeatures = featuresUsed.length * featuresMultiple
    correctFormat = totalFeatures === modelSpecs.n_features
  }
  return correctFormat
}

const checkModelSpecs = (modelSpecs) => {
  const useBias = (
    modelSpecs.biases !== undefined &&
    modelSpecs.biases.length > 0
  )

  var inDim
  var outDim
  var correctFormat = true
  if (modelSpecs.weights !== undefined) {
    for (var l = 0; l < modelSpecs.weights.length; l++) {
      if (l === 0) {
        inDim = modelSpecs.n_features
        outDim = modelSpecs.neurons[l]
      }
      else if (l === modelSpecs.weights.length - 1) {
        inDim = modelSpecs.neurons[l-1]
        outDim = modelSpecs.n_targets
      }
      else {
        inDim = modelSpecs.neurons[l-1]
        outDim = modelSpecs.neurons[l]
      }
  
      if (modelSpecs.weights[l].length !== inDim) correctFormat = false
      else if (modelSpecs.weights[l][0].length !== outDim) correctFormat = false
  
      if (useBias) {
        if (modelSpecs.biases[l].length !== 1) correctFormat = false
        else if (modelSpecs.biases[l][0].length !== outDim) correctFormat = false  
      }
    }
  }
  else {
    correctFormat = false
  }

  return [correctFormat, useBias]
}

const checkValidModel = (files, setModelSpecs) => {
  // if (files.length === 1) {
  for (var i = 0; i < files.length; i++) {
    if (files[i].type === "application/json") {
      const fileReader = new FileReader()
      fileReader.readAsText(files[i])

      fileReader.onload = function () {
        const proposedModelSpecs = JSON.parse(fileReader.result)
        const correctMappings = checkMappings(proposedModelSpecs)
        if (correctMappings) {
          const correctFormat = checkModelSpecs(proposedModelSpecs)
          if (correctFormat) {
            setModelSpecs((prevState) => { return [...prevState, proposedModelSpecs] })
          }
          else {
            alert("Your model specification is not in the correct format!")
          }
        }
        else {
          alert("Your mappings are not correctly specified!")
        }
      }
    }
    else {
      alert("You are only allowed to upload application/json files!")
    }
  }
  // else {
  //   alert("You are only allowed to upload 1 file at a time!")
  // }  
}

export {
  checkValidModel
}

const animationsDirectory = "animations/"
const oldAnimationsDirectory = "animations-original/"
const animationExtension = ".fbx"

const animationsMapping = {
  "idle": animationsDirectory + "idle" + animationExtension,
  "back-aerial-kick": animationsDirectory + "back-aerial-kick" + animationExtension,
  "captured-start": animationsDirectory + "captured-start" + animationExtension,
  "captured": animationsDirectory + "captured" + animationExtension,
  "crouch": animationsDirectory + "crouch" + animationExtension,
  "crouch-start": animationsDirectory + "crouch-start" + animationExtension,
  "crouch-hold": animationsDirectory + "crouch-hold" + animationExtension,
  "crouch-end": animationsDirectory + "crouch-end" + animationExtension,
  "double-punch": animationsDirectory + "double-punch" + animationExtension,
  "electric-special": animationsDirectory + "electric-special" + animationExtension,
  "fall-down-sweep": animationsDirectory + "fall-down-sweep" + animationExtension,
  "fall-on-face": animationsDirectory + "fall-on-face" + animationExtension,
  "fall": animationsDirectory + "fall" + animationExtension,
  "fire-special": animationsDirectory + "fire-special" + animationExtension,
  "flip-kick": animationsDirectory + "flip-kick" + animationExtension,
  "flying-kick": animationsDirectory + "flying-kick" + animationExtension,
  "front-kick": animationsDirectory + "front-kick" + animationExtension,
  "get-up-back": animationsDirectory + "get-up-back" + animationExtension,
  "get-up-front": animationsDirectory + "get-up-front" + animationExtension,
  "grab-attack": animationsDirectory + "grab-attack" + animationExtension,
  "grab-end": animationsDirectory + "grab-end" + animationExtension,
  "grab-hold": animationsDirectory + "grab-hold" + animationExtension,
  "grab-start": animationsDirectory + "grab-start" + animationExtension,
  "grabbed": animationsDirectory + "grabbed" + animationExtension,
  "headbutt-charge": animationsDirectory + "headbutt-charge" + animationExtension,
  "headbutt-end": animationsDirectory + "headbutt-end" + animationExtension,
  "headbutt-start": animationsDirectory + "headbutt-start" + animationExtension,
  "headbutt": animationsDirectory + "headbutt" + animationExtension,
  "hit-light": animationsDirectory + "hit-light" + animationExtension,  
  "jump-aerial": animationsDirectory + "jump-aerial" + animationExtension,
  "jump-back-aerial": animationsDirectory + "jump-back-aerial" + animationExtension,
  "jump-fall": animationsDirectory + "jump-fall" + animationExtension,
  "jump-land": animationsDirectory + "jump-land" + animationExtension,
  "jump-start": animationsDirectory + "jump-start" + animationExtension,
  "jump-windup": animationsDirectory + "jump-windup" + animationExtension,
  "kick-aerial": animationsDirectory + "kick-aerial" + animationExtension,
  "knockback-heavy": animationsDirectory + "knockback-heavy" + animationExtension,
  "knockback-light": animationsDirectory + "knockback-light" + animationExtension,
  "knockback-spin": animationsDirectory + "knockback-spin" + animationExtension,
  "ledge-catch": animationsDirectory + "ledge-catch" + animationExtension,
  "ledge-climb": animationsDirectory + "ledge-climb" + animationExtension,
  "ledge-hang": animationsDirectory + "ledge-hang" + animationExtension,
  "low-sweep": animationsDirectory + "low-sweep" + animationExtension,
  "low-sweep-from-crouch": animationsDirectory + "low-sweep-from-crouch" + animationExtension,
  "punch-aerial": animationsDirectory + "punch-aerial" + animationExtension,
  "punch-down-aerial-start": animationsDirectory + "punch-down-aerial-start" + animationExtension,
  "punch-down-aerial-hold": animationsDirectory + "punch-down-aerial-hold" + animationExtension,
  "punch-down-aerial-end": animationsDirectory + "punch-down-aerial-end" + animationExtension,
  "punch": animationsDirectory + "punch" + animationExtension,
  "roll": animationsDirectory + "roll" + animationExtension,
  "run": animationsDirectory + "run" + animationExtension,
  "shield-break": animationsDirectory + "shield-break" + animationExtension,
  "shield-hold": animationsDirectory + "shield-hold" + animationExtension,
  "shield-start": animationsDirectory + "shield-start" + animationExtension,
  "stand-still": animationsDirectory + "stand-still" + animationExtension,
  "throw-back": animationsDirectory + "throw-back" + animationExtension,
  "throw-down": animationsDirectory + "throw-down" + animationExtension,
  "throw-forward": animationsDirectory + "throw-forward" + animationExtension,
  "throw-up": animationsDirectory + "throw-up" + animationExtension,
  "thrown-down-end": animationsDirectory + "thrown-down-end" + animationExtension,
  "thrown-down-start": animationsDirectory + "thrown-down-start" + animationExtension,
  "thrown-down": animationsDirectory + "thrown-down" + animationExtension,
  "uppercut-aerial": animationsDirectory + "uppercut-aerial" + animationExtension,
  "uppercut-ground": animationsDirectory + "uppercut-ground" + animationExtension,
  "wall-hang": animationsDirectory + "wall-hang" + animationExtension,
  "wall-jump": animationsDirectory + "wall-jump" + animationExtension,
  "water-special": animationsDirectory + "water-special" + animationExtension,
  /*"idle": animationsDirectory + "idle" + animationExtension,
  "run": animationsDirectory + "run" + animationExtension,

  // "raul-animation": animationsDirectory + "raul-animation" + animationExtension,

  "jump-windup": animationsDirectory + "jump-windup" + animationExtension,
  "jump-start": animationsDirectory + "jump-start" + animationExtension,
  "jump-fall": animationsDirectory + "jump-fall" + animationExtension,
  "jump-land": animationsDirectory + "jump-land" + animationExtension,
  "jump-aerial": animationsDirectory + "jump-aerial" + animationExtension,
  
  
  "fall": animationsDirectory + "fall" + animationExtension,
  "roll": animationsDirectory + "roll" + animationExtension,
  "wall-hang": animationsDirectory + "wall-hang" + animationExtension,
  "wall-jump": animationsDirectory + "wall-jump" + animationExtension,

  "ledge-catch": animationsDirectory + "ledge-catch" + animationExtension,
  "ledge-hang": animationsDirectory + "ledge-hang" + animationExtension,
  "ledge-climb": animationsDirectory + "ledge-climb" + animationExtension,

  "punch": animationsDirectory + "punch" + animationExtension,
  "double-punch": animationsDirectory + "double-punch" + animationExtension,
  "punch-aerial": animationsDirectory + "punch-aerial" + animationExtension,
  "punch-down-aerial": animationsDirectory + "punch-down-aerial" + animationExtension,

  "headbutt": animationsDirectory + "headbutt" + animationExtension,
  "headbutt-start": animationsDirectory + "headbutt-start" + animationExtension,
  "headbutt-charge": animationsDirectory + "headbutt-charge" + animationExtension,
  "headbutt-end": animationsDirectory + "headbutt-end" + animationExtension,

  "front-kick": animationsDirectory + "front-kick" + animationExtension,
  "flip-kick": animationsDirectory + "flip-kick" + animationExtension,
  "flying-kick": animationsDirectory + "flying-kick" + animationExtension,
  "kick-aerial": animationsDirectory + "kick-aerial" + animationExtension,
  "low-sweep": animationsDirectory + "low-sweep" + animationExtension,
  "uppercut-ground": animationsDirectory + "uppercut-ground" + animationExtension,
  "uppercut-aerial": animationsDirectory + "uppercut-aerial" + animationExtension,

  "hit-light": animationsDirectory + "hit-light" + animationExtension,
  "knockback-heavy": animationsDirectory + "knockback-heavy" + animationExtension,
  "knockback-light": animationsDirectory + "knockback-light" + animationExtension,
  "knockback-spin": animationsDirectory + "knockback-spin" + animationExtension,
  "fall-down-sweep": animationsDirectory + "fall-down-sweep" + animationExtension,
  "fall-on-face": animationsDirectory + "fall-on-face" + animationExtension,
  "get-up-back": animationsDirectory + "get-up-back" + animationExtension,
  "get-up-front": animationsDirectory + "get-up-front" + animationExtension,
    
  "shield-start": animationsDirectory + "shield-start" + animationExtension,
  "shield-hold": animationsDirectory + "shield-hold" + animationExtension,
  "shield-break": animationsDirectory + "shield-break" + animationExtension,

  "grab-start": animationsDirectory + "grab-start" + animationExtension,
  "grab-hold": animationsDirectory + "grab-hold" + animationExtension,
  "grab-end": animationsDirectory + "grab-end" + animationExtension,
  "grab-attack": animationsDirectory + "grab-attack" + animationExtension,

  "captured-start": animationsDirectory + "captured-start" + animationExtension,
  "captured": animationsDirectory + "captured" + animationExtension,

  "throw-forward": animationsDirectory + "throw-forward" + animationExtension,
  "throw-up": animationsDirectory + "throw-up" + animationExtension,  
  "throw-down": animationsDirectory + "throw-down" + animationExtension,  

  "thrown-down-start": animationsDirectory + "thrown-down-start" + animationExtension,  
  "thrown-down-end": animationsDirectory + "thrown-down-end" + animationExtension,

  "electric-special": animationsDirectory + "electric-special" + animationExtension,
  "fire-special": animationsDirectory + "fire-special" + animationExtension,
  "water-special": animationsDirectory + "water-special" + animationExtension,*/

  // "uppercut-aerial-polish": animationsDirectory + "uppercut-aerial-polish" + animationExtension,
  // "uppercut-aerial-to-fall": animationsDirectory + "uppercut-aerial-to-fall" + animationExtension,
}

export {
  animationsMapping
}
import * as THREE from "three"

export default class Renderer {
  constructor(canvas, sizes, scene, camera, params) {
    this.canvas = canvas
    this.sizes = sizes
    this.scene = scene
    this.camera = camera
    this.drawBool = params.drawBool

    this.allMeshes = []
    this.materials = {}
    this.darkMaterial = new THREE.MeshBasicMaterial({ color: "black" })

    this.setInstance()
  }

  setInstance() {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      preserveDrawingBuffer: this.drawBool,
    })
    this.instance.outputEncoding = THREE.sRGBEncoding
    this.instance.shadowMap.enabled = true
    this.instance.shadowMap.type = THREE.PCFSoftShadowMap
    this.instance.setClearColor(0x000000, 0.0)
    this.instance.setSize(this.sizes.width, this.sizes.height, false)
    // this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    this.instance.setPixelRatio(2)
    this.instance.autoClear = this.drawBool

    // const [bloomComposer, finalComposer] = setUpBloom(
    //   this.instance,
    //   this.scene,
    //   this.camera.instance
    // )

    // this.bloomComposer = bloomComposer
    // this.finalComposer = finalComposer
  }

  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height, false)
    // this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
    this.instance.setPixelRatio(2)
  }

  update() {
    this.instance.render(this.scene, this.camera.instance)
  }
}

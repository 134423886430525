import { useEffect } from "react"
import CollisionMakerScene from "../../collision-maker/collision-maker-scene"
import { 
  animationDataAtom, 
  animationReadyAtom, 
  cameraAtom, 
  environmentAtom, 
  manipulationAtom, 
  mouseTrackerAtom, 
  sceneAtom 
} from "../../collision-maker/atoms"
import { Interaction } from "../../threejs/Interaction/src"
import { useAtom } from "jotai"

const useCollisionMaker = () => {
  const setAnimationsReady = useAtom(animationReadyAtom)[1]
  const setAnimationData = useAtom(animationDataAtom)[1]
  const setManipulationType = useAtom(manipulationAtom)[1]
  const setEnvironment = useAtom(environmentAtom)[1]
  const setScene = useAtom(sceneAtom)[1]
  const setCamera = useAtom(cameraAtom)[1]
  const setMouseTracker = useAtom(mouseTrackerAtom)[1]

  const handleKeydown = (e) => {
    const newNameInput = document.getElementById("collider-name-input")
    if (newNameInput === null || newNameInput !== document.activeElement) {
      if (e.key === "s") {
        setManipulationType((prevState) => {
          return prevState !== "Size" ? "Size" : undefined
        })
      }
      else if (e.key === "r") {
        setManipulationType((prevState) => {
          return prevState !== "Rotate" ? "Rotate" : undefined
        })
      }
      else if (e.key === "g") {
        setManipulationType((prevState) => {
          return prevState !== "Grab" ? "Grab" : undefined
        })
      }
      else if (e.key === "x") {
        setManipulationType("Delete")
      }
      else if (e.key === "c") {
        setManipulationType((prevState) => {
          return prevState !== "Copy" ? "Copy" : undefined
        })
      }
    }
  }

  useEffect(() => {
    const env = new CollisionMakerScene(setAnimationsReady, setAnimationData, setMouseTracker)
    new Interaction(env.threejs.instance, env.scene, env.camera.instance)
    setEnvironment(env)
    setScene(env.scene)
    setCamera(env.camera.instance)    
    window.addEventListener("keydown", handleKeydown)    
    return () => {
      env.threejs.instance.domElement.remove()
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [])
}

export {
  useCollisionMaker
}
import { useEffect } from "react"
import { changeHurtboxLink } from "../../collision-maker/bone-operations"
import { 
  animationAtom, 
  boneSelectedAtom, 
  colliderSelectedAtom, 
  fighterTypeAtom, 
  hurtboxLinksAtom, 
  sceneAtom,
} from "../../collision-maker/atoms"
import { useAtom } from "jotai"

const useBoneLinking = () => {
  const scene = useAtom(sceneAtom)[0]
  const animation = useAtom(animationAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const boneSelected = useAtom(boneSelectedAtom)[0]
  const colliderSelected = useAtom(colliderSelectedAtom)[0]
  const setHurtboxLinks = useAtom(hurtboxLinksAtom)[1]

  useEffect(() => {
    if (
      colliderSelected !== undefined && 
      colliderSelected.name !== "" && 
      boneSelected !== undefined && 
      boneSelected.name !== "none" && 
      animation !== undefined
    ) {
      if (animation.name === "a-pose") {
        changeHurtboxLink(boneSelected, colliderSelected, fighterType, setHurtboxLinks, scene)
      }
    }
  }, [boneSelected, animation])
}

export {
  useBoneLinking
}
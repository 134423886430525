import React, { useState, useEffect } from 'react'
import AnimationMenu from '../animation-menu/AnimationMenu'
import FramesMenu from '../frames-menu/FramesMenu'
import VFXBuilderScene from '../../../helpers/vfx-builder/vfx-builder-scene'
import './vfx-builder.css'

const VFXBuilder = () => {
  const [animation, setAnimation] = useState(undefined)
  const [animationData, setAnimationData] = useState(undefined)
  const [animationsReady, setAnimationsReady] = useState(false)
  const [totalFrames, setTotalFrames] = useState(0)
  const [animationFrame, setAnimationFrame] = useState(0)
  const [environment, setEnvironment] = useState(undefined)

  const handleKeydown = (e) => {
    if (e.key === "ArrowRight") {
      setAnimationFrame((prevState) => {
        return Math.min(prevState + 1, totalFrames - 1)
      })
    }
    else if (e.key === "ArrowLeft") {
      setAnimationFrame((prevState) => {
        return Math.max(prevState - 1, 0)
      })
    }
  }

  useEffect(() => {
    const env = new VFXBuilderScene(setAnimationsReady, setAnimationData)
    setEnvironment(env)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown)
    return () => {
      window.removeEventListener("keydown", handleKeydown)
    }
  }, [totalFrames])

  const saveImage = () => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'image.png');
    let canvas = document.getElementById('canvas');
    canvas.toBlob(function(blob) {
      let url = URL.createObjectURL(blob);
      downloadLink.setAttribute('href', url);
      downloadLink.click();
    });
  }

  return (
    <>
      <div id="vfx-builder__container">
        <div id="vfx-builder__menu">
          <AnimationMenu
            animation={animation}
            setAnimation={setAnimation}
            setTotalFrames={setTotalFrames}
            animationFrame={animationFrame}
            setAnimationFrame={setAnimationFrame}
            animationsReady={animationsReady}
            animationData={animationData}>
          </AnimationMenu>
          <FramesMenu 
            animationData={animationData}
            totalFrames={totalFrames}
            animationFrame={animationFrame}
            setAnimationFrame={setAnimationFrame}
            animation={animation}>
          </FramesMenu> 
          {
            animationData === undefined &&
            <p id="vfx-builder__loading-text">LOADING ANIMATIONS</p>
          }
        </div>
        <div id="vfx-builder">
          <button onClick={() => saveImage()}>SAVE FRAME</button>
        </div>
      </div>
    </>
  )
}

export default VFXBuilder
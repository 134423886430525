import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"

const baseUrl = "https://ai-arena.b-cdn.net/"

const fighterLocations = [
  "fighterCollisionMaker",
]

const textures = [
  {
    name: "gradientMap",
    type: "texture",
    tags: fighterLocations,
    path: gradientMapFile,
  },
  {
    name: "colorGamut",
    type: "texture",
    tags: fighterLocations,
    path: baseUrl + "shared/color-gamut-1.webp"
  },  
  {
    name: "crownTexture",
    type: "texture",
    tags: fighterLocations,
    path: "accessories/additional/crown/additional-crown--base-color.webp",
  },
]

export default textures
import * as THREE from 'three'

const getGlobalBonePosition = (scene, name, bone) => {
  var target = new THREE.Vector3()
  bone = bone ?? scene.getObjectByName(name)
  if (bone !== undefined) {
    bone.getWorldPosition(target)
    return target
  }
}

const getGlobalBoneRotation = (scene, name, bone) => {
  var quaternion = new THREE.Quaternion()
  bone = bone ?? scene.getObjectByName(name)
  if (bone !== undefined) {
    bone.getWorldQuaternion(quaternion)
    let rotation = new THREE.Euler()
    rotation.setFromQuaternion(quaternion)
    return rotation
  }
}

const getRigData = (armature) => {
  var position
  var rotation
  const rigData = {}
  armature.traverse((bone) => {
    if (
      rigData[bone.name] === undefined && 
      bone.name !== "Armature" &&
      !bone.name.includes("accessory")
    ) {
      position = getGlobalBonePosition(undefined, bone.name, bone)
      rotation = getGlobalBoneRotation(undefined, bone.name, bone)
      rigData[bone.name] = {
        position: position,
        rotation: rotation
      }
    }
  })
  return rigData
}

const changeHurtboxLink = (boneSelected, colliderSelected, fighterType, setHurtboxLinks, scene) => {
  setHurtboxLinks((prevState) => {
    const bonePosition = getGlobalBonePosition(scene, boneSelected.name)
    const boneRotation = getGlobalBoneRotation(scene, boneSelected.name)
    prevState[fighterType][colliderSelected.name] = {
      name: boneSelected.name,
      shape: colliderSelected.geometry.type.replace("Geometry", ""),
      scale: { 
        x: colliderSelected.scale.x, 
        y: colliderSelected.scale.y, 
        z: colliderSelected.scale.z
      },
      positionOffset: { 
        x: colliderSelected.position.x - bonePosition.x, 
        y: colliderSelected.position.y - bonePosition.y, 
        z: colliderSelected.position.z - bonePosition.z 
      },
      // I'm not sure if the rotation works well
      startingRotation: {
        x: colliderSelected.rotation.x - boneRotation.x,
        y: colliderSelected.rotation.y - boneRotation.y,
        z: colliderSelected.rotation.z - boneRotation.z,
      }
    }
    return {...prevState}
  })
}

const moveHurtboxWithBone = (boneData, currentColliderName, scene) => {
  const bonePosition = getGlobalBonePosition(scene, boneData.name)
  const boneRotation = getGlobalBoneRotation(scene, boneData.name)
  if (bonePosition !== undefined && boneRotation !== undefined) {
    const currentHurtbox = scene.getObjectByName(currentColliderName)
    currentHurtbox.position.set(
      bonePosition.x + boneData.positionOffset.x, 
      bonePosition.y + boneData.positionOffset.y, 
      bonePosition.z + boneData.positionOffset.z
    )
    currentHurtbox.rotation.set(
      boneRotation.x + boneData.startingRotation.x, 
      boneRotation.y + boneData.startingRotation.y, 
      boneRotation.z + boneData.startingRotation.z
    )
    currentHurtbox.scale.setScalar(boneData.scale.x)
  }
}

export {
  getRigData,
  changeHurtboxLink,
  moveHurtboxWithBone
}
import { 
  fillVertexPrepend,
  fillVertexContent
 } from '../fill/fill-vertex-shader'
import * as THREE from 'three'

const basicVertexShader = `
  varying vec2 vUv;

  varying vec3 N;
  varying vec3 I;

  ${THREE.ShaderChunk.skinning_pars_vertex}
  ${fillVertexPrepend}

  void main(){
    vUv = uv;

    mat4 modelViewProjectionMatrix = projectionMatrix * modelViewMatrix;

    ${THREE.ShaderChunk.begin_vertex}
    ${THREE.ShaderChunk.beginnormal_vertex}
    ${THREE.ShaderChunk.skinbase_vertex}
    ${THREE.ShaderChunk.skinnormal_vertex}

    ${THREE.ShaderChunk.skinning_vertex}

    ${fillVertexContent}

    //Transform vertex by modelview and projection matrices
    gl_Position = modelViewProjectionMatrix * vec4( transformed, 1.0 );

    // Normal transform (transposed model-view inverse)
    N = normalMatrix * objectNormal;

    // Incident vector
    I = vec3(modelViewMatrix * vec4(transformed, 1.0));
  }
`

export {
  basicVertexShader
}
const stageNames = ["fighterCollisionMaker"]

const animations = [
  {
    name: "idle",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/idle.fbx",
  },
  {
    name: "run",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/run.fbx",
  },
  {
    name: "crouchStart",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/crouch-start.fbx",
  },
  {
    name: "crouchHold",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/crouch-hold.fbx",
  },
  {
    name: "crouchEnd",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/crouch-end.fbx",
  },
  {
    name: "punch",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/punch.fbx",
  },
  {
    name: "doublePunch",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/double-punch.fbx",
  },
  {
    name: "fall",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/fall.fbx",
  },
  {
    name: "roll",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/roll.fbx",
  },
  {
    name: "frontKick",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/front-kick.fbx",
  },
  {
    name: "uppercutGround",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/uppercut-ground.fbx",
  },
  {
    name: "lowSweep",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/low-sweep.fbx",
  },
  {
    name: "lowSweepFromCrouch",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/low-sweep-from-crouch.fbx",
  },
  {
    name: "punchAerial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/punch-aerial.fbx",
  },
  // {
  //   name: "punchDownAerial",
  //   type: "fbxModel",
  //   tags: stageNames,
  //   path: "animations/punch-down-aerial.fbx",
  // },
  {
    name: "uppercutAerial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/uppercut-aerial.fbx",
  },
  {
    name: "wallHang",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/wall-hang.fbx",
  },
  {
    name: "flyingKick",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/flying-kick.fbx",
  },
  {
    name: "kickAerial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/kick-aerial.fbx",
  },
  {
    name: "ledgeCatch",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/ledge-catch.fbx",
  },
  {
    name: "ledgeHang",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/ledge-hang.fbx",
  },
  {
    name: "ledgeClimb",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/ledge-climb.fbx",
  },
  {
    name: "grabStart",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/grab-start.fbx",
  },
  {
    name: "grabEnd",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/grab-end.fbx",
  },
  {
    name: "capturedStart",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/captured-start.fbx",
  },
  {
    name: "captured",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/captured.fbx",
  },
  {
    name: "grabHold",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/grab-hold.fbx",
  },
  {
    name: "throwUp",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/throw-up.fbx",
  },
  {
    name: "throwDown",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/throw-down.fbx",
  },
  {
    name: "throwForward",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/throw-forward.fbx",
  },
  {
    name: "throwBack",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/throw-back.fbx",
  },
  {
    name: "hitLight",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/hit-light.fbx",
  },
  {
    name: "hitLight2",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/hit-light-2.fbx",
  },
  {
    name: "hitLight3",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/hit-light-3.fbx",
  },
  {
    name: "knockbackHeavy",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/knockback-heavy.fbx",
  },
  {
    name: "knockbackLight",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/knockback-light.fbx",
  },
  {
    name: "knockbackSpin",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/knockback-spin.fbx",
  },
  {
    name: "fallOnFace",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/fall-on-face.fbx",
  },
  {
    name: "getUpFront",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/get-up-front.fbx",
  },
  {
    name: "shieldStart",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/shield-start.fbx",
  },
  {
    name: "shieldHold",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/shield-hold.fbx",
  },
  {
    name: "shieldBreak",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/shield-break.fbx",
  },
  {
    name: "waterSpecial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/water-special.fbx",
  },
  {
    name: "electricSpecial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/electric-special.fbx",
  },
  {
    name: "fireSpecial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/fire-special.fbx",
  },
  {
    name: "jumpWindup",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-windup.fbx",
  },
  {
    name: "jumpAerial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-aerial.fbx",
  },
  {
    name: "jumpBackAerial",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-back-aerial.fbx",
  },
  {
    name: "jump",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-start.fbx"
  },
  {
    name: "jumpStart",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-start.fbx"
  },
  {
    name: "jumpFall",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-fall.fbx",
  },
  {
    name: "fallOffPlatform",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-fall.fbx",
  },
  {
    name: "jumpLand",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/jump-land.fbx"
  },
  {
    name: "wallJump",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/wall-jump.fbx"
  },
  {
    name: "headbuttStart",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/headbutt-start.fbx"
  },
  {
    name: "headbuttCharge",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/headbutt-charge.fbx"
  },
  {
    name: "headbuttEnd",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/headbutt-end.fbx"
  },
  {
    name: "grabAttack",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/grab-attack.fbx"
  }, 
  {
    name: "flipKick",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/flip-kick.fbx"
  },
  {
    name: "backAerialKick",
    type: "fbxModel",
    tags: stageNames,
    path: "animations/back-aerial-kick.fbx"
  }
]

export default animations

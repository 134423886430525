import { inplacePositioning, convertRGB, convertHex } from './default-metadata'
import * as THREE from 'three'

const season1 = {
  "head-1-1": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#201759", true),
    shadeColor: convertHex("#0d072f", true),
    rimColor: convertHex("#3f3a96", true)
  },
  "head-1-2": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#d46679", true),
    shadeColor: convertHex("#af485a", true),
    rimColor: convertHex("#ffaaaa", true)
  },
  "head-1-3": {
    ...inplacePositioning, 
    customHairColor: true, 
    useTexture: false,
    color: convertHex("#160d0d", true),
    shadeColor: convertHex("#000000", true),
    rimColor: convertHex("#363655", true)
  },
  "head-1-4": {
    ...inplacePositioning, 
    customHairColor: true, 
    useTexture: false,
    color: convertHex("#e165b2", true),
    shadeColor: convertHex("#bb498c", true),
    rimColor: convertHex("#ffa7dc", true)
  },
  "head-1-5": {...inplacePositioning},
  "head-1-6": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#f7db88", true),
    shadeColor: convertHex("#aa8f41", true),
    rimColor: convertHex("#ffe8b6", true)
  },
  "head-1-7": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#d9643b", true),
    shadeColor: convertHex("#b14822", true),
    rimColor: convertHex("#e67f55", true)
  },
  "head-1-8": {
    ...inplacePositioning, 
    customHairColor: 
    true, 
    useTexture: false,
    color: convertHex("#ffffff", true),
    shadeColor: convertHex("#c3c3c3", true),
    rimColor: convertHex("#ffffff", true)
  },
  "head-1-9": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#180b03", true),
    shadeColor: convertHex("#090600", true),
    rimColor: convertHex("#37180a", true)
  },
  "head-1-10": {...inplacePositioning},
  "head-artist": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#20100f", true),
    shadeColor: convertHex("#0f0b09", true),
    rimColor: convertHex("#281a14", true)
  },
  "head-beta": {
    ...inplacePositioning, 
    customHairColor: true,
    color: convertHex("#d77424", true),
    shadeColor: convertHex("#ac5812", true),
    rimColor: convertHex("#f2ac74", true)
  },
  "head-gwei": {
    ...inplacePositioning, 
    customHairColor: true,
    useTexture: false,
    color: new THREE.Vector4(0.1, 0.1, 0.1, 1),
    shadeColor: new THREE.Vector4(0, 0, 0, 1),
    rimColor: new THREE.Vector4(0.2, 0.2, 0.2, 1)
  },
  "head-guppy": {
    ...inplacePositioning, 
    customHairColor: true,
    useTexture: false,
    color: new THREE.Vector4(0.15, 0.075, 0.00138, 1),
    shadeColor: new THREE.Vector4(0.09, 0.04, 0, 1),
    rimColor: new THREE.Vector4(0.25, 0.125, 0.002, 1)
  },
  "head-bo": {
    ...inplacePositioning,
    customHairColor: true,
    useTexture: false,
    color: new THREE.Vector4(...convertRGB([11, 10, 34]), 1),
    shadeColor: new THREE.Vector4(0, 0, 0, 1),
    rimColor: new THREE.Vector4(...convertRGB([27, 26, 46]), 1)
  }
}

const season2 = {
  "head-2-1": {...inplacePositioning},
  "head-2-2": {...inplacePositioning},
  "head-2-3": {...inplacePositioning},
  "head-2-4": {...inplacePositioning},
  "head-2-5": {...inplacePositioning},
  "head-2-6": {...inplacePositioning},
  "head-2-7": {...inplacePositioning},
  "head-2-8": {...inplacePositioning},
  "head-2-9": {...inplacePositioning},
  "head-2-10": {...inplacePositioning},
}

const headAccessoryMetadata = {
  ...season1,
  ...season2
}

export default headAccessoryMetadata
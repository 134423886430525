import { inplacePositioning } from './default-metadata'

const season1 = {
  "legs-1-3": {...inplacePositioning},
  "legs-1-4": {...inplacePositioning},
  "legs-1-8": {...inplacePositioning},
  "legs-1-9": {...inplacePositioning},
  "legs-artist": {...inplacePositioning},
  "legs-gwei": {...inplacePositioning},
  "legs-guppy": {...inplacePositioning},
}

const season2 = {
  "legs-2-1": {...inplacePositioning},
  "legs-2-2": {...inplacePositioning},
  "legs-2-3": {...inplacePositioning},
  "legs-2-4": {...inplacePositioning},
  "legs-2-5": {...inplacePositioning},
  "legs-2-6": {...inplacePositioning},
  "legs-2-7": {...inplacePositioning},
  "legs-2-9": {...inplacePositioning},
  "legs-2-10": {...inplacePositioning}
}

const legsAccessoryMetadata = {
  ...season1,
  ...season2
}

export default legsAccessoryMetadata
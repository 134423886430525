import React from 'react'
import './loading-screen.css'

const LoadingScreen = () => {
  return (
    <div id="loading-screen__container">
      Loading...
    </div>
  )
}

export default LoadingScreen
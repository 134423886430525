import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import createScene from "../../helpers/threejs/scene-setup"
import { updateToonEdge, updateToonShaderLight } from '../../helpers/threejs/toon-rendering'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"
import skinColorMetadata from "../asset-management/fighter-accessories/metadata/skin"
import fighterModifier from "../model-modifiers/characters/fighter-modifier"
import Resources from "../../helpers/resource-management/resources"
import { animationsMapping } from "../collision-maker/animation-mapping"
import * as THREE from "three"

const baseUrl = "https://ai-arena.b-cdn.net/"

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

export default class VFXBuilderScene {
  constructor(setAnimationsReady, setAnimationData) {
    this.initialize(setAnimationsReady, setAnimationData)
  }

  startAnimationAtFrameZero(clip) {
    let duration = 0
    clip.tracks.forEach((track) => {
      const newTimes = new Float32Array(track.times.length)
      newTimes[0] = 0
      newTimes.set(track.times.slice(0, track.times.length - 1), 1)
      track.times = newTimes
      if (newTimes[newTimes.length - 1] > duration) {
        duration = newTimes[newTimes.length - 1]
      }
    })
    clip.duration = duration
  }

  initialize(setAnimationsReady, setAnimationData) {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "vfx-builder",
      camera: { fov: 25, near: 0.1, far: 1000 },
      drawBool: true
    })

    this.targetFPS = 30
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 7)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0.78, 0.01, 1.66)
    this.scene.add(this.directionalLight)

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
    this.scene.add(this.ambientLight)

    const additionalSources = [
      {
        name: "fighter",
        type: "fbxModel",
        tags: ["vfxBuilder"],
        path: fighterBodyFile,
      },
      {
        name: "gradientMap",
        type: "texture",
        tags: ["vfxBuilder"],
        path: gradientMapFile,
      },
      {
        name: "colorGamut",
        type: "texture",
        tags: ["dressingRoom"],
        path: baseUrl + "shared/color-gamut-1.webp"
      },      
      {
        name: "facePlate",
        type: "fbxModel",
        tags: ["vfxBuilder"],
        path: 'accessories/face/face.fbx',        
      }
    ]

    this.allMeshes = []

    this.animations = {}
    this.mixer = undefined
    this.resources = new Resources("vfxBuilder", additionalSources)
    this.resources.on("ready", () => {
      const fighterScale = 0.017
      const fbx = this.resources.items["fighter"]
      fbx.scale.setScalar(fighterScale)
      fbx.position.set(0, -0.6, 0)
      fbx.name = "fighter"

      const accessoryData = {
        series: 1,
        eyesId: "guppy",
        mouthId: "guppy",
        headId: "guppy",
        bodyId: "guppy",
        handsId: "guppy",
        feetId: "guppy",      
        bodyColor: skinColorMetadata["skin-1-2"],        
        limbColor: skinColorMetadata["skin-1-2"],
        composableId: 2,
        nakedFighter: false,
      }

      fighterModifier(fbx, this.resources, accessoryData, this.allMeshes)
      this.scene.add(fbx)

      const helper = new THREE.SkeletonHelper(fbx)

      this.resources.loaders.fbxLoader.manager.onLoad = () => {
        this.manager = new THREE.LoadingManager()
        this.manager.onLoad = () => {
          setAnimationsReady(true)
          setAnimationData({
            mixer: this.mixer,
            animations: this.animations,
            fighter: fbx,
            armature: fbx.children.filter(
              (child) => child.name === "Armature"
            )[0],
            boneNames: [
              ...new Set(
                helper.bones.map((bone) => {
                  return bone.name
                })
              ),
            ],
          })

          fbx.getObjectByName("Armature").rotation.y = Math.PI / 2
        }

        this.mixer = new THREE.AnimationMixer(fbx)

        const onLoad = (animNamesMapping, anim, singleBool=false) => {       
          Object.keys(animNamesMapping).forEach((animKey) => {
            const animName = animNamesMapping[animKey]
            const clip = (
              singleBool ? 
              anim.animations[0] : 
              anim.animations.filter((x) => { return x.name === animKey })[0]
            )
            if (!singleBool) {
              this.startAnimationAtFrameZero(clip)
            }
            for (let track in clip.tracks) {
              clip.tracks[track].setInterpolation(THREE.InterpolateDiscrete);
            }
            const action = this.mixer.clipAction(clip)
            this.animations[animName] = {
              clip: clip,
              action: action,
              frameCount: Math.round(clip.duration * 60) + 1,
              // frameCount: Math.ceil(clip.duration * TICK_RATE),
            }
          })   
        }

        const loader = new GLTFLoader(this.manager)
        Object.keys(animationsMapping).forEach((animationKey) => {
          const animationData = animationsMapping[animationKey]
          loader.load(animationData.path, (a) => {
            onLoad(animationData.names, a, animationData.single)
          })
        })
      }
    })

    this.previousRAF = null
    this.render()
  }

  updateToonEdge(edgeBool) {
    if (this.resources["toonMaterials"] !== undefined) {
      this.resources["toonMaterials"].forEach((material) => {
        material.side = edgeBool ? THREE.BackSide : THREE.FrontSide
        material.uniforms.edge.value = edgeBool
      })
    }
  }

  updateToonShaderLight() {
    if (this.resources["toonMaterials"] !== undefined) {
      this.resources["toonMaterials"].forEach((material) => {
        material.uniforms.lightPosition.value = this.directionalLight.position
      })
    }
  }  

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }
      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        this.previousRAF = t - (elapsed % this.fpsInterval)         
        updateToonShaderLight(this.resources, this.directionalLight)
        updateToonEdge(this.resources, false)
        this.threejs.instance.render(this.scene, this.camera.instance)
      }
      this.render()
    })
  }
}

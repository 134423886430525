import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import createScene from "../../helpers/threejs/scene-setup"
import { updateToonEdge, updateToonShaderLight } from '../../helpers/threejs/toon-rendering'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
import wireframeBodyFile from "../../local-assets/gameplay/characters/wireframe-body.fbx"
import robotBodyFile from "../../local-assets/gameplay/characters/robot-body.fbx"
import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"
import robotTextureFile from "../../local-assets/textures/robot-body/robot.png"
import fighterModifier from "../model-modifiers/characters/fighter-modifier"
import characterModifierMapping from "../model-modifiers/characters/character-modifier-mapping"
import Resources from "../../helpers/resource-management/resources"
import { animationsMapping } from "./animation-mapping"
import * as THREE from "three"

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

const baseUrl = "https://ai-arena.b-cdn.net/"
export const accessoryDirectory = `${baseUrl}accessories/`

export default class DressingRoomScene {
  constructor(setAnimationsReady, setAnimationData) {
    this.initialize(setAnimationsReady, setAnimationData)
  }

  initialize(setAnimationsReady, setAnimationData) {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "dressing-room",
      camera: { fov: 25, near: 0.1, far: 1000 },
      drawBool: true
    })

    this.targetFPS = 30
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 7)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0, 1, 1.66)
    this.scene.add(this.directionalLight)

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
    this.scene.add(this.ambientLight)

    const composableFolderName = "accessories/composable-screen/1/"
    const additionalSources = [
      {
        name: "fighter",
        type: "fbxModel",
        tags: ["dressingRoom"],
        path: fighterBodyFile,
      },
      {
        name: "fighterWireframe",
        type: "fbxModel",
        tags: ["dressingRoom"],
        path: wireframeBodyFile,
      },
      {
        name: "gradientMap",
        type: "texture",
        tags: ["dressingRoom"],
        path: gradientMapFile,
      },
      {
        name: "facePlate",
        type: "fbxModel",
        tags: ["dressingRoom"],
        path: 'accessories/face/face.fbx',
      },
      {
        name: "robot",
        type: "fbxModel",
        tags: ["dressingRoom"],
        path: robotBodyFile,
      },
      {
        name: "robotTexture",
        type: "texture",
        tags: ["dressingRoom"],
        path: robotTextureFile,
      },
      {
        name: "composableScreen",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: composableFolderName + "composable-screen-1.fbx",
      },
      {
        name: "composableScreenTexture",
        type: "texture",
        tags: ["fighterCollisionMaker"],
        path: composableFolderName + "composable-screen-1--base-color.webp",
      },
      {
        name: "colorGamut",
        type: "texture",
        tags: ["dressingRoom"],
        path: baseUrl + "shared/color-gamut-1.webp"
      }
    ]

    this.allMeshes = []
    this.materials = {}

    this.animations = {}
    this.mixer = undefined
    this.resources = new Resources("fighterCollisionMaker", additionalSources)
    this.resources.on("ready", () => {
      const fighterScale = 0.017
      // const fighterScale = 0.025
      const fbx = this.resources.items["fighter"]
      // const fbx = this.resources.items["fighterWireframe"]
      // const fbx = this.resources.items["robot"]
      fbx.scale.setScalar(fighterScale)
      fbx.position.set(0, -0.6, 0)
      // fbx.position.set(0, -1.1, 0)
      fbx.name = "fighter"

      for (var m = 0; m < fbx.children.length; m++) {
        if (fbx.children[m].type.includes("Mesh")) {
          fbx.children[m].castShadow = true
          this.allMeshes.push(fbx.children[m])          
        }
      }

      const robotBool = false
      const accessoryData = {
        series: 1,
        skinId: 1,
        limbId: 1,
        composableId: 1,
        nakedFighter: true,
      }

      fighterModifier(
        fbx,
        this.resources,
        accessoryData,
        this.allMeshes
      )
      // const wireframeMaterial = new THREE.MeshBasicMaterial({ color: "cyan" })
      // fbx.traverse((child) => {
      //   if (child.type.includes("Mesh")) {
      //     child.material = wireframeMaterial
      //   }
      // })
      // characterModifierMapping["composable"](
      //   fbx,
      //   this.resources,
      //   accessoryData,
      //   this.allMeshes
      // )
      this.scene.add(fbx)
      this.character = fbx

      this.resources.loaders.fbxLoader.manager.onLoad = () => {
        const armatureName = robotBool ? "Robot" : "Armature"
        this.manager = new THREE.LoadingManager()
        this.manager.onLoad = () => {
          setAnimationsReady(true)
          setAnimationData({
            mixer: this.mixer,
            animations: this.animations,
            fighter: fbx,
            armature: fbx.children.filter(
              (child) => child.name === armatureName
            )[0],
          })
          // fbx.getObjectByName(armatureName).rotation.y = Math.PI / 2
          fbx.getObjectByName(armatureName).rotation.y = Math.PI / 3.2
          this.animations["idle"].action.play()
          this.animations["idle"].action.time = 0
          this.mixer.update(0)
        }

        this.mixer = new THREE.AnimationMixer(fbx)

        const onLoad = (animName, anim) => {
          const clip = anim.animations[0]
          const action = this.mixer.clipAction(clip)
          this.animations[animName] = {
            clip: clip,
            action: action,
            frameCount: Math.round(clip.duration * 60) + 1,
          }
        }

        const loader = new FBXLoader(this.manager)
        Object.keys(animationsMapping).forEach((animationName) => {
          loader.load(animationsMapping[animationName], (a) => {
            onLoad(animationName, a)
          })
        })
      }
    })

    this.previousRAF = null
    this.render()
  }

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }

      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        this.previousRAF = t - (elapsed % this.fpsInterval)
        if (this.mixer !== undefined) {
          this.mixer.update(elapsed)
        }
        updateToonShaderLight(this.resources, this.directionalLight)
        // updateToonEdge(this.resources, true)
        // this.threejs.instance.render(this.scene, this.camera.instance)

        updateToonEdge(this.resources, false)
        this.threejs.instance.render(this.scene, this.camera.instance)
      }
      this.render()
    })
  }
}

import * as THREE from 'three'

const hexagonBackground = (texture, scene) => {
  const planeGeometry = new THREE.PlaneGeometry(100, 100)
  const planeMaterial = new THREE.MeshBasicMaterial({
    opacity: 0,
    transparent: true,
    side: THREE.BackSide
  })
  const plane = new THREE.Mesh(planeGeometry, planeMaterial)
  plane.rotation.x = Math.PI
  const background = new THREE.Group()
  background.add(plane)
  background.name = "wall"
  scene.add(background)
  return background
}

export default hexagonBackground
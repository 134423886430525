import { convertHex } from './default-metadata'

const limbColorMetadata = {
  "limbs-1-1": {
    color: convertHex("#dc2c3a"),
    shadeColor: convertHex("#dc2c3a"),
    rimColor: convertHex("#ffffff")
  },
  "limbs-1-2": {
    color: convertHex("#ff7809"),
    shadeColor: convertHex("#ff7809"),
    rimColor: convertHex("#ffffff")
  },
  "limbs-1-3": {
    color: convertHex("#e4c013"),
    shadeColor: convertHex("#e4c013"),
    rimColor: convertHex("#ffffff")
  },
  "limbs-1-4": {
    color: convertHex("#6bc500"),
    shadeColor: convertHex("#6bc500"),
    rimColor: convertHex("#ffffff")
  },
  "limbs-1-5": {
    color: convertHex("#3cabff"),
    shadeColor: convertHex("#3cabff"),
    rimColor: convertHex("#ffffff")
  },
  "limbs-1-6": {
    color: convertHex("#112151"),
    shadeColor: convertHex("#112151"),
    rimColor: convertHex("#ffffff")
  },
  "limbs-1-7": {
    color: convertHex("#8953f9"),
    shadeColor: convertHex("#8953f9"),
    rimColor: convertHex("#ffffff")
  },

  // Preset
  "limbs-marshall": {
    // color: convertHex("purple"),
    // shadeColor: convertHex("#630663"),
    color: convertHex("#b218ca"),
    shadeColor: convertHex("#850971"),
    rimColor: convertHex("#b218ca")
  },
  "limbs-bo": {
    // color: convertHex("blue"),
    // shadeColor: convertHex("#0d0d94"),
    color: convertHex("#1601f4"),
    shadeColor: convertHex("#111555"),
    rimColor: convertHex("#2222f6")
  },
}

export default limbColorMetadata
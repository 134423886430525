import { inplacePositioning } from './default-metadata'

const season1 = {
  "feet-1-1": {...inplacePositioning},
  "feet-1-2": {...inplacePositioning},
  "feet-1-3": {...inplacePositioning},
  "feet-1-4": {...inplacePositioning},
  "feet-1-5": {...inplacePositioning},
  "feet-1-6": {...inplacePositioning},
  "feet-1-7": {...inplacePositioning},
  "feet-1-8": {...inplacePositioning},
  "feet-1-9": {...inplacePositioning},
  "feet-1-10": {...inplacePositioning},
  "feet-guppy": {...inplacePositioning},
  "feet-artist": {...inplacePositioning},
  "feet-gwei": {...inplacePositioning},
}

const season2 = {
  "feet-2-1": {...inplacePositioning},
  "feet-2-2": {...inplacePositioning},
  "feet-2-3": {...inplacePositioning},
  "feet-2-4": {...inplacePositioning},
  "feet-2-5": {...inplacePositioning},
  "feet-2-6": {...inplacePositioning},
  "feet-2-7": {...inplacePositioning},
  "feet-2-8": {...inplacePositioning},
  "feet-2-9": {...inplacePositioning},
  "feet-2-10": {...inplacePositioning},
}

const feetAccessoryMetadata = {
  ...season1,
  ...season2
}

export default feetAccessoryMetadata
import { inplacePositioning } from './default-metadata'
import { convertRGB } from './default-metadata'
import * as THREE from 'three'

const season1 = {
  "hands-1-1": {...inplacePositioning},
  "hands-1-2": {...inplacePositioning},
  "hands-1-3": {...inplacePositioning},
  "hands-1-4": {...inplacePositioning},
  "hands-1-5": {...inplacePositioning},
  "hands-1-6": {...inplacePositioning},
  "hands-1-7": {...inplacePositioning},
  "hands-1-8": {...inplacePositioning},
  "hands-1-9": {...inplacePositioning},
  "hands-1-10": {...inplacePositioning},
  "hands-guppy": {...inplacePositioning},
  "hands-artist": {...inplacePositioning},
  "hands-gwei": {
    ...inplacePositioning, 
    useTexture: false,
    color: new THREE.Vector4(...convertRGB([135, 174, 239]), 1),
    shadeColor: new THREE.Vector4(...convertRGB([138, 242, 255]), 1),
    rimColor: new THREE.Vector4(...convertRGB([193, 149, 255]), 1)
  },
  "hands-bowling": {...inplacePositioning},
}

const season2 = {
  "hands-2-1": {...inplacePositioning},
  "hands-2-2": {...inplacePositioning},
  "hands-2-3": {...inplacePositioning},
  "hands-2-4": {...inplacePositioning},
  "hands-2-5": {...inplacePositioning},
  "hands-2-6": {...inplacePositioning},
  "hands-2-7": {...inplacePositioning},
  "hands-2-8": {...inplacePositioning},
  "hands-2-9": {...inplacePositioning},
  "hands-2-10": {...inplacePositioning},
}

const handsAccessoryMetadata = {
  ...season1,
  ...season2
}

export default handsAccessoryMetadata
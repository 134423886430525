import React from 'react'
import guppy from '../../../local-assets/landing-page/co-founder-pose--guppy.png'
import artist from '../../../local-assets/landing-page/co-founder-pose--artist.png'
import gwei from '../../../local-assets/landing-page/co-founder-pose--gwei.png'
import SignOutButton from '../../sign-in-components/sign-out-button/SignOutButton'
import "./landing-page.css"

const LandingPage = () => {
  return (
    <>
      <div id="landing-page__container">
        <SignOutButton />
        {/* <img className="landing-page__pose" id="landing-page--guppy" src={guppy} alt="" />
        <img className="landing-page__pose" id="landing-page--artist" src={artist} alt="" />
        <img className="landing-page__pose" id="landing-page--gwei" src={gwei} alt="" /> */}
      </div>
    </>
  )
}

export default LandingPage
import { useEffect } from 'react'
import { 
  animationAtom, 
  animationDataAtom, 
  colliderSelectedAtom, 
  colliderTypeAtom, 
  environmentAtom, 
  fighterTypeAtom, 
  totalFramesAtom
} from '../../collision-maker/atoms'
import { useAtom } from 'jotai'

const useSwapCharacters = () => {
  const fighterType = useAtom(fighterTypeAtom)[0]
  const environment = useAtom(environmentAtom)[0]
  const animationData = useAtom(animationDataAtom)[0]
  const setColliderSelected = useAtom(colliderSelectedAtom)[1]
  const setColliderType = useAtom(colliderTypeAtom)[1]
  const setTotalFrames = useAtom(totalFramesAtom)[1]
  const [animation, setAnimation] = useAtom(animationAtom)

  const revertToAPose = () => {
    if (animation.name !== "a-pose") {
      animationData.animations[animation.name].action.stop()
    }
    setColliderSelected(undefined)
    setColliderType({ name: "hurtbox", label: "hurtbox" })
    setTotalFrames(0)
    setAnimation({ name: "a-pose", label: "A Pose" })
  }

  useEffect(() => {
    if (environment) {
      revertToAPose()
      environment.swapCharacters(fighterType)
    }
  }, [fighterType])

}

export {
  useSwapCharacters
}
import React from 'react'
import './frames-menu.css'

const FramesMenu = ({ totalFrames, animationFrame, setAnimationFrame }) => {
  const playAnimation = (currentFrame) => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (currentFrame < totalFrames) {
          setAnimationFrame(currentFrame)
          playAnimation(currentFrame + 1)
        }
      })  
    }, 1000/60)
  }

  return (
    <>
      {
        totalFrames > 0 &&
        <>
          <div id="frames-menu__play-button" onClick={() => playAnimation(0)}>
            <p>Play</p>
          </div>
          <div id="frames-menu__container">
            {
              [...Array(totalFrames).keys()].map((frameIdx) => {
                return (
                  <div
                    key={`frame-key--${frameIdx}`}
                    className="frames-menu__frame-key"
                    id={`frames-menu__frame-key--${frameIdx}`}
                    style={{
                      backgroundColor: frameIdx === animationFrame ? "#ff776f" : "#69e3f2"
                    }}
                    onClick={() => {
                      setAnimationFrame(frameIdx)
                    }}>
                  </div>
                )
              })
            }
          </div>
          <h1 id="frames-menu__frame-number">Frame {animationFrame}</h1>
        </>
      }
    </>
  )
}

export default FramesMenu
const rimLightVertexPrepend = `
  uniform vec3 rimOffset;
  uniform float modelScale;
  varying float rimContribution;
  
  varying vec3 vPositionW;
  varying vec3 vNormalW;
`

const rimLightVertexContent = `
  vPositionW = normalize(vec3(modelViewMatrix * vec4(transformed, 1.0)).xyz);
  vNormalW = normalize(normalMatrix * objectNormal);
`

export {
  rimLightVertexPrepend,
  rimLightVertexContent
}
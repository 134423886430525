import React from "react"
import { Route } from "react-router-dom"
import Unauthorized from "./sign-in-components/unauthorized/Unauthorized"
import { accessAtom } from "../helpers/generic/setup"
import { useAtom } from "jotai"

const GatedRoute = ({ path, access, children, exact, sessionExists }) => {
  const hasAccess = useAtom(accessAtom)[0]

  return (
    <Route path={path} exact={exact}>
      {
        <>
          {
            sessionExists && hasAccess.checked &&
            (access ? children : <Unauthorized />)
          }
        </>
      }
    </Route>
  )
}

export default GatedRoute

import { inplacePositioning } from './default-metadata'

const additionalAccessoryMetadata = {
  "additional-artist": {...inplacePositioning},
  "additional-crown": {
    rotation: { x: -1.6, y: 0.5, z: -1.53 },
    position: { x: 46.5, y: -21.9, z: 0 },
    scaleMultiple: 1,
    useTexture: true,
  }
}

const crownPositionsY = {
  dendroid: -26.1,
  1: -18,
  2: -17.6,
  3: -17.5,
  4: -18.8,
  5: -22.8,
  6: -20.4,
  7: -19.8,
  8: -16.2,
  9: -18.5,
  10: -25.8
}

const headToCrownPositions = {
  dendroid: { x: -crownPositionsY.dendroid * 2.2, y: crownPositionsY.dendroid, z: 0 },
  1: { x: 43.5, y: crownPositionsY[1], z: 0 },
  2: { x: -crownPositionsY[2] * 2.2, y: crownPositionsY[2], z: 0 },
  3: { x: -crownPositionsY[3] * 2.2, y: crownPositionsY[3], z: 0 },
  4: { x: -crownPositionsY[4] * 2.2, y: crownPositionsY[4], z: 0 },
  5: { x: -crownPositionsY[5] * 2.2, y: crownPositionsY[5], z: 0 },
  6: { x: -crownPositionsY[6] * 2.2, y: crownPositionsY[6], z: 0 },
  7: { x: -crownPositionsY[7] * 2.2, y: crownPositionsY[7], z: 0 },
  8: { x: -crownPositionsY[8] * 2.2, y: crownPositionsY[8], z: 0 },
  9: { x: -crownPositionsY[9] * 2.2, y: crownPositionsY[9], z: 0 },
  10: { x: -crownPositionsY[10] * 2.2, y: crownPositionsY[10], z: 0 }
}

export default additionalAccessoryMetadata
export {
  headToCrownPositions
}
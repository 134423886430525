import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader"
import createScene from "../../helpers/threejs/scene-setup"
import { updateToonEdge, updateToonShaderLight } from '../../helpers/threejs/toon-rendering'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"
import skinColorMetadata from "../asset-management/fighter-accessories/metadata/skin"
import limbColorMetadata from "../asset-management/fighter-accessories/metadata/limb"
import fighterModifier from "../model-modifiers/characters/fighter-modifier"
import Resources from "../../helpers/resource-management/resources"
import { animationsMapping } from "./animation-mapping"

import stageFile from "../../local-assets/gameplay/stages/castle/castle-stage.fbx"
import stageTexture from "../../local-assets/gameplay/stages/castle/castle-stage--base-color.webp"
import arenaBackground from "../../local-assets/complete-backgrounds/castle-background.webp"
import { clone } from "three/examples/jsm/utils/SkeletonUtils"
import * as THREE from "three"
import { GUI } from 'dat.gui'

const baseUrl = "https://ai-arena.b-cdn.net/"

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

export default class MarshallBoFaceoffScene {
  constructor() {
    this.initialize()
  }

  initialize() {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "marketing-staging",
      camera: { fov: 25, near: 0.1, far: 1000 },
    })

    this.targetFPS = 60
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 10)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0.78, 0.01, 1.66)
    this.scene.add(this.directionalLight)

    const additionalSources = [
      {
        name: "fighter",
        type: "fbxModel",
        tags: ["marketingStaging"],
        path: fighterBodyFile,
      },
      {
        name: "gradientMap",
        type: "texture",
        tags: ["marketingStaging"],
        path: gradientMapFile,
      },
      {
        name: "colorGamut",
        type: "texture",
        tags: ["dressingRoom"],
        path: baseUrl + "shared/color-gamut-1.webp"
      },      
      {
        name: "facePlate",
        type: "fbxModel",
        tags: ["marketingStaging"],
        path: 'accessories/face/face.fbx',
      },
      {
        name: "stage",
        type: "fbxModel",
        tags: ["marketingStaging"],
        path: stageFile,
      },
      {
        name: "stageTexture",
        type: "texture",
        tags: ["marketingStaging"],
        path: stageTexture,
      },
      {
        name: "arenaBackground",
        type: "texture",
        tags: ["marketingStaging"],
        path: arenaBackground,
      }
    ]

    this.allMeshes = []

    this.boState = {
      name: "idle",
      time: 0
    }

    this.transitionMapping = {
      "idle": "punch",
      "punch": "double-punch",
      "double-punch": "idle"
    }

    this.animations = { marshall: {}, bo: {} }
    this.mixer = undefined
    this.resources = new Resources("marketingStaging", additionalSources)
    this.resources.on("ready", () => {

      const texture = this.resources.items["arenaBackground"]
      texture.encoding = THREE.sRGBEncoding
      texture.wrapS = THREE.RepeatWrapping
      texture.wrapT = THREE.RepeatWrapping
      const material = new THREE.SpriteMaterial({ map: texture })
      const sprite = new THREE.Sprite(material)
      sprite.scale.x = 30
      sprite.scale.y = sprite.scale.x / (16 / 9)
      sprite.position.set(0, 0, -10)
      this.background = sprite
      this.scene.add(sprite)

      const stageScale = 0.01
      const stageModel = this.resources.items["stage"]
      stageModel.scale.setScalar(stageScale)
      stageModel.position.y = -0.75//-0.95
      const stageTexture = this.resources.items["stageTexture"]
      stageTexture.encoding = THREE.sRGBEncoding
      const stageMaterial = new THREE.MeshBasicMaterial({ map: stageTexture, side: THREE.DoubleSide })
      stageModel.traverse((child) => {
        if (child.type.includes("Mesh")) {
          child.material = stageMaterial
        }
      })
      this.scene.add(stageModel)

      const fighterScale = 0.017
      const marshallModel = this.resources.items["fighter"]
      marshallModel.scale.setScalar(fighterScale)
      marshallModel.position.set(-2.5, -0.6, 0)
      marshallModel.name = "fighter"

      const marshallAccessoryData = {
        series: 1,
        eyesId: 1,
        mouthId: 1,
        headId: "marshall",
        bodyId: "marshall",
        handsId: 1,
        feetId: 7,
        bodyColor: skinColorMetadata["skin-marshall"],        
        limbColor: limbColorMetadata["limbs-marshall"],
        composableId: 2,
        nakedFighter: false,
      }
      fighterModifier(marshallModel, this.resources, marshallAccessoryData, this.allMeshes)
      this.scene.add(marshallModel)

      const boAccessoryData = {
        series: 1,
        eyesId: "bo",
        mouthId: "bo",
        headId: "bo",
        bodyId: "boxing",
        handsId: "bo",
        feetId: 1,
        bodyColor: skinColorMetadata["skin-bo"],        
        limbColor: limbColorMetadata["limbs-bo"],
        composableId: 2,
        nakedFighter: false,
      }
      const boModel = clone(marshallModel)
      boModel.position.x = 2.5
      fighterModifier(boModel, this.resources, boAccessoryData, this.allMeshes)
      this.scene.add(boModel)

      this.resources.loaders.fbxLoader.manager.onLoad = () => {
        this.manager = new THREE.LoadingManager()
        this.manager.onLoad = () => {
          const marshallArmatureRotation = marshallModel.getObjectByName("Armature").rotation
          const boArmatureRotation = boModel.getObjectByName("Armature").rotation

          const gui  = new GUI()

          const marshallRotation = gui.addFolder("Marshall Rotation")
          marshallRotation.add(marshallArmatureRotation, "y", -Math.PI, Math.PI, 0.01)
          marshallRotation.open()

          const boRotation = gui.addFolder("Bo Rotation")
          boRotation.add(boArmatureRotation, "y", -Math.PI, Math.PI, 0.01)
          boRotation.open()

          marshallArmatureRotation.y = Math.PI / 1.5
          this.animations.marshall["idle"].action.play()
          this.animations.marshall["idle"].action.time = 0
          this.mixers.marshall.update(0)

          boArmatureRotation.y = -Math.PI / 3.5
          this.animations.bo["idle"].action.play()
          this.animations.bo["idle"].action.time = 0
          this.mixers.bo.update(0)
        }

        this.mixers = {
          marshall: new THREE.AnimationMixer(marshallModel),
          bo: new THREE.AnimationMixer(boModel)
        }

        const onLoad = (animName, anim) => {          
          const clip = anim.animations[0]
          this.animations.marshall[animName] = {
            clip: clip,
            action: this.mixers.marshall.clipAction(clip),
            frameCount: Math.round(clip.duration * 60) + 1,
          }
          this.animations.bo[animName] = {
            clip: clip,
            action: this.mixers.bo.clipAction(clip),
            frameCount: Math.round(clip.duration * 60) + 1,
          }
        }

        const loader = new FBXLoader(this.manager)
        Object.keys(animationsMapping).forEach((animationName) => {
          loader.load(animationsMapping[animationName], (a) => {
            onLoad(animationName, a)
          })
        })
      }
    })

    this.previousRAF = null
    this.render()
  }

  updateToonEdge(edgeBool) {
    if (this.resources["toonMaterials"] !== undefined) {
      this.resources["toonMaterials"].forEach((material) => {
        material.side = edgeBool ? THREE.BackSide : THREE.FrontSide
        material.uniforms.edge.value = edgeBool
      })
    }
  }

  updateToonShaderLight() {
    if (this.resources["toonMaterials"] !== undefined) {
      this.resources["toonMaterials"].forEach((material) => {
        material.uniforms.lightPosition.value = this.directionalLight.position
      })
    }
  }  

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }
      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        if (this.background !== undefined) {
          const cameraOffset = new THREE.Vector3(0, 0, -10)
          cameraOffset.applyQuaternion(this.camera.instance.quaternion)
          this.background.position.copy(cameraOffset)
        }

        this.previousRAF = t - (elapsed % this.fpsInterval)
        updateToonShaderLight(this.resources, this.directionalLight)
        updateToonEdge(this.resources, false)
        this.threejs.instance.render(this.scene, this.camera.instance)
      }
      if (this.mixers !== undefined) {
        this.mixers.marshall.update(elapsed)
        
        // if (this.animations.bo[this.boState.name] !== undefined) {
        //   this.boState.time += elapsed
        //   const action = this.animations.bo[this.boState.name].action
        //   const duration = action._clip.duration
        //   if (this.boState.time >= duration - 1/60) {
        //     action.stop()
        //     action.time = 0
        //     this.boState = {
        //       name: this.transitionMapping[this.boState.name],
        //       time: 0
        //     }
        //     this.animations.bo[this.boState.name].action.play()
        //     this.animations.bo[this.boState.name].action.time = 0
        //   }
        // }
        this.mixers.bo.update(elapsed)
      }

      this.render()
    })
  }
}

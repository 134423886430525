import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { 
  animationAtom, 
  boneSelectedAtom, 
  colliderSelectedAtom, 
  fighterTypeAtom, 
  hurtboxLinksAtom 
} from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './bone-menu.css'

const BoneMenu = ({ boneNames }) => {
  const animation = useAtom(animationAtom)[0]
  const colliderSelected = useAtom(colliderSelectedAtom)[0]
  const hurtboxLinks = useAtom(hurtboxLinksAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const [boneSelected, setBoneSelected] = useAtom(boneSelectedAtom)
  const [boneOptions, setBoneOptions] = useState([])  

  const changeBone = (newBone) => {
    setBoneSelected(newBone)
  }

  useEffect(() => {
    var tempOptions
    if (colliderSelected !== undefined) {
      tempOptions = [
        { name: "none", label: "none" },
        ...boneNames.map((name) => {
          return { name: name, label: name }
        })
      ]
    }
    else {
      tempOptions = [{ name: "none", label: "none" }]
    }
    setBoneOptions(tempOptions)
    if (
      colliderSelected !== undefined && 
      hurtboxLinks[fighterType][colliderSelected.name] !== undefined
    ) {
      const link = hurtboxLinks[fighterType][colliderSelected.name].name
      setBoneSelected({ name: link, label: link })
    }
    else {
      setBoneSelected(tempOptions[0])
    }
  }, [colliderSelected])

  const aPoseBool = animation?.name === "a-pose"

  return (
    <>
      {
        boneOptions.length > 0 && aPoseBool &&
        <Dropdown
          containerId="bone-menu__container"
          dropdownId="bone-menu-dropdown"
          selection={boneSelected}
          clickFunction={changeBone}
          options={boneOptions}
          positionStyling={{}}>
        </Dropdown>
      }
    </>
  )
}

export default BoneMenu
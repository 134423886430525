import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { animationsMapping } from '../../../helpers/animation-player/animation-mapping'
import './animation-menu.css'

const AnimationMenu = ({ animationsReady, animationData }) => {
  const [animation, setAnimation] = useState(undefined)

  const positionStyling = {
    top: "10px",
    left: "20px"
  }
  const animationOptions = [
    ...Object.keys(animationsMapping).map((name) => {
      return { name: name, label: name.split("-").join(" ") }
    })
  ]

  const changeAnimation = (newAnimation) => {
    animationData.animations[animation.name].action.stop()
    setAnimation(newAnimation)
  }

  useEffect(() => {
    setAnimation(animationOptions[0])
  }, [])

  useEffect(() => {
    if (
      animationsReady && 
      animation !== undefined && 
      animationData !== undefined
    ) {
      animationData.fighter.getObjectByName("Armature").rotation.y = Math.PI / 2
      animationData.animations[animation.name].action.play()
      animationData.animations[animation.name].action.time = 0
      animationData.mixer.update(0)
    }
  }, [animation, animationData, animationsReady])

  return (
    <>
      {
        animation !== undefined &&
        <Dropdown
          containerId="animation-menu__container"
          dropdownId="animation-menu-dropdown"
          selection={animation}
          clickFunction={changeAnimation}
          options={animationOptions}
          positionStyling={positionStyling}>
        </Dropdown>
      }
    </>
  )
}

export default AnimationMenu
import { dressFighter } from '../../asset-management/fighter-accessories/dress-fighter'
import { 
  changeBodyColor, 
  changeLimbColor 
} from '../../asset-management/fighter-accessories/skin-color'
import skinColorMetadata from '../../asset-management/fighter-accessories/metadata/skin'
import limbColorMetadata from '../../asset-management/fighter-accessories/metadata/limb'
import * as THREE from 'three'

const fighterModifier = (fbx, resources, accessoryData, allMeshes, context) => {
  const gradientMap = resources.items["gradientMap"]
  gradientMap.minFilter = THREE.NearestFilter
  gradientMap.magFilter = THREE.NearestFilter

  if (resources["toonMaterials"] === undefined) {
    resources["toonMaterials"] = []
  }

  const scale = fbx.scale.x
  // Eventually completely replace with id, but this is a backwards compatible patch
  const skinColor = (
    accessoryData.bodyColor !== undefined ? 
    accessoryData.bodyColor : 
    skinColorMetadata[`skin-${accessoryData.series}-${accessoryData.skinId}`]
  )
  const limbColor = (
    accessoryData.limbColor !== undefined ? 
    accessoryData.limbColor : 
    limbColorMetadata[`limbs-${accessoryData.series}-${accessoryData.limbId}`]
  )
  
  const nonClothingInputs = ["Toon", gradientMap, scale, accessoryData.edge]
  const skinMaterial = changeBodyColor(fbx, skinColor, ...nonClothingInputs)
  // const limbMaterial = changeLimbColor(fbx, limbColor, ...nonClothingInputs)
  const limbMaterial = changeLimbColor(fbx, skinColor, ...nonClothingInputs)
  dressFighter(
    fbx, 
    accessoryData, 
    fbx.scale.x, 
    resources, 
    accessoryData.nakedFighter, 
    allMeshes,
    context
  )
  
  resources["toonMaterials"].push(skinMaterial)
  resources["toonMaterials"].push(limbMaterial)
}

export default fighterModifier

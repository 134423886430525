import EventEmitter from "../event-emitter"

export default class Sizes extends EventEmitter {
  constructor(canvas) {
    super()

    this.canvas = canvas

    // Setup
    this.width = this.canvas.clientWidth
    this.height = this.canvas.clientHeight
    this.pixelRatio = Math.min(window.devicePixelRatio, 2)

    // Resize event
    window.addEventListener("resize", () => {
      this.width = this.canvas.clientWidth
      this.height = this.canvas.clientHeight
      this.pixelRatio = Math.min(window.devicePixelRatio, 2)

      this.trigger("resize")
    })
  }
}

const aspectCutoffs = { min: 1, max: 2.5 }
export {
  aspectCutoffs
}
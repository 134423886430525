import React, { useState, useEffect } from 'react'
import AnimationMenu from '../animation-menu/AnimationMenu'
import UploaderMenu from '../uploader-menu/UploaderMenu'
import AnimationPlayerScene from '../../../helpers/animation-player/animation-player-scene'
import './animation-player.css'

import * as THREE from 'three'
import { GUI } from 'dat.gui'

const AnimationPlayer = () => {
  const [animationData, setAnimationData] = useState(undefined)
  const [animationsReady, setAnimationsReady] = useState(false)
  const [environment, setEnvironment] = useState(undefined)

  useEffect(() => {
    const env = new AnimationPlayerScene(setAnimationsReady, setAnimationData)

    // Color Picker
    const parameters = { 
      skinColor: 0xd99c86, 
      skinShadeColor: 0xb37964,
      skinRimColor: 0xf5c4b3,
      limbColor: 0xd99c86, 
      limbShadeColor: 0xb37964,
      limbRimColor: 0xf5c4b3,
    }    

    const gui = new GUI({ autoPlace: true })
    gui.domElement.id = 'gui'
    const skinFolder = gui.addFolder("Skin Color")
    skinFolder.addColor(parameters, 'skinColor')
    .onChange(() =>
    {
      const skinMaterial = env.scene.getObjectByName("mesh_head").material
      const newColor = new THREE.Color(parameters.skinColor)
      skinMaterial.uniforms.color.value = new THREE.Vector4(
        newColor.r, newColor.g, newColor.b, 1
      )
    })

    skinFolder.addColor(parameters, 'skinShadeColor')
    .onChange(() =>
    {
      const skinMaterial = env.scene.getObjectByName("mesh_head").material
      const newColor = new THREE.Color(parameters.skinShadeColor)
      skinMaterial.uniforms.shadeColor.value = new THREE.Vector4(
        newColor.r, newColor.g, newColor.b, 1
      )
    })

    skinFolder.addColor(parameters, 'skinRimColor')
    .onChange(() =>
    {
      const skinMaterial = env.scene.getObjectByName("mesh_head").material
      const newColor = new THREE.Color(parameters.skinRimColor)
      skinMaterial.uniforms.rimColor.value = new THREE.Vector4(
        newColor.r, newColor.g, newColor.b, 1
      )
    })

    const limbFolder = gui.addFolder("Limb Color")
    limbFolder.addColor(parameters, 'limbColor')
    .onChange(() =>
    {
      const limbMaterial = env.scene.getObjectByName("mesh_arms").material
      const newColor = new THREE.Color(parameters.limbColor)
      limbMaterial.uniforms.color.value = new THREE.Vector4(
        newColor.r, newColor.g, newColor.b, 1
      )
    })

    limbFolder.addColor(parameters, 'limbShadeColor')
    .onChange(() =>
    {
      const limbMaterial = env.scene.getObjectByName("mesh_arms").material
      const newColor = new THREE.Color(parameters.limbShadeColor)
      limbMaterial.uniforms.shadeColor.value = new THREE.Vector4(
        newColor.r, newColor.g, newColor.b, 1
      )
    })

    limbFolder.addColor(parameters, 'limbRimColor')
    .onChange(() =>
    {
      const limbMaterial = env.scene.getObjectByName("mesh_arms").material
      const newColor = new THREE.Color(parameters.limbRimColor)
      limbMaterial.uniforms.rimColor.value = new THREE.Vector4(
        newColor.r, newColor.g, newColor.b, 1
      )
    })
    // 

    setEnvironment(env)
    return () => {
      env.threejs.instance.domElement.remove()
    }
  }, [])

  return (
    <>
      <div id="animation-player__container">
        <div id="animation-player__menu">
          <AnimationMenu
            animationsReady={animationsReady}
            animationData={animationData}>
          </AnimationMenu>
        </div>
        <UploaderMenu environment={environment}></UploaderMenu>
        <div id="animation-player"></div>
      </div>
    </>
  )
}

export default AnimationPlayer
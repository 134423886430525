const animationsDirectory = "animations/"
const animationExtension = ".fbx"

const animationsMapping = {
  "idle": animationsDirectory + "idle" + animationExtension,
  "punch": animationsDirectory + "punch" + animationExtension,
  "double-punch": animationsDirectory + "double-punch" + animationExtension,
}

export {
  animationsMapping
}
import ToonShader from '../../../shaders/toon/toon-shader'
import * as THREE from 'three'

const limbNames = ["mesh_legs", "mesh_arms"]

const changeModelMaterial = (meshList, material) => {
  meshList.forEach((childMesh) => {    
    childMesh.material = material
    childMesh.material.skinning = true
    childMesh.material.morphTargets = true
  })
}

const getMaterial = (color, textureName, gradientMap, scale, edgeData) => {
  var material
  if (textureName === "Plastic") {
    material = new THREE.MeshStandardMaterial({ color: color, roughness: 0.1, metalness: 0})
  }
  else if (textureName === "Metallic") {
    material = new THREE.MeshStandardMaterial({ color: color, roughness: 0.5, metalness: 1})
  }
  else if (textureName === "Toon") {
    const toonShader = new ToonShader({
      stepTexture: gradientMap,
      texture: undefined,
      useTexture: false,
      modelScale: scale,
      color: new THREE.Vector4(
        color.color.r, 
        color.color.g, 
        color.color.b, 
        1.0
      ),
      shadeColor: (
        color.shadeColor !== undefined ?
        new THREE.Vector4(
          color.shadeColor.r, 
          color.shadeColor.g, 
          color.shadeColor.b, 
          1.0
        ) : null
      ),
      rimColor: (
        color.rimColor !== undefined ?
        new THREE.Vector4(
          color.rimColor.r, 
          color.rimColor.g, 
          color.rimColor.b, 
          1.0
        ) : null
      ),
      edgeWidthRatio: edgeData !== undefined ? edgeData.width : 0.4,
      edgeColor: edgeData !== undefined ? edgeData.color : new THREE.Vector4(0, 0, 0, 1)
    })
    material = new THREE.ShaderMaterial(toonShader)
  }
  else {
    material = new THREE.MeshStandardMaterial({ color: color })
  }
  return material
}

const changeBodyColor = (meshGroup, color, textureName, gradientMap, scale, edgeData) => {
  const bodyMaterial = getMaterial(color, textureName, gradientMap, scale, edgeData)
  changeModelMaterial(meshGroup.children.filter((child) => !limbNames.includes(child.name)), bodyMaterial)
  return bodyMaterial
}

const changeLimbColor = (meshGroup, color, textureName, gradientMap, scale, edgeData) => {
  const limbMaterial = getMaterial(color, textureName, gradientMap, scale, edgeData)
  changeModelMaterial(meshGroup.children.filter((child) => limbNames.includes(child.name)), limbMaterial)
  return limbMaterial
}

export {
  changeBodyColor,
  changeLimbColor
}
import Main from "./components/index"
import Store from "./Store"
require("dotenv").config()

function App() {
  return (
    <Store>
      <Main></Main>
    </Store>
  );
}

export default App;

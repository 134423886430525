import React from 'react'
import { colliderLinkingAtom } from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './link-bone-checkbox.css'

const LinkBoneCheckbox = () => {
  const [colliderLinking, setColliderLinking] = useAtom(colliderLinkingAtom)

  return (
    <label 
      className="link-bone-checkbox__container"
      onClick={() => setColliderLinking((prevState) => { 
        return { bones: !prevState.bones, ml: false, live: false } 
      })}>
      Link Bones
      <span 
        className={`link-bone-checkbox__checkmark${colliderLinking.bones ? " checked" : ""}`}>
      </span>
    </label>
  )
}

export default LinkBoneCheckbox
import React from 'react'
import cubeIcon from '../../../local-assets/collision-maker/available-shapes/cube.png'
import sphereIcon from '../../../local-assets/collision-maker/available-shapes/sphere.png'
import cylinderIcon from '../../../local-assets/collision-maker/available-shapes/cylinder.png'
import './shapes-menu.css'

const ShapesMenu = ({ setCreateRequest }) => {  
  return (
    <>
      <div id="shapes-menu__container">
        {/* <img 
          src={cubeIcon} 
          onClick={() => {
            setCreateRequest("Box")
          }}
          alt="" /> */}
        <img 
          src={sphereIcon} 
          onClick={() => {
            setCreateRequest("Sphere")
          }}
          alt="" />
        {/* <img 
          src={cylinderIcon} 
          onClick={() => {
            setCreateRequest("Cylinder")
          }}
          alt="" /> */}
      </div>
    </>
  )
}

export default ShapesMenu

import { matrixMultiplication } from "../machine-learning/neural-network/matrix-math"

const processRotation = (rotation) => {
  rotation = rotation >= 0 ? rotation : rotation + Math.PI
  return rotation / (Math.PI * 2)
}

const getYawMatrix = (alpha) => {
  return [
    [1, 0, 0],
    [0, Math.cos(alpha), -Math.sin(alpha)],
    [0, Math.sin(alpha), Math.cos(alpha)]
  ]
}

const getPitchMatrix = (beta) => {
  return [
    [Math.cos(beta), 0, Math.sin(beta)],
    [0, 1, 0],
    [-Math.sin(beta), 0, Math.cos(beta)]
  ]
}

const getRollMatrix = (gamma) => {
  return [
    [Math.cos(gamma), -Math.sin(gamma), 0],
    [Math.sin(gamma), Math.cos(gamma), 0],
    [0, 0, 1]
  ]
}

const getRotationMatrix = (alpha, beta, gamma) => {
  const yaw = getYawMatrix(alpha)
  const pitch = getPitchMatrix(beta)
  const roll = getRollMatrix(gamma)
  return matrixMultiplication(matrixMultiplication(yaw, pitch), roll)
}

const getInputs = (rigData, modelInputs, useRotation) => {
  const inputs = []
  var currentPosition
  var currentRotation
  var rotationMatrix
  for (var i = 0; i < modelInputs.length; i++) {
    currentPosition = rigData[modelInputs[i]].position
    inputs.push(currentPosition.x)
    inputs.push(currentPosition.y)
    inputs.push(currentPosition.z)
    if (useRotation) {
      currentRotation = rigData[modelInputs[i]].rotation
      rotationMatrix = getRotationMatrix(
        currentRotation.x,
        currentRotation.y,
        currentRotation.z,
      )
      inputs.push(rotationMatrix[0][0])
      inputs.push(rotationMatrix[0][1])
      inputs.push(rotationMatrix[1][0])
      inputs.push(rotationMatrix[1][1])
      inputs.push(rotationMatrix[2][0])
      inputs.push(rotationMatrix[2][1])
    }
  }
  // console.log(inputs)
  return inputs
}

const getOutputs = (inputs, model) => {
  const outputs = {}
  const currentOutput = model.forwardProp([inputs])[0][0]
  outputs[model.output] = {
    x: currentOutput[0],
    y: currentOutput[1],
    z: currentOutput[2]
  }
  return outputs
}

export {
  getInputs,
  getOutputs
}
import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { animationsMapping } from '../../../helpers/collision-maker/animation-mapping'
import './animation-menu.css'

const AnimationMenu = ({ 
  animation,
  setAnimation,
  setTotalFrames, 
  animationFrame,
  setAnimationFrame, 
  animationsReady, 
  animationData 
}) => {
  const positionStyling = {
    top: "10px",
    left: "20px"
  }
  const animationOptions = [
    ...Object.keys(animationsMapping).flatMap((key) => {
      return Object.values(animationsMapping[key].names).map((name) => {
        return { name: name, label: name.split("-").join(" ") }
      })
    })
  ]

  const changeAnimation = (newAnimation) => {
    animationData.animations[animation.name].action.stop()
    setAnimation(newAnimation)
  }

  useEffect(() => {
    setAnimation(animationOptions[0])
  }, [])

  useEffect(() => {
    if (
      animationsReady && 
      animation !== undefined && 
      animationData !== undefined
    ) {
      setTotalFrames(animationData.animations[animation.name].frameCount)
      animationData.animations[animation.name].action.play()
      animationData.animations[animation.name].action.time = 0
      setAnimationFrame(0)
      animationData.mixer.update(0)
    }
  }, [animation, animationData, animationsReady])

  useEffect(() => {
    if (animationData !== undefined) {
      const roundingError = 1 / 600
      animationData.animations[animation.name].action.play()
      animationData.animations[animation.name].action.time = 0
      animationData.mixer.update(Math.max(animationFrame / 60 - roundingError, 0))
    }
  }, [animationFrame])

  return (
    <>
      {
        animation !== undefined && animationData !== undefined &&
        <Dropdown
          containerId="animation-menu__container"
          dropdownId="animation-menu-dropdown"
          selection={animation}
          clickFunction={changeAnimation}
          options={animationOptions}
          positionStyling={positionStyling}>
        </Dropdown>
      }
    </>
  )
}

export default AnimationMenu
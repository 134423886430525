import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { authServer } from "../../../env-variables"
import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const useSignedInSession = () => {
  const history = useHistory()
  const [session, setSession] = useState(null)
  const [sessionExists, setSessionExists] = useState(false)

  useEffect(() => {
    const getSession = async () => {
      try {
        const response = await fetch(`${authServer}api/auth/session`, {
          credentials: "include",
        })

        if (!response.ok) {
          console.error("Request failed with status: " + response.status)
          history.push(`${authServer}?redirect=builder`)
          return
        }

        const data = await response.json()

        if (Object.keys(data).length === 0) {
          console.log("no session, redirecting to auth server")
          history.push(`${authServer}?redirect=builder`)
          setSessionExists(false)
        } else {
          console.log("session active")
          if (data.jwt) {
            cookies.set("__Development-next-auth.session-token", data.jwt, { domain: "localhost" })
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.jwt
          }
          setSession(data)
          setSessionExists(true)
        }
      } catch (error) {
        console.error(error)
        console.log("Error, redirecting to auth server")
        window.location.href = `${authServer}?redirect=builder`

      }
    }

    getSession()
  }, [])

  return { sessionExists, session }
}

export { useSignedInSession }

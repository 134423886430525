const headIdxToDetails = {
  1: { name: "New Head 1", rarity: "Bronze", probability: 0.15 },  
  2: { name: "New Head 2", rarity: "Gold", probability: 0.07 },
  3: { name: "New Head 3", rarity: "Gold", probability: 0.07 },
  4: { name: "New Head 4", rarity: "Gold", probability: 0.07 },
  5: { name: "New Head 5", rarity: "Gold", probability: 0.07 },
  6: { name: "New Head 6", rarity: "Bronze", probability: 0.15 },  
  7: { name: "New Head 7", rarity: "Bronze", probability: 0.15 },  
  8: { name: "New Head 8", rarity: "Bronze", probability: 0.15 },  
  9: { name: "New Head 9", rarity: "Bronze", probability: 0.15 },
  10: { name: "New Head 10", rarity: "Bronze", probability: 0.15 },      
}

const eyesIdxToDetails = {  
  1: { name: "New Eyes 1", rarity: "Bronze", probability: 0.15 },  
  2: { name: "New Eyes 2", rarity: "Bronze", probability: 0.15 },  
  3: { name: "New Eyes 3", rarity: "Bronze", probability: 0.15 },  
  4: { name: "New Eyes 4", rarity: "Bronze", probability: 0.15 },  
  5: { name: "New Eyes 5", rarity: "Bronze", probability: 0.15 },  
  6: { name: "New Eyes 6", rarity: "Bronze", probability: 0.15 },  
  7: { name: "New Eyes 7", rarity: "Bronze", probability: 0.15 },  
  8: { name: "New Eyes 8", rarity: "Bronze", probability: 0.15 },  
}

const mouthIdxToDetails = {  
  1: { name: "New Mouth 1", rarity: "Bronze", probability: 0.15 },  
  2: { name: "New Mouth 2", rarity: "Bronze", probability: 0.15 },  
  3: { name: "New Mouth 3", rarity: "Bronze", probability: 0.15 },  
  4: { name: "New Mouth 4", rarity: "Bronze", probability: 0.15 },  
  5: { name: "New Mouth 5", rarity: "Bronze", probability: 0.15 },  
  6: { name: "New Mouth 6", rarity: "Bronze", probability: 0.15 },  
  7: { name: "New Mouth 7", rarity: "Bronze", probability: 0.15 },  
  8: { name: "New Mouth 8", rarity: "Bronze", probability: 0.15 },  
  9: { name: "New Mouth 9", rarity: "Bronze", probability: 0.15 },  
  10: { name: "New Mouth 10", rarity: "Bronze", probability: 0.15 },  
}

const bodyIdxToDetails = {
  1: { name: "New Body 1", rarity: "Bronze", probability: 0.15 },  
  2: { name: "New Body 2", rarity: "Gold", probability: 0.07 },
  3: { name: "New Body 3", rarity: "Gold", probability: 0.07 },
  4: { name: "New Body 4", rarity: "Gold", probability: 0.07 },
  5: { name: "New Body 5", rarity: "Gold", probability: 0.07 },
  6: { name: "New Body 6", rarity: "Bronze", probability: 0.15 },  
  7: { name: "New Body 7", rarity: "Gold", probability: 0.07 },
  8: { name: "New Body 8", rarity: "Gold", probability: 0.07 },
  9: { name: "New Body 9", rarity: "Gold", probability: 0.07 },
  10: { name: "New Body 10", rarity: "Gold", probability: 0.07 },
}

const handsIdxToDetails = {
  1: { name: "New Hands 1", rarity: "Gold", probability: 0.07 },
  2: { name: "New Hands 2", rarity: "Gold", probability: 0.07 },
  3: { name: "New Hands 3", rarity: "Gold", probability: 0.07 },
  4: { name: "New Hands 4", rarity: "Gold", probability: 0.07 },
  5: { name: "New Hands 5", rarity: "Gold", probability: 0.07 },
  6: { name: "New Hands 6", rarity: "Bronze", probability: 0.15 },  
  7: { name: "New Hands 7", rarity: "Gold", probability: 0.07 },
  8: { name: "New Hands 8", rarity: "Gold", probability: 0.07 },
  9: { name: "New Hands 9", rarity: "Gold", probability: 0.07 },
  10: { name: "New Hands 10", rarity: "Gold", probability: 0.07 },
}

const feetIdxToDetails = {
  1: { name: "New Feet 1", rarity: "Gold", probability: 0.07 },
  2: { name: "New Feet 2", rarity: "Gold", probability: 0.07 },
  3: { name: "New Feet 3", rarity: "Gold", probability: 0.07 },
  4: { name: "New Feet 4", rarity: "Gold", probability: 0.07 },
  5: { name: "New Feet 5", rarity: "Gold", probability: 0.07 },
  6: { name: "New Feet 6", rarity: "Bronze", probability: 0.15 },  
  7: { name: "New Feet 7", rarity: "Gold", probability: 0.07 },
  8: { name: "New Feet 8", rarity: "Gold", probability: 0.07 },
  9: { name: "New Feet 9", rarity: "Gold", probability: 0.07 },
  10: { name: "New Feet 10", rarity: "Gold", probability: 0.07 },
}

const limbsIdxToDetails = {  

}

const skinIdxToDetails = {

}

const attributeMapping = {
  Head: headIdxToDetails,
  Eyes: eyesIdxToDetails,
  Mouth: mouthIdxToDetails,
  Body: bodyIdxToDetails,
  Hands: handsIdxToDetails,
  Feet: feetIdxToDetails,
  Limbs: limbsIdxToDetails,
  Skin: skinIdxToDetails
}

export default attributeMapping
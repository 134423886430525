import { Route, Switch } from "react-router-dom"
import GatedRoute from "./GatedRoute"

import LandingPage from "./landing-page-components/landing-page/LandingPage"
import FighterCollisionMaker from "./collision-maker-components/fighter-collisions/FighterCollisionMaker"
import SilhouetteMaker from "./silhouette-maker-components/silhouette-maker/SilhouetteMaker"
import AnimationPlayer from "./animation-player-components/animation-player/AnimationPlayer"
import PhotoBooth from "./photo-booth-components/photo-booth/PhotoBooth"
import DressingRoom from "./dressing-room-components/dressing-room/DressingRoom"
import VFXBuilder from "./vfx-builder-components/vfx-builder/VFXBuilder"
import SaveImages from "./save-images-components/save-images/SaveImages"
import MarketingStaging from "./marketing-staging-components/marketing-staging/MarketingStaging"
import Error from "./generic-components/error-page/Error"

const allAccessPoints = {
  team: { basic: true },
  development: { basic: true, dev: true },
  noAccess: { basic: false }
}

const createRoutes = (accessKey, sessionExists) => {
  const accessPoint = allAccessPoints[accessKey]
  if (accessPoint === undefined) return <></>

  return <>
    <Switch>
      {/* Basic Access */}
      <GatedRoute exact path='/' access={accessPoint.basic} sessionExists={sessionExists}>
        <LandingPage />
      </GatedRoute>

      <GatedRoute exact path="/animation-player" access={accessPoint.basic} sessionExists={sessionExists}>
        <AnimationPlayer />
      </GatedRoute>
      <GatedRoute exact path="/photo-booth" access={accessPoint.basic} sessionExists={sessionExists}>
        <PhotoBooth />
      </GatedRoute>
      <GatedRoute exact path="/silhouette-maker" access={accessPoint.basic} sessionExists={sessionExists}>
        <SilhouetteMaker />
      </GatedRoute>
      <GatedRoute exact path="/dressing-room" access={accessPoint.basic} sessionExists={sessionExists}>
        <DressingRoom />
      </GatedRoute>
      <GatedRoute exact path="/vfx-builder" access={accessPoint.basic} sessionExists={sessionExists}>
        <VFXBuilder />
      </GatedRoute>
      <GatedRoute exact path="/marketing-staging" access={accessPoint.basic} sessionExists={sessionExists}>
        <MarketingStaging />
      </GatedRoute>

      {/* Dev Access */}
      <GatedRoute exact path="/fighter-collision-maker" access={accessPoint.dev} sessionExists={sessionExists}>
        <FighterCollisionMaker />
      </GatedRoute>
      <GatedRoute exact path="/save-images" access={accessPoint.dev} sessionExists={sessionExists}>
        <SaveImages />
      </GatedRoute>

      {/* Public Routes */}
      <Route path="*">
        <Error />
      </Route>
    </Switch>
  </>
}

export default createRoutes

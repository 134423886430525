const probabilisticSampling = (modelOutput) => {
  var randomNumber
  var selectedAction
  var counter
  const selectedActionsMatrix = modelOutput.map((currentActionProbabilities) => {
    randomNumber = Math.random()
    selectedAction = 0
    counter = 0
    while (selectedAction < currentActionProbabilities.length - 1) {
      counter += currentActionProbabilities[selectedAction]
      if (randomNumber <= counter) {
        break
      }
      else {
        selectedAction++
      }
    }
    return selectedAction
  })
  return selectedActionsMatrix
}

const argmax = (array) => {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1]
}

const argmaxPolicy = (modelOutput) => {
  const selectedActionsMatrix = modelOutput.map((currentModelOutputs) => {
    return argmax(currentModelOutputs)
  })
  return selectedActionsMatrix
}

const epsilonGreedy = (modelOutput) => {
  const epsilon = 0.1
  const selectedActionsMatrix = modelOutput.map((currentModelOutputs) => {
    if (Math.random() < epsilon) {
      return Math.min(Math.floor(Math.random() * currentModelOutputs.length), currentModelOutputs.length - 1)
    }
    else {
      return argmax(currentModelOutputs)
    }    
  })
  return selectedActionsMatrix
}

const policyMapping = {
  probabilisticSampling,
  epsilonGreedy,
  argmaxPolicy  
}

const otherActionsOrder = [
  "grab",
  "shield",
  "jump",
  "punch",
  "special",
  "nothing"
]

const discreteOther = (otherSelection) => {
  const otherActions = {}
  otherActionsOrder.forEach((actionName, idx) => {
    otherActions[actionName] = otherSelection === idx
  })
  return otherActions
}

const discreteDirection = (directionSelection) => {
  return {
    up: directionSelection === 0,
    down: directionSelection === 1,
    left: directionSelection === 2,
    right: directionSelection === 3,
    nothing: directionSelection === 4
  }
}

const getAnalogAxes = (directionOutput) => {
  const [rawX, rawY] = directionOutput
  const magnitude = Math.sqrt(rawX**2 + rawY**2)
  const scalingFactor = Math.min(1, 1 / magnitude)
  const x = rawX * scalingFactor
  const y = rawY * scalingFactor
  return [x, y]
}

const analogDirection = (directionOutput) => {
  const [x, y] = getAnalogAxes(directionOutput)
  return {
    up: y < 0 ? Math.abs(y) : 0,
    down: y > 0 ? y : 0,
    right: x > 0 ? x : 0,
    left: x < 0 ? Math.abs(x) : 0
  }
}

const actionMapping = {
  discreteOther,
  discreteDirection,
  analogDirection
}

export {
  otherActionsOrder,
  getAnalogAxes,
  policyMapping,
  actionMapping
}
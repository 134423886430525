const hitboxMappings = {
  "backAerialKick": "back-aerial-kick",
  "doublePunch": "double-punch",
  "flipKick": "flip-kick",
  "flyingKick": "flying-kick",
  "frontKick": "front-kick",
  "grabAttack": "grab-attack",
  "grabStart": "grab-start",
  "headbuttEnd": "headbutt-end",
  "headbutt": "headbutt",
  "kickAerial": "kick-aerial",
  "lowSweep": "low-sweep",
  "lowSweepFromCrouch": "low-sweep-from-crouch",
  "punchAerial": "punch-aerial",
  "punchDownAerialStart": "punch-down-aerial-start",
  "punchDownAerialHold": "punch-down-aerial-hold",
  // "punchDownAerialEnd": "punch-down-aerial-end",
  "punch": "punch",
  "throwBack": "throw-back",
  "throwDown": "throw-down",
  "throwForward": "throw-forward",
  "throwUp": "throw-up",
  "uppercutAerial": "uppercut-aerial",
  "uppercutGround": "uppercut-ground"
}

const reverseHitboxMappings = Object.fromEntries(
  Object.entries(hitboxMappings).map(([key, value]) => [value, key])
)

const agnesHitboxMappings = {
  "leftPunch": "left-punch",
  "rightPunch": "right-punch",
  "twoHandsSlap": "two-hands-slap"
}

const agnesReverseHitboxMappings = Object.fromEntries(
  Object.entries(agnesHitboxMappings).map(([key, value]) => [value, key])
)

const allReverseHitboxMappings = {
  "fighter": reverseHitboxMappings,
  "agnes-ultimate": agnesReverseHitboxMappings
}

export {
  agnesHitboxMappings,
  hitboxMappings,
  reverseHitboxMappings,
  allReverseHitboxMappings
}
import React from 'react'
import { colliderNameAtom, colliderSelectedAtom } from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './collider-name-input.css'

const ColliderNameInput = () => {
  const [colliderName, setColliderName] = useAtom(colliderNameAtom)
  const [colliderSelected, setColliderSelected] = useAtom(colliderSelectedAtom)

  const changeColliderName = () => {
    const newName = document.getElementById("collider-name-input").value
    if (newName !== "") {
      setColliderName(newName)
      setColliderSelected((prevState) => {
        prevState.name = newName
        return prevState
      })
    }
  }

  return (
    <>
      {
        colliderSelected !== undefined &&
        <div id="collider-name-input__container">
          {
            colliderName !== undefined &&
            <p id="collider-name">{colliderName}</p>
          }
          {
            colliderName === undefined &&
            <>
              <input 
                id="collider-name-input"
                type="text"
                maxLength="100"
                placeholder="mesh-name"
              />
              <div 
                id="collider-name-input__button"
                onClick={() => changeColliderName()}>
                <p>SET</p>
              </div>
            </>
          }
        </div>
      }
    </>
  )
}

export default ColliderNameInput
import React, { useState, useEffect } from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import skinColorMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/skin'
import limbColorMetadata from '../../../helpers/asset-management/fighter-accessories/metadata/limb'
import * as THREE from 'three'
import './color-picker.css'

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const presetMappings = {
  skin: {
    guppy: skinColorMetadata["skin-1-2"],
    artist: skinColorMetadata["skin-1-2"],
    gwei: skinColorMetadata["skin-1-1"],
    marshall: skinColorMetadata["skin-marshall"],
    bo: skinColorMetadata["skin-bo"],
  },
  limbs: {
    guppy: skinColorMetadata["skin-1-2"],
    artist: skinColorMetadata["skin-1-2"],
    gwei: skinColorMetadata["skin-1-1"],
    marshall: limbColorMetadata["limbs-marshall"],
    bo: limbColorMetadata["limbs-bo"],
  }
}


const ColorPicker = ({ attributeType, preset, environment, attributeMapping }) => {
  const [currentAttribute, setCurrentAttribute] = useState(undefined)
  const [attributeMetadata, setAttributeMetadata] = useState(undefined)

  const series = 1

  const currentMapping = attributeMapping[series][capitalize(attributeType)]
  const currentMetadata = attributeType === "skin" ? skinColorMetadata : limbColorMetadata
  const baseMaterialMesh = attributeType === "skin" ? "mesh_head" : "mesh_arms"
  const options = [
    ...Object.keys(currentMapping).map((id) => {
      return { 
        name: currentMapping[id].name.split(" ").join("-"), 
        label: currentMapping[id].name, 
        id: id 
      }
    })
  ]

  const changeAttribute = (newAttribute) => {
    setCurrentAttribute(newAttribute)
    setAttributeMetadata(currentMetadata[`${attributeType}-${series}-${newAttribute.id}`])
  }

  useEffect(() => {
    setCurrentAttribute(options[0])
  }, [])

  const colorToV4 = (c) => {
    return new THREE.Vector4(c.r, c.g, c.b, 1)
  }

  useEffect(() => {
    if (attributeMetadata !== undefined) {
      const baseMaterial = environment.character.getObjectByName(baseMaterialMesh).material
      baseMaterial.uniforms.color.value = colorToV4(attributeMetadata.color)
      baseMaterial.uniforms.shadeColor.value = colorToV4(attributeMetadata.shadeColor)
      baseMaterial.uniforms.rimColor.value = colorToV4(attributeMetadata.rimColor)
    }
  }, [attributeMetadata])

  useEffect(() => {
    if (preset.name !== "none") {
      setAttributeMetadata(presetMappings[attributeType][preset.name])
    }
  }, [preset])

  return (
    <>
      {
        currentAttribute !== undefined &&
        <Dropdown
          containerId={`color-picker__container--${attributeType}`}
          dropdownId={`color-picker-dropdown--${attributeType}`}
          selection={currentAttribute}
          clickFunction={changeAttribute}
          options={options}
          positionStyling={{}}>
        </Dropdown>
      }
    </>
  )
}

export default ColorPicker
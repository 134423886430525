import React from 'react'
import Dropdown from '../../generic-components/dropdown/Dropdown'
import { 
  animationAtom,
  colliderTypeAtom
} from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import './collider-type-menu.css'

const ColliderTypeMenu = () => {
  const [colliderType, setColliderType] = useAtom(colliderTypeAtom)
  const animation = useAtom(animationAtom)[0]
  const aPoseBool = animation?.name === "a-pose"

  const colliderOptions = [    
    { name: "hurtbox", label: "hurtbox" }
  ]
  if (!aPoseBool) {
    colliderOptions.push({ name: "hitbox", label: "hitbox" })
  }

  return (
    <>
      {
        colliderType !== undefined &&
        <Dropdown
          containerId="collider-type-menu__container"
          dropdownId="collider-type-menu-dropdown"
          selection={colliderType}
          clickFunction={setColliderType}
          options={colliderOptions}
          optionsStyling={{}}>
        </Dropdown>
      }
    </>
  )
}

export default ColliderTypeMenu
import animations from "./animations"
import models from "./models"
import textures from "./textures"

export default function getSources(name) {
  let sources = []

  addToSources(models, sources, name)
  addToSources(animations, sources, name)
  addToSources(textures, sources, name)

  return sources
}

export function getModelSources(name) {
  let sources = []

  addToSources(models, sources, name)

  return sources
}

export function getAnimationSources(name) {
  let sources = []

  addToSources(animations, sources, name)

  return sources
}

export function getTextureSources(name) {
  let sources = []

  addToSources(textures, sources, name)

  return sources
}

function addToSources(resources, sources, name) {
  for (const resource of resources) {
    const resourceToAdd = add(resource, name)

    if (resourceToAdd) {
      sources.push(resourceToAdd)
    }
  }
}

function add(resource, name) {
  for (const tag of resource.tags) {
    if (tag === name) {
      return resource
    }
  }

  return null
}

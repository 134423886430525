import React, { useState, useEffect } from 'react'
import { flattenParameters } from '../../../helpers/machine-learning/neural-network/matrix-math'
import { checkValidModel } from '../../../helpers/machine-learning/neural-network/upload-model'
import { neuralNetwork } from '../../../helpers/machine-learning/neural-network/neural-network'
import { fighterTypeAtom, hurtboxLinksAtom } from '../../../helpers/collision-maker/atoms'
import { useAtom } from 'jotai'
import checkmark from '../../../local-assets/collision-maker/tick-mark.png'
import './upload-model.css'

const UploadModel = ({ modelData, setModelData }) => {
  const fighterType = useAtom(fighterTypeAtom)[0]
  const hurtboxLinks = useAtom(hurtboxLinksAtom)[0]

  const [files, setFiles] = useState(undefined)
  const [modelSpecs, setModelSpecs] = useState([])

  const handleFile = (e) => {    
    setFiles(e.target.files)
  }

  useEffect(() => {
    if (files !== undefined) {
      checkValidModel(files, setModelSpecs)
    }
  }, [files])

  useEffect(() => {
    if (modelSpecs.length > 0) {
      var currentSpecs
      for (var i = 0; i < modelSpecs.length; i++) {
        currentSpecs = modelSpecs[i]
        const neuralNetworkModel = new neuralNetwork(
          currentSpecs.n_features, 
          currentSpecs.neurons, 
          currentSpecs.n_targets, 
          flattenParameters(currentSpecs.weights), 
          flattenParameters(currentSpecs.biases),
          { outputActivation: "Linear", activationFunctionName: "elu" }
        )
        neuralNetworkModel.useRotation = currentSpecs.rotation_included
        neuralNetworkModel.featuresUsed = currentSpecs.features_used
        neuralNetworkModel.output = currentSpecs.hurtbox
        setModelData((prevState) => {
          prevState[currentSpecs.hurtbox] = neuralNetworkModel
          return { ...prevState }
        })
      }
    }
  }, [modelSpecs])

  return (
    <>
      <div id="upload-model__container">
        <input 
          type="file" 
          onChange={(e) => handleFile(e)}
          onClick={(e) => e.target.value = null}
          multiple/>
        <h2>Upload Model</h2>
      </div>

      <div id="upload-model__status-container">
        {
          hurtboxLinks[fighterType] !== undefined && 
          Object.keys(hurtboxLinks[fighterType]).map((hurtboxName) => {
            return (
              <div 
                className="upload-model__status-model" 
                key={`upload-${hurtboxName}`}>
                <p>{hurtboxName}</p>
                <img 
                  src={checkmark} 
                  style={{opacity: modelData[hurtboxName] !== undefined ? 1 : 0}} 
                  alt="" />
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default UploadModel
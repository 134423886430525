import { useEffect } from 'react'
import { moveHurtboxWithBone } from '../../collision-maker/bone-operations'
import { 
  animateHurtboxesWithML,
  animateSavedHurtboxes 
} from '../../collision-maker/collider-animations'
import { doesMeshExist, addMeshToScene } from '../../collision-maker/collider-operations'
import { 
  savedFrameAtom, 
  animationAtom, 
  animationFrameAtom,
  colliderSelectedAtom,
  colliderTypeAtom,
  meshesInSceneAtom,
  sceneAtom,
  hurtboxLinksAtom,
  fighterTypeAtom,
  colliderLinkingAtom,
  liveFrameAtom
} from '../../collision-maker/atoms'
import { useAtom } from 'jotai'

const useHurtboxSetup = (modelData) => {
  const scene = useAtom(sceneAtom)[0]
  const animation = useAtom(animationAtom)[0]
  const liveFrameData = useAtom(liveFrameAtom)[0]
  const savedFrameData = useAtom(savedFrameAtom)[0]
  const animationFrame = useAtom(animationFrameAtom)[0]
  const colliderType = useAtom(colliderTypeAtom)[0]
  const hurtboxLinks = useAtom(hurtboxLinksAtom)[0]
  const fighterType = useAtom(fighterTypeAtom)[0]
  const setColliderSelected = useAtom(colliderSelectedAtom)[1]
  const [meshesInScene, setMeshesInScene] = useAtom(meshesInSceneAtom)
  const colliderLinking = useAtom(colliderLinkingAtom)[0]

  useEffect(() => {
    const links = hurtboxLinks[fighterType]
    if (links !== undefined) {
      if (animation !== undefined && (colliderLinking.bones || animation.name === "a-pose")) {
        Object.keys(links).forEach((currentColliderName) => {
          if (!doesMeshExist(currentColliderName, meshesInScene)) {
            addMeshToScene(
              links[currentColliderName].shape, 
              {
                position: {...links[currentColliderName].positionOffset},
                rotation: {...links[currentColliderName].startingRotation},
                scale: {...links[currentColliderName].scale},
                name: currentColliderName
              }, 
              setMeshesInScene, 
              setColliderSelected, 
              scene
            )
            moveHurtboxWithBone(links[currentColliderName], currentColliderName, scene)
          }
        })
      }
      else if (animation !== undefined && colliderLinking.ml) {
        animateHurtboxesWithML(
          modelData, 
          links, 
          meshesInScene, 
          setMeshesInScene,
          setColliderSelected, 
          scene
        )
      }
      else if (animation !== undefined && savedFrameData[fighterType]?.[animation.name]) {
        animateSavedHurtboxes(
          animation.name, 
          animationFrame, 
          colliderLinking.live ? liveFrameData[fighterType] : savedFrameData[fighterType], 
          meshesInScene, 
          setMeshesInScene, 
          setColliderSelected,
          colliderType,
          links,
          scene
        )
      }
    }
  }, [hurtboxLinks[fighterType], colliderLinking])
}

export {
  useHurtboxSetup
}
const rimLightFragmentPrepend = `
  uniform float rimStep;
  uniform vec3 rimColor;

  varying vec3 vPositionW;
  varying vec3 vNormalW;
`

const rimLightFragmentContent = `
  float basePortion = -min(dot(vPositionW, normalize(vNormalW) ), 0.0);
  basePortion = step(rimStep, basePortion);
  float rimPortion = ( 1.0 - basePortion );
  rim = rimColor * rimPortion;
`

export {
  rimLightFragmentPrepend,
  rimLightFragmentContent
}
import { atom } from "jotai"

// Frame atoms
export const savedFrameAtom = atom({})
export const liveFrameAtom = atom({})
export const totalFramesAtom = atom(0)
export const animationFrameAtom = atom(0)

// Animation atoms
export const animationAtom = atom(undefined)
export const animationDataAtom = atom(undefined)
export const animationReadyAtom = atom(false)

// Collider atoms
export const colliderTypeAtom = atom({ name: "hurtbox", label: "hurtbox" })
export const colliderNameAtom = atom(undefined)
export const colliderSelectedAtom = atom(undefined)
export const manipulationAtom = atom(undefined)
export const boneSelectedAtom = atom(undefined)
export const hurtboxLinksAtom = atom({})

// 3D Object atoms
export const environmentAtom = atom(undefined)
export const sceneAtom = atom(undefined)
export const cameraAtom = atom(undefined)
export const meshesInSceneAtom = atom([])

// Other atoms
export const fighterTypeAtom = atom("fighter")
export const mouseTrackerAtom = atom(undefined)
export const colliderLinkingAtom = atom({
  bones: false,
  ml: false,
  live: false
})
import { backend } from '../../../env-variables'
import axios from 'axios'

async function getLiveColliderFrames(fighterType) {
  const liveFrames = await axios.get(`${backend}frame-data/load?character=${fighterType}`)
  return liveFrames.data
}

async function getAnimationFrames(fighterType) {
  const animationFrames = await axios.get(`${backend}animation-data/frame-data?character=${fighterType}`)
  return animationFrames.data
}

async function getLinkedHurtboxes(fighterType) {
  const linkedHurtboxes = await axios.get(`${backend}animation-data/linked-hurtboxes?character=${fighterType}`)
  return linkedHurtboxes.data[0]
}

async function getColliderAccess(colliderType) {
  if (colliderType === "hurtbox" || colliderType === "hitbox") {
    const access = await axios.get(
      `${backend}animation-data/has-${colliderType}-access`
    )
    return access.data
  }
}

export {
  getLiveColliderFrames,
  getAnimationFrames,
  getLinkedHurtboxes,
  getColliderAccess
}
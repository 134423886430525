import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import createScene from "../../helpers/threejs/scene-setup"
import { setUpBloom, renderBloom } from "../../helpers/threejs/selective-bloom-rendering"
import { updateToonEdge, updateToonShaderLight } from '../../helpers/threejs/toon-rendering'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import fighterBodyFile from "../../local-assets/gameplay/characters/base-body.fbx"
import robotBodyFile from "../../local-assets/gameplay/characters/robot-body-fixed.fbx"
import gradientMapFile from "../../local-assets/textures/toon-gradient-map/toon-gradient-map.png"
import characterModifierMapping from "../../helpers/model-modifiers/characters/character-modifier-mapping"
import Resources from "../../helpers/resource-management/resources"
import hexagonBackground from "../asset-management/backgrounds/hexagon-background"
import { 
  storageUrl, 
  animationsMapping, 
  auAnimationsMapping, 
  attackDroneAnimationsMapping, 
  defenseDroneAnimationsMapping,
  agilityDroneAnimationsMapping
} from "./animation-mapping"
import * as THREE from "three"

const baseUrl = "https://ai-arena.b-cdn.net/"

const listOfCharacters = [
  "fighter",
  "agnes-ultimate",
  "attack-drone",
  "defense-drone",
  "agility-drone"
]

const animationFiles = {
  "fighter": animationsMapping,
  "agnes-ultimate": auAnimationsMapping,
  "attack-drone": attackDroneAnimationsMapping,
  "defense-drone": defenseDroneAnimationsMapping,
  "agility-drone": agilityDroneAnimationsMapping
}

const animationLoaderClasses = {
  "fighter": GLTFLoader,
  "agnes-ultimate": GLTFLoader,
  "attack-drone": GLTFLoader,
  "defense-drone": GLTFLoader,
  "agility-drone": GLTFLoader,
}

const armatureMapping = {
  "fighter": "Armature",
  "agnes-ultimate": "jnt_org",
  "attack-drone": "jnt_org",
  "defense-drone": "jnt_org",
  "agility-drone": "jnt_org"
}

const roundFrameSpeed = (frameSpeed, decimalPrecision, roundFunction) => {
  roundFunction = roundFunction !== undefined ? roundFunction : Math.round
  return (
    roundFunction(frameSpeed * 10 ** decimalPrecision) / 10 ** decimalPrecision
  )
}

const applySolidColor = (fbx, allMeshes) => {
  for (var m = 0; m < fbx.children.length; m++) {
    if (fbx.children[m].type.includes("Mesh")) {
      fbx.children[m].material = new THREE.MeshBasicMaterial({ color: 0x00ffff })
      allMeshes.push(fbx.children[m])
    }
  }
}

const loadFighter = (scene, resources, allMeshes, cachedCharacters, modelName, solidBool=false) => {
  const fighterScale = 0.017
  const fbx = resources.items[modelName]
  fbx.scale.setScalar(fighterScale)
  fbx.position.set(0, -0.6, 0)
  // fbx.rotation.y = Math.PI / 2
  // fbx.getObjectByName("Armature").rotation.y = Math.PI / 2
  fbx.name = listOfCharacters[0]

  if (solidBool) {
    applySolidColor(fbx, allMeshes)
  }
  else {
    const accessoryData = {
      series: 1,
      eyesId: "guppy",
      mouthId: "guppy",
      headId: "guppy",
      bodyId: "guppy",
      handsId: "guppy",
      feetId: "guppy",      
      bodyColor: {
        color: new THREE.Color(0xd99c86),
        shadeColor: new THREE.Color(0xb37964),
        rimColor: new THREE.Color(0xf5c4b3)
      },        
      limbColor: {
        color: new THREE.Color(0xd99c86),
        shadeColor: new THREE.Color(0xb37964),
        rimColor: new THREE.Color(0xf5c4b3)
      },
      composableId: 2,
      nakedFighter: true,
    }
    characterModifierMapping[modelName](fbx, resources, accessoryData, allMeshes)
  }
  scene.add(fbx)
  cachedCharacters[fbx.name] = fbx
  return fbx
}

const loadAgnesUltimate = (scene, resources, allMeshes, cachedCharacters) => {
  const scale = 0.017
  const fbx = resources.items["agnesUltimate"]
  fbx.scale.setScalar(scale)
  fbx.position.set(0, -0.6, 0)
  
  const material = new THREE.MeshToonMaterial({ map: resources.items["agnesUltimateTexture"] })
  
  const agnesBody = fbx.getObjectByName("geo_Agnes")
  applySolidColor(agnesBody, allMeshes)
  const ultimateBody = fbx.getObjectByName("geo_Ultimate")
  ultimateBody.traverse((child) => {
    if (child instanceof THREE.Mesh) {
      child.material = material
      allMeshes.push(child)
    }
  })

  fbx.name = listOfCharacters[1]
  scene.add(fbx)
  cachedCharacters[fbx.name] = fbx
  return fbx
}

const loadDrone = (scene, resources, allMeshes, cachedCharacters, functionality) => {
  const scale = 0.017
  const drone = resources.items[`${functionality}Drone`]
  drone.position.set(0, -0.6, 0)
  drone.scale.setScalar(scale)
  drone.name = `${functionality}-drone`
  drone.rotation.y = (Math.PI / 2) * 0.5

  drone.children[1].children.forEach((child) => {
    if (child instanceof THREE.Mesh) {
      allMeshes.push(child)
    }
  })

  cachedCharacters[drone.name] = drone
  scene.add(drone)
  return drone
}

const loadNewCharacter = (name, scene, resources, allMeshes, cachedCharacters) => {
  if (cachedCharacters[name]) {
    scene.add(cachedCharacters[name])
    return cachedCharacters[name]
  }
  else {
    const commonInputs = [scene, resources, allMeshes, cachedCharacters]
    if (name === "fighter") {
      return loadFighter(...commonInputs, "fighter")
    }
    else if (name === "agnes-ultimate") {
      return loadAgnesUltimate(...commonInputs)
    }
    else if (name.includes("-drone")) {
      return loadDrone(...commonInputs, name.replace("-drone", ""))
    }
  }
}

export default class CollisionMakerScene {
  constructor(setAnimationsReady, setAnimationData, setMouseTracker) {
    this.initialize(setAnimationsReady, setAnimationData, setMouseTracker)
  }

  initialize(setAnimationsReady, setAnimationData, setMouseTracker) {
    ;[this.scene, this.sizes, this.camera, this.threejs] = createScene({
      containerId: "fighter-collision-maker",
      camera: { fov: 25, near: 0.01, far: 1000 },
    })

    this.setAnimationsReady = setAnimationsReady 
    this.setAnimationData = setAnimationData

    this.targetFPS = 30
    this.fpsDecimalPrecision = 5
    this.fpsInterval = roundFrameSpeed(
      1 / this.targetFPS,
      this.fpsDecimalPrecision,
      Math.floor
    )

    this.camera.instance.position.set(0, 0.6, 7)
    this.camera.instance.lookAt(0, 0, 0)
    this.camera.instance.rotation.set(0, 0, 0)

    new OrbitControls(this.camera.instance, this.threejs.instance.domElement)

    this.directionalLight = new THREE.DirectionalLight(0xffffff, 1)
    this.directionalLight.position.set(0.78, 0.01, 1.66)
    // this.directionalLight.position.set(-4, 0.01, 1.66)
    this.scene.add(this.directionalLight)

    this.ambientLight = new THREE.AmbientLight(0xffffff, 0.25)
    this.scene.add(this.ambientLight)

    const robotBool = false
    const modelName = robotBool ? "robot" : "fighter"
    const additionalSources = [
      {
        name: modelName,
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: robotBool ? robotBodyFile : fighterBodyFile,
      },
      {
        name: "gradientMap",
        type: "texture",
        tags: ["fighterCollisionMaker"],
        path: gradientMapFile,
      },
      {
        name: "colorGamut",
        type: "texture",
        tags: ["dressingRoom"],
        path: baseUrl + "shared/color-gamut-1.webp"
      },      
      {
        name: "facePlate",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: 'accessories/face/face.fbx',        
      },
      {
        name: "agnesUltimate",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: storageUrl + "npc/agnes-ultimate/agnes-ultimate-model--new.fbx"
      },
      {
        name: "agnesUltimateTexture",
        type: "texture",
        tags: ["fighterCollisionMaker"],
        path: storageUrl + "npc/agnes-ultimate/textures/body--base-color.webp"
      },
      {
        name: "attackDrone",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: storageUrl + "npc/drones-final/drone-body--attack.fbx"
      },
      {
        name: "defenseDrone",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: storageUrl + "npc/drones-final/drone-body--defense.fbx"
      },
      {
        name: "agilityDrone",
        type: "fbxModel",
        tags: ["fighterCollisionMaker"],
        path: storageUrl + "npc/drones-final/drone-body--agility.fbx"
      }
    ]

    this.allMeshes = []
    this.materials = {}
    this.darkMaterial = new THREE.MeshBasicMaterial({ color: "black" })
    this.cachedCharacters = {}
    if (robotBool) {
      const [bloomComposer, finalComposer] = setUpBloom(
        this.threejs.instance,
        this.scene,
        this.camera.instance
      )
      this.bloomComposer = bloomComposer
      this.finalComposer = finalComposer
    }

    const startingCharacter = listOfCharacters[0]

    this.animations = {}
    this.mixer = {}
    this.helpers = {}
    this.resources = new Resources("fighterCollisionMaker", additionalSources)
    this.resources.on("ready", () => {
      this.background = hexagonBackground(
        this.resources.items["hexagonBackground"],
        this.scene
      )
      this.allMeshes.push(this.background.children[0])

      this.raycaster = new THREE.Raycaster()
      this.pointer = new THREE.Vector2()
      const cameraRotation = new THREE.Vector3()
      setMouseTracker({
        background: this.background,
        raycaster: this.raycaster,
        pointer: this.pointer,
        cameraRotation: cameraRotation.copy(this.camera.instance.rotation),
        colliderUUID: undefined,
        prevPoint: { x: 0, y: 0, z: 0 },
      })

      const fbx = this.swapCharacters(startingCharacter)
      // this.scene.add(helper)
      // this.loadAnimations(fbx, startingCharacter)
    })

    this.previousRAF = null
    this.render()
  }

  loadAnimations(fbx, characterName) {
    if (this.animations[characterName] === undefined) {
      this.animations[characterName] = {}
      this.helpers[characterName] = new THREE.SkeletonHelper(fbx)

      // this.resources.loaders.fbxLoader.manager.onLoad = () => {
        this.startLoadingAnimations(fbx, characterName)
      // }
    }
    else {
      this.swapAnimationData(fbx, characterName)
    }
  }

  startAnimationAtFrameZero(clip) {
    let duration = 0
    clip.tracks.forEach((track) => {
      const newTimes = new Float32Array(track.times.length)
      newTimes[0] = 0
      newTimes.set(track.times.slice(0, track.times.length - 1), 1)
      track.times = newTimes
      if (newTimes[newTimes.length - 1] > duration) {
        duration = newTimes[newTimes.length - 1]
      }
    })
    clip.duration = duration
  }

  startLoadingAnimations(fbx, characterName) {
    this.manager = new THREE.LoadingManager()
    this.manager.onLoad = () => {
      this.swapAnimationData(fbx, characterName)
    }

    this.mixer[characterName] = new THREE.AnimationMixer(fbx)

    const onLoad = (animNamesMapping, anim, singleBool=false) => {       
      Object.keys(animNamesMapping).forEach((animKey) => {
        const animName = animNamesMapping[animKey]
        const clip = (
          singleBool ? 
          anim.animations[0] : 
          anim.animations.filter((x) => { return x.name === animKey })[0]
        )
        if (!singleBool) {
          this.startAnimationAtFrameZero(clip)
        }
        for (let track in clip.tracks) {
          clip.tracks[track].setInterpolation(THREE.InterpolateDiscrete);
        }
        const action = this.mixer[characterName].clipAction(clip)
        this.animations[characterName][animName] = {
          clip: clip,
          action: action,
          frameCount: Math.round(clip.duration * 60) + 1,
          // frameCount: Math.ceil(clip.duration * TICK_RATE),
        }
      })   
    }

    const loader = new animationLoaderClasses[characterName](this.manager)
    Object.keys(animationFiles[characterName]).forEach((animationKey) => {
      const animationData = animationFiles[characterName][animationKey]
      loader.load(animationData.path, (a) => {
        onLoad(animationData.names, a, animationData.single)
      })
    })
  }

  swapAnimationData(fbx, characterName) {
    this.setAnimationsReady(true)
    this.setAnimationData({
      character: characterName,
      mixer: this.mixer[characterName],
      animations: this.animations[characterName],
      fighter: fbx,
      armature: fbx.getObjectByName(armatureMapping[characterName]),
      boneNames: [
        ...new Set(
          this.helpers[characterName].bones.map((bone) => {
            return bone.name
          })
        ),
      ],
    })
  }

  updateToonEdge(edgeBool) {
    if (this.resources["toonMaterials"] !== undefined) {
      this.resources["toonMaterials"].forEach((material) => {
        material.side = edgeBool ? THREE.BackSide : THREE.FrontSide
        material.uniforms.edge.value = edgeBool
      })
    }
  }

  updateToonShaderLight() {
    if (this.resources["toonMaterials"] !== undefined) {
      this.resources["toonMaterials"].forEach((material) => {
        material.uniforms.lightPosition.value = this.directionalLight.position
      })
    }
  }

  swapCharacters(newCharacter) {
    this.setAnimationsReady(false)

    let fbx
    listOfCharacters.forEach((name) => {
      if (name === newCharacter) {
        fbx = loadNewCharacter(
          newCharacter, 
          this.scene, 
          this.resources, 
          this.allMeshes, 
          this.cachedCharacters
        )
        fbx.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            child.frustumCulled = false;
          }
        });
      }
      else {
        const characterToRemove = this.scene.getObjectByName(name)
        if (characterToRemove !== undefined && characterToRemove !== null) {
          this.scene.remove(characterToRemove)
        }
      }
    })

    this.loadAnimations(fbx, newCharacter)
    return fbx
  }

  render() {
    requestAnimationFrame((t) => {
      if (this.previousRAF === null) {
        this.previousRAF = t
      }
      const elapsed = roundFrameSpeed(
        (t - this.previousRAF) / 1000,
        this.fpsDecimalPrecision,
        Math.ceil
      )
      if (elapsed >= this.fpsInterval) {
        if (this.background && this.camera) {
          this.background.quaternion.copy(this.camera.instance.quaternion)
        }

        this.previousRAF = t - (elapsed % this.fpsInterval)        
        if (
          this.bloomComposer !== undefined &&
          this.finalComposer !== undefined
        ) {          
          renderBloom(
            this.bloomComposer,
            this.finalComposer,
            this.allMeshes,
            this.materials,
            this.darkMaterial
          )
        } else {          
          updateToonShaderLight(this.resources, this.directionalLight)
          updateToonEdge(this.resources, false)
          this.threejs.instance.render(this.scene, this.camera.instance)
        }
      }
      this.render()
    })
  }
}

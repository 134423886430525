import React from 'react'
import AttributeUploader from '../attribute-uploader/AttributeUploader'
import './uploader-menu.css'

const UploaderMenu = ({ environment }) => {
  return (
    <div id="uploader-menu__container">
      <AttributeUploader environment={environment} bodyPart="eyes"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="mouth"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="head"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="body"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="arms"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="legs"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="hands"></AttributeUploader>
      <AttributeUploader environment={environment} bodyPart="feet"></AttributeUploader>
    </div>
  )
}

export default UploaderMenu